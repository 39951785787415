import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CirclesManageScene from "../Scenes/CirclesManageScene";
import LoadingScene from "../Scenes/LoadingScene";
import { getCircle, deleteInvite } from "../../store/actions";
import { pick, mapKeys } from "lodash";
import ProtoTypes from "prop-types";

class CirclesSceneManageConnected extends Component {
  state = { loading: true };
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getCircle(id);
    this.setState({ loading: false });
  }
  render() {
    const { circle, members, invited, deleteInvite } = this.props;
    return (
      <>
        {this.state.loading ? (
          <LoadingScene />
        ) : (
          <CirclesManageScene
            circle={circle}
            members={members}
            invited={invited}
            deleteInvite={deleteInvite}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (
  { circles, circleInvites, members },
  { match: { params } }
) => {
  const { id } = params;
  let circle = circles[id];
  let invitedMembers =
    circle &&
    Object.values(
      mapKeys(pick(circleInvites, circle.invites), "invited_email")
    );
  const circleMembers = circle && Object.values(pick(members, circle.members));
  return {
    circle,
    members: circleMembers,
    invited:
      invitedMembers &&
      invitedMembers.filter((member) => member.accept_status === "Pending"),
  };
};

CirclesSceneManageConnected.protoTypes = {
  id: ProtoTypes.string,
  match: ProtoTypes.object,
  getCircle: ProtoTypes.func,
  circle: ProtoTypes.object,
  members: ProtoTypes.object,
  invited: ProtoTypes.object,
  deleteInvite: ProtoTypes.func,
};

export default withRouter(
  connect(mapStateToProps, { getCircle, deleteInvite })(
    CirclesSceneManageConnected
  )
);
