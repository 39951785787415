import React from "react";
import styled from "styled-components";
import { colors, fontSizes } from "../Shared/styleGuide";

const LabelField = styled("label")`
  margin: 0px;
  padding: 0px;
  font-family: Avenir Next;
  font-size: ${fontSizes.h4}rem;
  line-height: ${fontSizes.h4 * 1.05}rem;
  color: ${({ lightLabel }) =>
    lightLabel ? colors.slateGrey : colors.cloudBurst};
  font-weight: 600;
`;

export default LabelField;
