import React from "react";
import Button from "../../Shared/Button";
import RenderFieldComponent from "../../Shared/RenderFieldComponent";
import FlexEndCol from "../../Shared/FlexEndCol";
import Text from "../../Shared/Text";
import Form from "../../Shared/Form";
import { Field } from "formik";
import { validatePassword } from "../validate.js";

const UpdatePasswordForm = props => (
  <Form>
    <Field
      component={RenderFieldComponent}
      name="old_password"
      inputtype={"password"}
      label={"Current"}
      {...props}
    />
    <Field
      component={RenderFieldComponent}
      name="new_password1"
      inputtype={"password"}
      label={"New"}
      validate={validatePassword}
      {...props}
    />
    <Text textAlign={"left"}>Password must be at least 8 characters long.</Text>
    <Field
      component={RenderFieldComponent}
      name="new_password2"
      inputtype={"password"}
      label={"Re-type new"}
      {...props}
    />
    <FlexEndCol>
      <Button btntype={"primary"}>Save</Button>
    </FlexEndCol>
  </Form>
);

export default UpdatePasswordForm;
