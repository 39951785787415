import React, { Component } from "react";
import styled from "styled-components";
import { fontSizes } from "../Shared/styleGuide";
import Col from "react-bootstrap/Col";
import Text from "../Shared/Text";
import Toggle from "../Shared/Toggle";
import BorderBtmRow from "../Shared/BorderBtmRow";

const RightDiv = styled(Col)`
  display: flex;
  justify-content: flex-end;
  padding: 0px;
`;
const LeftDiv = styled(Col)`
  padding: 0px;
`;
class NotificationToggleField extends Component {
  render() {
    const { props } = this;
    return (
      <BorderBtmRow bordercolor={props.bordercolor}>
        <LeftDiv xs={{ span: 10 }} md={{ span: 8 }}>
          <Text
            margin={"0px 0px 10px 0px"}
            display={"block"}
            fontSize={fontSizes.normal}
            textAlign={"left"}
          >
            {props.label}
          </Text>
          <Text
            display={"block"}
            textAlign={"left"}
            fontSize={fontSizes.caption}
          >
            {props.children}
          </Text>
        </LeftDiv>
        <RightDiv xs={{ span: 2 }} md={{ span: 4 }}>
          <Toggle />
        </RightDiv>
      </BorderBtmRow>
    );
  }
}

export default NotificationToggleField;
