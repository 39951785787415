import React from "react";

const Logo = () => (
  <svg
    id="fireside-logo"
    viewBox="0 0 36 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M28.1069 18.9847L32.7941 27.6442C35.4788 32.6041 35.2748 38.6557 32.2624 43.4181C32.2006 43.5158 32.1381 43.614 32.0751 43.7128C29.6453 47.5167 24.352 54.8172 16.1951 65.6142C9.24675 60.1132 7.8862 49.9987 13.1278 42.8112C18.4968 35.4489 21.8859 30.7088 23.2951 28.5909C25.4033 25.4224 27.0072 22.2203 28.1069 18.9847Z"
        fill="#FF6161"
      />
      <path
        d="M18.426 0L23.2628 8.57636C26.0328 13.488 25.9333 19.5425 23.0033 24.3577C22.9434 24.4561 22.8829 24.5551 22.8218 24.6545C20.4583 28.5006 15.2922 35.8931 7.32354 46.8319C0.280849 41.4539 -1.25376 31.3639 3.86373 24.0836C9.10331 16.6296 12.4092 11.8315 13.7813 9.68936C15.8343 6.48422 17.3825 3.25443 18.426 0Z"
        fill="#FF6161"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="36" height="66" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
