import React, { Component } from "react";
import { FieldArray } from "formik";
import Header from "../../Shared/Header";
import FlexVC from "../../Shared/FlexVC";
import Button from "../../Shared/Button";
import Option from "../../Shared/Option";

class MemberType extends Component {
  state = {
    options: [
      { id: 0, value: "Friends", category: "social", selected: true },
      {
        id: 1,
        value: "Romantic Partner",
        category: "partner",
        selected: false,
      },
      { id: 2, value: "Family", category: "social", selected: false },
      { id: 3, value: "Team Member", category: "team", selected: false },
    ],
  };
  componentDidMount() {
    this.props.deleteOnboardingQs();
    this.props.setFieldValue("members", []);
  }
  handleSelect = (idx) => {
    // remove any errors
    delete this.props.errors.member_types;

    const updatedOptions = [...this.state.options];
    const optionsLen = updatedOptions.length;

    // make only one question selected
    //TODO: extract this function
    for (let i = 0; i < optionsLen; i++) {
      if (i == idx) {
        updatedOptions[i].selected = true;
        updatedOptions[i].disabled = false;
      } else {
        updatedOptions[i].selected = false;
        updatedOptions[i].disabled = true;
      }
    }

    this.setState((state, props) => ({
      options: updatedOptions,
    }));
  };

  handleContinue = async () => {
    const {
      setFieldError,
      getOnboardingQs,
      setFieldValue,
      handleMemberType,
      handleStep,
    } = this.props;

    const selected = this.state.options.filter(
      (option) => option.selected === true
    );

    if (selected.length === 0) {
      setFieldError("member_types", "Select One");
    } else {
      setFieldValue("member_types", selected);

      // check to see if circle is romantic
      handleMemberType(
        selected.length === 1 && selected[0].category === "partner"
      );

      // questions depend on member type
      await getOnboardingQs(selected[0].category);

      handleStep();
    }
  };

  render() {
    const { errors, handleBack } = this.props;
    return (
      <>
        <FlexVC
          direct="column"
          justify={"center"}
          margin={"0rem 0rem 2rem 0rem"}
          mmargin={"1rem 0rem"}
        >
          <Header h3 light center>
            This helps us cater the experience to your circle
          </Header>
        </FlexVC>

        <FlexVC direct="column" justify="center" wrap>
          {errors.member_types}
          <FlexVC direct="column" justify="center" wrap>
            <FieldArray
              name="member_types"
              render={({ replace }) =>
                this.state.options.map((option, index) => (
                  <Option
                    key={index}
                    onMouseDown={(e) => {
                      this.handleSelect(index, replace);
                    }}
                    onTouchStart={(e) => {
                      this.handleSelect(index, replace);
                    }}
                    onTouchEnd={(e) => {
                      e.preventDefault();
                    }}
                    children={option.value}
                    bg={option.selected && !option.disabled}
                    fontSize={1.125}
                  />
                ))
              }
            />
          </FlexVC>
        </FlexVC>
        <FlexVC
          direct="row"
          justify="center"
          margin={"2rem 0rem 0rem 0rem"}
          full
        >
          <FlexVC direct="column" full>
            <Button
              width={"236px"}
              type={"button"}
              onClick={() => this.handleContinue()}
            >
              Choose A Question
            </Button>
          </FlexVC>
        </FlexVC>
      </>
    );
  }
}

export default MemberType;

// handleSelect = (idx) => {
//   // remove any errors
//   delete this.props.errors.member_types;
//   const updatedOptions = [...this.state.options];
//   updatedOptions[idx].selected = !updatedOptions[idx].selected;

//   this.setState((state, props) => ({
//     options: updatedOptions,
//   }));
// };

// handleContinue = () => {
//   const { values, setFieldError } = this.props;

//   // check selected options
//   const selected = this.state.options.filter(
//     (option) => option.selected === true
//   );

//   // check if none were selected
//   if (selected.length === 0) {
//     setFieldError("member_types", "Select At Least One");
//   } else {
//     this.props.setFieldValue("member_types", selected);

//     // check to see if circle is romantic
//     this.props.handleMemberType(
//       selected.length === 1 && selected[0].value === "Romantic Partner"
//     );

//     // questions vary with member type
//     // need to ask what to do if more than one group is selected

//     this.props.getOnboardingQs(selected[0].category);

//     this.props.handleStep();
//   }
// };
