import {
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  PASSWORD_RESET,
  UPDATE_USER,
  GET_WELCOME_MODAL_VIEWS,
} from "../actions/types.js";

const INTIAL_STATE = {
  userId: null,
  first_name: null,
  last_name: null,
  isSignedIn: false,
  new_user: true,
  requesting: false,
  welcomeModalViews: 0,
};

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        ...action.payload,
      };

    case "REQUESTING":
      return { ...state, ...action.payload };
    case SIGN_OUT:
      return {};
    case SIGN_UP:
      return {
        ...state,
        isSignedIn: true,
        ...action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        first_name: action.payload.first_name,
        last_name: action.payload.last_name,
      };
    case PASSWORD_RESET:
      return state;
    case GET_WELCOME_MODAL_VIEWS:
      return {
        ...state,
        welcomeModalViews: state.welcomeModalViews + 1,
      };
    default:
      return state;
  }
};
