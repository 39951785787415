import React from "react";
import { Field } from "formik";
import Button from "../../Shared/Button";
import Form from "../../Shared/Form";
import TermsAndPrivacyBlurb from "../../Forms/TermsAndPrivacyBlurb";
import RenderFieldComponent from "../../Shared/RenderFieldComponent";
import {
  validateName,
  validatePassword,
  validateConfirmPassword,
} from "../validate.js";

const EmailInviteForm = ({ errors, touched, values }) => (
  <Form mobileMed>
    <Field
      component={RenderFieldComponent}
      name="first_name"
      inputtype={"text"}
      placeholder={"First Name"}
      validate={validateName}
      mobileMed
    />
    <Field
      component={RenderFieldComponent}
      name="last_name"
      inputtype={"text"}
      placeholder={"Last Name"}
      validate={validateName}
      mobileMed
    />
    <Field
      component={RenderFieldComponent}
      name="email"
      inputtype={"email"}
      mobileMed
      readOnly
    />
    <Field
      component={RenderFieldComponent}
      name="password1"
      inputtype={"password"}
      placeholder={"Password"}
      validate={validatePassword}
      mobileMed
    />
    <Field
      component={RenderFieldComponent}
      name="password2"
      inputtype={"password"}
      placeholder={"Confirm Password"}
      validate={(value) => validateConfirmPassword(values.password1, value)}
      mobileMed
    />
    <TermsAndPrivacyBlurb />

    <Button btntype={"primary"}>Sign Up</Button>
    {errors.general}
  </Form>
);
export default EmailInviteForm;
