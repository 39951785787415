import React from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Header from "../../Shared/Header";
import FlexVC from "../../Shared/FlexVC";
import ArrowLeftSmall from "../../Shared/icons/ArrowLeftSmall";
import Container from "react-bootstrap/Container";
import ArcBackground from "../../Shared/Arc/ArcBackground";
import TextWithCircle from "../../Shared/TextWithCircle";
import { colors } from "../../Shared/styleGuide";
import styled from "styled-components";

const HeaderRow = styled(Row)`
  width: 100%;
  margin: -1px 0px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: ${({ mode }) => (mode ? colors.selago : colors.slateGrey)};
  color: ${({ mode }) => (mode ? `${colors.cloudBurst}` : `${colors.white}`)};
  @media (max-width: 576px) {
    padding: 0rem 0rem 0.5rem 0rem;
  }
`;
const Arc = styled(ArcBackground)`
  display: none;
  @media (max-width: 576px) {
    display: flex;
    padding: 1rem 0rem 0.5rem 0rem;
    width: 120%;
    margin-bottom: 1rem;
    margin-left: -10%;
  }
`;

const HeroResponseSection = (props) => (
  <>
    <HeaderRow mode={props.mode}>
      <Container>
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <FlexVC
              direct="row"
              fullWidth
              justify={"flex-start"}
              margin={"0.5rem"}
            >
              <ArrowLeftSmall color={colors.white} />
              <Header
                handleHeaderClick={props.back}
                h4
                light
                mode={props.mode}
                margin={"0rem 0.625rem"}
              >
                {`Back`}
              </Header>
            </FlexVC>

            <Header h1 center light mode={props.mode} margin={"0.5rem"}>
              {props.header}
            </Header>
            <TextWithCircle
              mode={props.mode}
              circle_id={props.circle_id}
              name={props.name}
              {...props}
            />
          </Col>
        </Row>
      </Container>
    </HeaderRow>
    <Arc mode={0} />
  </>
);

HeroResponseSection.propTypes = {
  mode: PropTypes.number,
};
HeroResponseSection.defaultProps = {
  mode: 1, // 0 for dark, 1 for light
};
export default HeroResponseSection;
