import styled from "styled-components";
import { calcZIndex } from "./carouselFunctions";

export const Slide = styled("div")`
  display: inline-flex;
  flex-direction: row;
  height: ${({ order, len }) => (order + 1 <= len ? "100%" : "0px")};
  min-height: ${({ order, len }) => (order + 1 <= len ? "22rem" : "0px")};
  width: ${({ order, len }) => {
    if (order + 1 === len || order === 0) return "50px";
    if (order + 1 <= len) return "100px";
    return "0px";
  }};
  align-items: flex-start;
  justify-content: center;
  justify-items: flex-start;
  z-index: ${({ order, len }) => calcZIndex(order, len)};
  order: ${({ order }) => order};
`;
export const SlideText = styled("div")`
  text-align: center;
  padding-top: 0.625rem;
  opacity: ${({ mid, order }) => (mid === order ? "1" : "0")};
  visibility: ${({ mid, order }) => (mid === order ? "visible" : "none")};
  transition: ${({ mid, order }) =>
    mid === order ? "visibility 1s, opacity 0.7s linear" : ""};
  ${({ width }) => `width: ${width}rem`};
`;
export const SlideContent = styled("div")`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
`;
