import React from "react";
import CommentInputArea from "../CommentInputArea/CommentInputArea";
import Button from "../../Shared/Button";
import FlexEndCol from "../../Shared/FlexEndCol";
import { Field, Form } from "formik";
import { validateTextArea } from "../validate.js";
import styled from "styled-components";

const CommentForm = styled(Form)`
  width: 100%;
`;
const CreateCommentForm = (props) => (
  <CommentForm>
    <Field
      component={CommentInputArea}
      name="comment_text"
      validate={validateTextArea}
      {...props}
    />
    <FlexEndCol padding={"0"}>
      <Button type="submit" >
        Submit
      </Button>
    </FlexEndCol>
  </CommentForm>
);

export default CreateCommentForm;
