import React, { Component } from "react";
import autosize from "autosize";
import ResponseTextarea from "../../Shared/ResponseTextarea";
import "emoji-mart/css/emoji-mart.css";
import { NimblePicker } from "emoji-mart";
import data from "emoji-mart/data/twitter.json";
import Emoticon from "../../Shared/Emoticon";
import IconHolder from "../../Shared/IconHolder";
import EmoticonOptionsHolder from "../../Shared/EmoticonOptionsHolder";
import InputAndEmojiHolder from "../../Shared/InputAndEmojiHolder";

class ResizableTextarea extends Component {
  state = {
    showEmojis: false,
  };
  addEmoji = (e) => {
    this.setState({
      showEmojis: !this.state.showEmojis,
    });

    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));

    let emojiPic = String.fromCodePoint(...codesArray);

    this.props.setFieldValue(
      "response_text",
      `${this.props.values.response_text} ${emojiPic} `
    );
  };

  showEmojis = (e) => {
    this.setState({
      showEmojis: !this.state.showEmojis,
    });
  };

  componentDidMount() {
    autosize(this.textarea);
  }

  render() {
    let { props } = this;
    let { field, placeholder, ariaLabel } = props;
    return (
      <InputAndEmojiHolder>
        <ResponseTextarea
          ref={(c) => (this.textarea = c)}
          placeholder={placeholder}
          aria-label={ariaLabel}
          rows={1}
          {...field}
          {...props}
        />
        {this.state.showEmojis ? (
          <EmoticonOptionsHolder onClick={this.showEmojis}>
            <NimblePicker
              onSelect={this.addEmoji}
              set="twitter"
              data={data}
              emojiSize={20}
              emoji="point_up"
              exclude={["symbols", "flags", "custom"]}
              sheetSize={16}
              title="Pick your emoji…"
            />
          </EmoticonOptionsHolder>
        ) : (
          <IconHolder>
            <Emoticon handleClick={this.showEmojis} />
          </IconHolder>
        )}
      </InputAndEmojiHolder>
    );
  }
}

export default ResizableTextarea;
