import React, { Component } from "react";
import styled from "styled-components";
import Footer from "../Shared/Footer";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Wrapper from "../Shared/Wrapper";
import Container from "react-bootstrap/Container";
import MainNavbar from "../containers/NavbarConnected";
import MobileNavConnected from "../containers/MobileNavConnected";
import MobileDropdownConnected from "../containers/MobileDropdownConnected";
import Mobile from "../Shared/Mobile";
import HideMobile from "../Shared/HideMobile";
import CenterLogoOnlyNav from "../Navbar/CenterLogoOnlyNav";

const StyledRow = styled(Row)`
  margin: ${({ ntm }) => (ntm ? "0rem -15px" : "2rem -15px")};
`;
const StyledContainer = styled(Container)`
  margin-top: -4rem;
  margin-bottom: ${({ margin }) => (margin ? margin : "1rem")};
  @media (max-width: 576px) {
    margin-top: -4rem;
  }
`;

class SceneTemplate extends Component {
  state = {
    displayMobileMenu: false,
  };
  toggleMobileMenu = (display) => {
    this.setState({
      displayMobileMenu: display,
    });
  };
  render() {
    let {
      children,
      header,
      mode,
      margin,
      whole,
      ntm,
      createdCircle,
    } = this.props;
    return (
      <Wrapper>
        {this.state.displayMobileMenu ? (
          <MobileDropdownConnected
            displayMenu={this.toggleMobileMenu}
            mode={mode}
            {...this.props}
          />
        ) : (
          <>
            {!createdCircle ? (
              <CenterLogoOnlyNav mode={mode} />
            ) : (
              <>
                <HideMobile>
                  <MainNavbar mode={mode} createdCircle={createdCircle} />
                </HideMobile>
                <Mobile>
                  <MobileNavConnected
                    mode={mode}
                    displayMenu={this.toggleMobileMenu}
                    createdCircle={createdCircle}
                  />
                </Mobile>
              </>
            )}
            {header}
            <StyledContainer margin={margin}>
              <StyledRow ntm={ntm}>
                <Col md={whole ? { span: 12 } : { span: 10, offset: 1 }}>
                  {children}
                </Col>
              </StyledRow>
            </StyledContainer>
            <Footer />
          </>
        )}
      </Wrapper>
    );
  }
}
SceneTemplate.defaultProps = {
  createdCircle: true,
};
export default SceneTemplate;
