import React from "react";
import Logo from "../Shared/FlexibleLogo";
import LogoText from "../Shared/LogoText";

const Brand = ({ createdCircle, mode }) => (
  <>
    <Logo mb={"0rem"} size={36} />
    {createdCircle ? (
      <LogoText mode={mode} to="/home">
        fireside
      </LogoText>
    ) : (
      <LogoText mode={mode} to="/">
        fireside
      </LogoText>
    )}
  </>
);

export default Brand;
