import React, { Component } from "react";
import ResponseSceneTemplate from "./ResponseSceneTemplate";
import ResponseHero from "../Heros/ResponseHero/";
import ResponseFormConnected from "../containers/ResponseFormConnected";
import LoadingScene from "../Scenes/LoadingScene";

class ResponseCreateScene extends Component {
  state = { loading: true };
  componentDidMount() {
    this.setState({ loading: false });
  }
  render() {
    if (this.state.loading) {
      return <LoadingScene />;
    }
    return (
      <ResponseSceneTemplate
        mode={0}
        header={
          <ResponseHero
            header={this.props.prompt.question_text}
            name={this.props.circle.circle_name}
            circle_id={this.props.circle.id}
            mode={0}
            back={() => this.props.history.goBack()}
          />
        }
      >
        <ResponseFormConnected {...this.props} />
      </ResponseSceneTemplate>
    );
  }
}
export default ResponseCreateScene;
