import React from "react";
import { fontSizes } from "../Shared/styleGuide";

// Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LabelTextBtnBorder from "../Shared/LabelTextBtnBorder";
import Header from "../Shared/Header";

const OtherSection = props => (
  <Row>
    <Col>
      <Header h5 margin={"0.5rem 0.5rem 0.5rem 0rem"} uppercase>
        Other
      </Header>
      {false && (
        <LabelTextBtnBorder
          label={"Delete account"}
          action={"Delete"}
          btntype={"tertiary"}
          labelSize={fontSizes.normal}
          fontSize={fontSizes.caption}
        >
          All your content will be deleted and cannot be recovered.
        </LabelTextBtnBorder>
      )}
      <LabelTextBtnBorder
        label={"Privacy policy"}
        linkable={"privacy"}
        action={""}
        btntype={"tertiary"}
        labelSize={fontSizes.normal}
        fontSize={fontSizes.caption}
      >
        Your privacy is importatnt to us. Learn more about our privacy policies.
      </LabelTextBtnBorder>
      <LabelTextBtnBorder
        label={"Terms of use"}
        linkable={"terms-of-service"}
        action={""}
        btntype={"tertiary"}
        labelSize={fontSizes.normal}
        fontSize={fontSizes.caption}
      >
        Read more about our platform guidelines and the Fireside community.
      </LabelTextBtnBorder>
      <Header h5 light margin={"0.5rem 0rem"} center>
        For questions email us at hello@fireside.social
      </Header>
    </Col>
  </Row>
);

export default OtherSection;
