import React from "react";
import SceneTemplate from "./SceneTemplate";
import HeaderSection from "../containers/HeaderSectionConnected";
import Header from "../Shared/Header";
import Link from "../Shared/Link";
import FlexVC from "../Shared/FlexVC";

const TermsOfServiceScene = ({ createdCircle = false }) => (
  <SceneTemplate
    mode={0}
    createdCircle={createdCircle}
    header={
      <HeaderSection
        header="Fireside Technologies, Inc. Terms  of Service"
        tagline="Effective Date: 12/1/19"
        mode={0}
        short={!createdCircle}
      />
    }
  >
    <FlexVC direct="column" margin="1rem 0rem">
      <article>
        <section>
          Thank you for visiting this Site, which is owned and operated by
          Fireside Technologies Inc. (“Fireside”). Fireside makes software
          products and applications that users may interact with through
          websites, iOS and Android applications, email, SMS, phone calls, video
          calls, conversational software, and other software platforms. These
          Terms of Use govern your use of this Site.
        </section>
        <section>
          <Header h3 noMargin>
            Your Acceptance of These Terms of Use
          </Header>
          These Terms of Use apply to all users of this Site, whether or not you
          are a registered member. By using this Site you are agreeing to comply
          with and be bound by these Terms of Use. If you do not agree to these
          Terms of Use, you may not access or use this Site.
        </section>
        <section>
          <Header h3 noMargin>
            Your Acceptance of Our Privacy Policy
          </Header>
          By agreeing to these Terms of Use, you agree to the terms of our
          Privacy Policy, which is expressly incorporated herein. Before using
          this Site, please carefully review our{" "}
          <Link to="/privacy">Privacy Policy</Link>. All personal information
          provided to us as a result of your use of this Site will be handled in
          accordance with our Privacy Policy. To the extent there are
          inconsistencies between these Terms of Use and our Privacy Policy,
          these Terms of Use control.
        </section>
        <section>
          <Header h3 noMargin>
            Your Consent to Other Agreements
          </Header>
          When you sign up to use a special feature of this Site, you may be
          asked to agree to special terms governing your use of the special
          feature. In such cases, you may be asked to expressly consent to the
          special terms, for example, by checking a box or clicking on a button
          marked “I agree.” This type of agreement is known as a “click-through”
          agreement. If any of the terms of the click-through agreement are
          different than the terms of these Terms of Use, the terms of the
          click-through agreement will supplement or amend these Terms of Use,
          but only with respect to the matters governed by the “click-through
          agreement.”
        </section>
        <section>
          <Header h3 noMargin>
            Ownership of this Site and its Content
          </Header>
          <p>
            This Site, including all its Content are protected under applicable
            intellectual property and other laws, including without limitation
            the laws of the United States and other countries. All Content and
            intellectual property rights therein are the property of Fireside or
            the material is included with the permission of the rights owner and
            is protected pursuant to applicable copyright and trademark laws.{" "}
          </p>
          <p>
            The presence of any Content on this Site does not constitute a
            waiver of any right in such Content. You do not acquire ownership
            rights to any such Content viewed through this Site. Except as
            otherwise provided herein, none of this Content may be used, copied,
            reproduced, distributed, republished, downloaded, modified,
            displayed, posted or transmitted in any form or by any means,
            including, but not limited to, electronic, mechanical, photocopying,
            recording, or otherwise, without our express prior written
            permission.
          </p>
          <p>
            Permission is hereby granted to the extent necessary to lawfully
            access and use this Site and to display, download, or print portions
            of this Site on a temporary basis and for your personal,
            educational, noncommercial use only, provided that you (i) do not
            modify the Content; (ii) you retain any and all copyright and other
            proprietary notices contained in the Content; and (iii) you do not
            copy or post the Content on any network computer or broadcast the
            Content in any media.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            Trademarks
          </Header>
          The Fireside names and logos (including, without limitation, those of
          its affiliates), all product and service names, all graphics, all
          button icons, and all trademarks, service marks and logos appearing
          within this Site, unless otherwise noted, are trademarks (whether
          registered or not), service marks and/or trade dress of Fireside
          and/or its affiliates (the “Fireside Marks”). All other trademarks,
          product names, company names, logos, service marks and/or trade dress
          mentioned, displayed, cited or otherwise indicated within this Site
          are the property of their respective owners. You are not authorized to
          display or use the Fireside Marks in any manner without our prior
          written permission. You are not authorized to display or use
          trademarks, product names, company names, logos, service marks and/or
          trade dress of other owners featured within this Site without the
          prior written permission of such owners. The use or misuse of the
          Fireside Marks or other trademarks, product names, company names,
          logos, service marks and/or trade dress or any other materials
          contained herein, except as permitted herein, is expressly prohibited.
        </section>
        <section>
          <Header h3 noMargin>
            Responsibility for User-Generated Content Posted on or Through this
            Site
          </Header>
          <em>You are responsible for User-Generated Content that you post.</em>{" "}
          <p>
            Under no circumstances will we be liable in any way for any UGC.
            This means that you, not Fireside, are entirely responsible for all
            UGC that you post and that you can be held personally liable for
            comments that are defamatory, obscene, or libelous, or that violate
            these Terms of Use, an obligation of confidentiality, or the rights
            of others. If any part of the UGC you post is not your original
            work, it is your responsibility to obtain any necessary permission
            to post it.{" "}
          </p>
          <p>
            Because we do not control the UGC posted on or through this Site, we
            cannot and do not warrant or guarantee the truthfulness, integrity,
            suitability, or quality of that UGC. You also agree and understand
            that by accessing this Site, you may encounter UGC that you may
            consider to be objectionable. We have no responsibility for any UGC,
            including without limitation any errors or omissions therein. We are
            not liable for any loss or damage of any kind you may claim was
            incurred as a result of the use of any UGC posted, emailed,
            transmitted or otherwise made available on or through this Site. The
            UGC posted on or through this Site expresses the personal opinions
            of the individuals who posted it and does not necessarily reflect
            the views of Fireside or any person or entity associated with it.
          </p>
          <p>
            <em>You own User-Generated Content, but we may use it. </em>
          </p>
          <p>
            You own the copyright in any original UGC you post. We do not claim
            any copyrights in UGC. However, by using this Site you are granting
            us and our subsidiaries, affiliates, successors and assigns, a
            nonexclusive, fully paid, worldwide, perpetual, irrevocable,
            royalty-free, transferable license to use, copy, modify, distribute,
            publish, transmit, remove, retain, and repurpose UGC you post in any
            and all media or form of communication whether now existing or
            hereafter developed, without obtaining additional consent, without
            restriction, notification, or attribution, and without compensating
            you in any way. For this reason, we ask that you not post any UGC
            that you do not wish to license to us, including any confidential
            information.{" "}
          </p>
          <p>
            <em>We may disclose and/or remove User-Generated Content. </em>
            Fireside has certain rights. We have the right (but do not assume
            the obligation) to:
            <ul>
              <li>monitor all UGC; </li>

              <li>require that you avoid certain subjects; </li>

              <li>
                remove or block any UGC at any time without notice at our sole
                and absolute discretion;{" "}
              </li>

              <li>
                disclose any UGC and the identity of the user who posted it in
                response to a subpoena or whenever we believe that disclosure is
                appropriate to comply with the law or a court order, to prevent
                or investigate a possible crime or other violation of law, to
                protect the rights of Fireside or others, or to enforce these
                Terms of Use; and{" "}
              </li>
              <li>
                terminate your access to and use of this Site, or to modify,
                edit or block your transmissions thereto, for any reason and in
                our sole discretion.
              </li>
            </ul>
          </p>
          <p>
            You agree that our exercise of such discretion shall not render us
            the owners of UGC you post, and that you will retain ownership
            thereof as described above.{" "}
          </p>
          <p>
            <em>Restrictions on User-Generated Content. </em>It is a condition
            of these Terms of Use that you do not upload, post, transmit or
            otherwise make available:
            <ul>
              <li>
                any UGC that is unlawful, harmful, hateful, threatening,
                abusive, harassing, libelous, defamatory, obscene, vulgar,
                pornographic, profane, racially disparaging, indecent, or
                invasive of another’s privacy;{" "}
              </li>
              <li>
                any UGC that constitutes or encourages activity illegal under
                criminal or civil law;{" "}
              </li>
              <li>
                {" "}
                any UGC that is false, misleading, or fraudulent; any UGC that
                you do not have a right to make available under any law or under
                contractual or fiduciary relationships (such as inside
                information or proprietary and confidential information learned
                or disclosed as part of employment relationships or under
                nondisclosure agreements);{" "}
              </li>
              <li>
                any UGC that violates or infringes upon the rights of others,
                including UGC which violates the patent rights, copyrights,
                trademark rights, privacy rights, publicity rights, trade secret
                rights, confidentiality rights, contract rights, or any other
                rights of any individual, living or deceased, or any legal
                entity;{" "}
              </li>
              <li>
                {" "}
                any UGC that contains the image, name or likeness of anyone
                other than yourself, unless (i) that person is at least eighteen
                years old and you have first obtained his/her express permission
                or (ii) that person is under eighteen years old but you are
                his/her parent or legal guardian;{" "}
              </li>
              <li>
                {" "}
                any request for or solicitation of any personal or private
                information from any individual to the extent such request is
                not consistent with the networking goals of this Site;{" "}
              </li>
              <li>
                any request for or solicitation of money, goods, or services for
                private gain{" "}
              </li>
              <li>
                {" "}
                any UGC that contains advertising, promotions or marketing, or
                which otherwise has a commercial purpose{" "}
              </li>
              <li>
                any material that contains software viruses or any other
                computer code, files or programs designed to interrupt, destroy
                or limit the functionality of any computer software or hardware
                or telecommunications equipment; or
              </li>

              <li>
                {" "}
                impersonate any person or entity or falsely state or otherwise
                misrepresent your affiliation with a person or entity; or{" "}
              </li>
              <li>
                {" "}
                violate any local, state, national or international law, rule or
                regulation.
              </li>

              <li>
                use automated means, including spiders, robots, crawlers, data
                mining tools, or the like to download data from this Network
                except for Internet search engines (e.g. Google) and
                non-commercial public archives (e.g. archive.org) that comply
                with our robots.txt file, or well-behaved web services/RSS/Atom
                clients. We reserve the right to define what we mean by
                well-behaved;
              </li>
            </ul>
          </p>
          <p>
            {" "}
            By posting User-Generated Content, you represent and warrant that
            (i) you own or otherwise control all of the rights to the UGC and
            have the right to grant the license set forth in these Terms of Use;
            (ii) the UGC is accurate, and (iii) you have read and understood—and
            your UGC fully complies with—these Terms of Use and applicable laws
            and will not cause injury to any person or entity.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            Removal of Content
          </Header>
          <p>
            {" "}
            <em> In general. </em>You can seek removal of objectionable UGC,
            “flag” posts, and lodge complaints against particular users, by
            contacting us at hello@fireside.social. We will endeavor to review
            such requests and to remove UGC and users that we determine should
            be removed, in our sole discretion and in accordance with these
            Terms of Use and applicable law. However, by providing a mechanism
            for the submission of complaints, we make no promises that we will
            review all such complaints or that we will take any action in
            response to such complaints. Please be aware, however, that if the
            UGC has already been distributed to other websites or published in
            other media, we will not be able to recapture and delete it. Also, a
            back-up or residual copy of the UGC we remove from this Site may
            remain on backup servers.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            Violation of copyrights.
          </Header>
          <p>
            Fireside does not knowingly violate or permit others to violate the
            copyrights of others. We will promptly remove or disable access to
            material that we know is infringing or if we become aware of
            circumstances from which infringing activity is apparent.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            Your Feedback
          </Header>
          <p>
            Although we do not claim ownership of User-Generated Content you
            post using this Site, the Feedback you provide to us through this
            Site will be and remain our exclusive property. Your submission of
            Feedback will constitute an assignment to us of all worldwide
            rights, title and interests in your Feedback, including all
            copyrights and other intellectual property rights in your Feedback.
            We will be entitled to reduce to practice, exploit, make, use, copy,
            disclose, display or perform publicly, distribute, improve and
            modify any Feedback you submit for any purpose whatsoever, without
            restriction and without compensating you in any way. For this
            reason, we ask that you not send us any Feedback that you do not
            wish to assign to us.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            Your Obligations
          </Header>
          <p>
            In consideration of your use of this Site, you agree that to the
            extent you provide personal information to Fireside it will be true,
            accurate, current, and complete and that you will update all
            personal information as necessary. You also agree that you will use
            an image of yourself that you are authorized to use for your profile
            picture. The use of company logos, advertisements, web addresses,
            contact information, pictures of celebrities or the unauthorized use
            of images owned by others is prohibited.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            Fees and Payments
          </Header>
          <p>
            {" "}
            All fees are paid in either in advance or on a recurring basis and
            are non-refundable. Subscriptions may be cancelled at any time, in
            which case paid services will discontinue at the end of the billing
            cycle. If you provide us with a credit card that expires during the
            term of these Terms of Service, we reserve the right to charge any
            renewal card issued to you as a replacement. You agree to promptly
            pay Fireside in the event of any refusal of your credit card issuer
            to pay any amount to Fireside for any reason. You agree to pay all
            costs of collection, including attorneys’ fees and costs, on any
            outstanding balance. In the event you fail to pay any amount when
            due, Fireside may immediately suspend or terminate your access to
            any or all of our services.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            DISCLAIMERS
          </Header>
          <p>
            WE MAKE NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THIS SITE
            OR ITS CONTENT, OR ANY PRODUCT OR SERVICE AVAILABLE ON OR PROMOTED
            THROUGH THIS SITE. THIS SITE AND ALL OF ITS CONTENT (INCLUDING
            USER-GENERATED CONTENT) ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE”
            BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND. TO THE
            FULLEST EXTENT PERMITTED BY LAW, FIRESIDE, ITS AFFILIATES, AND THEIR
            SERVICE PROVIDERS AND LICENSORS DISCLAIM ANY AND ALL REPRESENTATIONS
            AND WARRANTIES, WHETHER EXPRESS, IMPLIED, ARISING BY STATUTE,
            CUSTOM, COURSE OF DEALING, COURSE OF PERFORMANCE OR IN ANY OTHER
            WAY, WITH RESPECT TO THIS SITE, ITS CONTENT, AND ANY PRODUCTS OR
            SERVICES AVAILABLE OR PROMOTED THROUGH THIS SITE. WITHOUT LIMITING
            THE GENERALITY OF THE FOREGOING, FIRESIDE, ITS AFFILIATES, AND THEIR
            SERVICE PROVIDERS AND LICENSORS DISCLAIM ALL REPRESENTATIONS AND
            WARRANTIES (A) OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND
            FITNESS FOR A PARTICULAR PURPOSE; (B) RELATING TO THE SECURITY OF
            THIS SITE; (C) THAT THE CONTENT OF THIS SITE IS ACCURATE, COMPLETE
            OR CURRENT; OR (D) THAT THIS SITE WILL OPERATE SECURELY OR WITHOUT
            INTERRUPTION OR ERROR. WE DO NOT REPRESENT OR WARRANT THAT THIS
            SITE, ITS SERVERS, OR ANY TRANSMISSIONS SENT FROM US OR THROUGH THIS
            SITE WILL BE FREE OF ANY HARMFUL COMPONENTS (INCLUDING VIRUSES).
            FIRESIDE DOES NOT ENDORSE AND IS NOT RESPONSIBLE FOR STATEMENTS,
            ADVICE AND OPINIONS MADE BY ANYONE OTHER THAN AUTHORIZED FIRESIDE
            SPOKESPERSONS. WE DO NOT ENDORSE AND ARE NOT RESPONSIBLE FOR ANY
            STATEMENTS, ADVICE OR OPINIONS CONTAINED IN USER-GENERATED CONTENT
            AND SUCH STATEMENTS, ADVICE AND OPINIONS DO NOT IN ANY WAY REFLECT
            THE STATEMENTS, ADVICE AND OPINIONS OF FIRESIDE. WE DO NOT MAKE ANY
            REPRESENTATIONS OR WARRANTIES AGAINST THE POSSIBILITY OF DELETION,
            MISDELIVERY OR FAILURE TO STORE COMMUNICATIONS, PERSONALIZED
            SETTINGS, OR OTHER DATA. YOU ACCEPT THAT OUR SHAREHOLDERS, OWNERS,
            OFFICERS, DIRECTORS, EMPLOYEES AND OTHER REPRESENTATIVES SHALL HAVE
            THE BENEFIT OF THIS CLAUSE. APPLICABLE LAW MAY NOT ALLOW THE
            LIMITATION OF CERTAIN WARRANTIES, SO ALL OR PART OF THIS DISCLAIMER
            OF WARRANTIES MAY NOT APPLY TO YOU.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            LIMITATION OF LIABILITY
          </Header>
          <p>
            {" "}
            TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAWS WE, ON BEHALF OF
            OUR DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS, LICENSORS AND
            SERVICE PROVIDERS, EXCLUDE AND DISCLAIM LIABILITY FOR ANY LOSSES AND
            EXPENSES OF WHATEVER NATURE AND HOWSOEVER ARISING INCLUDING, WITHOUT
            LIMITATION, ANY DIRECT, INDIRECT, GENERAL, SPECIAL, PUNITIVE,
            INCIDENTAL OR CONSEQUENTIAL DAMAGES; LOSS OF USE; LOSS OF DATA; LOSS
            CAUSED BY A VIRUS; LOSS OF INCOME OR PROFIT; LOSS OF OR DAMAGE TO
            PROPERTY; CLAIMS OF THIRD PARTIES; OR OTHER LOSSES OF ANY KIND OR
            CHARACTER, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES OR LOSSES, ARISING OUT OF OR IN CONNECTION WITH THE USE OF
            THIS SITE. YOU ASSUME TOTAL RESPONSIBILITY FOR ESTABLISHING SUCH
            PROCEDURES FOR DATA BACKUP AND VIRUS CHECKING AS YOU CONSIDER
            NECESSARY. THIS LIMITATION OF LIABILITY APPLIES WHETHER THE ALLEGED
            LIABILITY IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
            LIABILITY OR ANY OTHER BASIS. IF ANY PART OF THIS LIMITATION ON
            LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE FOR ANY REASON,
            THEN THE AGGREGATE LIABILITY OF THE RELEASED PARTIES FOR LIABILITIES
            THAT OTHERWISE WOULD HAVE BEEN LIMITED SHALL NOT EXCEED TEN DOLLARS
            ($10.00).{" "}
          </p>
          <p>
            This Site gives you specific legal rights and you may also have
            other rights which vary from country to country. Some jurisdictions
            do not allow certain kinds of limitations or exclusions of
            liability, so the limitations and exclusions set out in these Terms
            of Use may not apply to you. Other jurisdictions allow limitations
            and exclusions subject to certain conditions. In such a case the
            limitations and exclusions set out in these Terms of Use shall apply
            to the fullest extent permitted by the laws of such applicable
            jurisdictions. Your statutory rights as a consumer, if any, are not
            affected by these provisions, and we do not seek to exclude or limit
            liability for fraudulent misrepresentation.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            Links to Third-Party Websites
          </Header>
          <p>
            This Site may provide links to other websites operated by third
            parties. Because we have no control over third-party websites, we
            are not responsible for the availability of those websites and do
            not endorse and are not responsible or liable for any content,
            advertising, services, products, or other materials on or available
            from such websites. Fireside shall not be responsible or liable,
            directly or indirectly, for any damage or loss caused or alleged to
            be caused by or in connection with the use of or reliance on any
            content, advertising, services, products, or other materials on or
            available from such websites. These Terms of Use do not apply to
            your use of third-party websites; your use of such websites is
            subject to the terms and policies of the owner of such websites.{" "}
          </p>
          <p>
            Fireside has financial relationships with some of the companies,
            products, and services mentioned on our site, and may be compensated
            if users choose to follow the links pointing to those companies,
            products or services.
          </p>
        </section>

        <section>
          <Header h3 noMargin>
            Modification, Discontinuation, and Termination
          </Header>
          <p>
            We reserve the right at any time and from time-to-time to modify,
            edit, delete, suspend or discontinue, temporarily or permanently
            this Site (or any portion thereof) and/or the information,
            materials, products and/or services available through this Site (or
            any part thereof) with or without notice. You agree that we shall
            not be liable to you or to any third party for any such
            modification, editing, deletion, suspension or discontinuance of
            this Site.{" "}
          </p>
          <p>
            You also agree that Fireside, in its sole discretion, may terminate
            your password, account (or any part thereof), or use of this Site
            for any reason, including, without limitation, for lack of use or if
            Fireside believes that you have violated or acted inconsistently
            with the letter or spirit of these Terms of Use. You agree that any
            termination of your access to this Site under any provision of these
            Terms of Use may be effected without prior notice, and acknowledge
            and agree that Fireside may immediately deactivate or delete your
            account and all related information in your account and/or bar any
            further access to this Site. Further, you agree that Fireside shall
            not be liable to you or any third-party for any termination of your
            access to this Site.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            Waiver
          </Header>
          <p>
            Our failure at any time to require performance of any provision of
            these Terms of Use or to exercise any right provided for herein will
            not be deemed a waiver of such provision or such right. All waivers
            must be in writing. Unless the written waiver contains an express
            statement to the contrary, no waiver by Fireside of any breach of
            any provision of these Terms of Use or of any right provided for
            herein will be construed as a waiver of any continuing or succeeding
            breach of such provision, a waiver of the provision itself, or a
            waiver of any right under these Terms of Use.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            Severability
          </Header>
          <p>
            If any provision of these Terms of Use is held by a court of
            competent jurisdiction to be contrary to law, such provision will be
            changed and interpreted so as to best accomplish the objectives of
            the original provision to the fullest extent allowed by law and the
            remaining provisions of these Terms of Use will remain in full force
            and effect.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            Governing Law, Jurisdiction and Venue
          </Header>
          <p>
            These Terms of Use will be governed under the laws of the State of
            Delaware without regard to its conflicts of law provisions. All
            actions or proceedings arising out of or relating to these Terms of
            Use will be venued exclusively in state or federal court in the
            State of Delaware. You hereby irrevocably consent and submit to the
            personal jurisdiction of said courts for all such purposes. However,
            we retain the right to bring legal proceedings in any jurisdiction
            where we believe that infringement of these Terms of Use is taking
            place or originating.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            Indemnity
          </Header>
          <p>
            {" "}
            You agree to indemnify and hold Fireside, its subsidiaries, and
            affiliates, and their respective officers, agents, partners and
            employees, harmless from any loss, liability, claim, or demand,
            including reasonable attorneys’ fees, made by any third party due to
            or arising out of your use of this Site in violation of these Terms
            of Use and/or arising from a breach of these Terms of Use and/or any
            breach of your representations and warranties set forth above and/or
            if any material that you post using this Site causes us to be liable
            to another. We reserve the right to defend any such claim, and you
            agree to provide us with such reasonable cooperation and information
            as we may request.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            These Terms of Use May Change
          </Header>
          <p>
            {" "}
            These Terms of Use are current as of the effective date set forth
            above. Fireside reserves the right to change these Terms of Use from
            time to time consistent with applicable laws and principles. These
            changes will be effective as of the date we post the revised version
            on this Site. Your continued use of this Site after we have posted
            the revised Terms of Use constitutes your agreement to be bound by
            the revised Terms of Use. If at any time you choose not to accept
            these Terms of Use, you should not use this Site.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            Entire Agreement
          </Header>{" "}
          <p>
            These Terms of Use (together with our Privacy Policy and any Privacy
            Notices or click-through agreements applicable to you) contain the
            entire understanding and agreement between you and Fireside with
            respect to this Site and supersede all previous communications,
            negotiations, and agreements, whether oral, written, or electronic,
            between you and Fireside with respect to this Site and your use of
            this Site.
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            Definitions:
          </Header>
          <p>
            <em>The terms “Fireside,” “we,” “us,” and “our” </em>refer to
            Fireside Technologies Inc.{" "}
          </p>
          <p>
            <em>The term “Content”</em> refers to all of the software and code
            comprising or used to operate this Site, and all of the text,
            photographs, images, illustrations, graphics, sound recordings,
            video and audio-video clips, and other materials available on this
            Site, including User-Generated Content and Feedback.{" "}
          </p>
          <p>
            <em>The term “Feedback”</em>
            refers to the Content you post on or through this Site that is
            specifically about how we can improve this Site and the products and
            services we make available through this Site.
          </p>
          <p>
            <em> The term “including”</em>
            means “including, but not limited to.”{" "}
          </p>
          <p>
            <em>The term “Site”</em> refers to the website located at
            fireside.social and owned by Fireside The terms “User-Generated
            Content” or “UGC” refer to your publicly available profile
            information and all content that you post using the social
            networking tools we make available to you. UGC does not include
            “Feedback.”
          </p>
        </section>
        <section>
          <Header h3 noMargin>
            Questions
          </Header>
          <p>
            If you have any questions about this Site or these Terms of Use,
            please contact us via email at hello@fireside.social
          </p>
        </section>
      </article>
    </FlexVC>
  </SceneTemplate>
);
export default TermsOfServiceScene;
