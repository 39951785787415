import React from "react";
import PropTypes from "prop-types";
import formatDate from "../../../utilities/functions/formatDate";
import HideMobile from "../../Shared/HideMobile";
import Mobile from "../../Shared/Mobile";
import Header from "../../Shared/Header";
import styled from "styled-components";

const SpaceBetweenRow = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 576px) {
    justify-content: center;
    margin: 0px;
  }
`;
const TextWithDate = ({ date }) => (
  <SpaceBetweenRow>
    <HideMobile>
      <Header h3 light>
        Together since {formatDate(date)}
      </Header>
    </HideMobile>
    <Mobile>
      <Header h5 light center>
        Together since {formatDate(date)}
      </Header>
    </Mobile>
  </SpaceBetweenRow>
);

TextWithDate.propTypes = {
  date: PropTypes.string,
};
export default TextWithDate;
