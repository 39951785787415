import React, { Component } from "react";
import autosize from "autosize";
import "emoji-mart/css/emoji-mart.css";
import { NimblePicker } from "emoji-mart";
import data from "emoji-mart/data/twitter.json";
import Emoticon from "../../Shared/Emoticon";
import IconHolder from "../../Shared/IconHolder";
import InputAndEmojiHolder from "../../Shared/InputAndEmojiHolder";
import CommentTextarea from "../../Shared/CommentTextarea";
import EmoticonOptionsHolder from "../../Shared/EmoticonOptionsHolder";

class CommentInputArea extends Component {
  state = {
    showEmojis: false,
  };
  componentDidMount() {
    autosize(this.textarea);
  }

  // handleKeyPress = (e) => {
  //   if (e.key === "Enter" || e.which === 13) {
  //     this.props.submitForm();
  //   }
  // };
  addEmoji = (e) => {
    this.setState({
      showEmojis: !this.state.showEmojis,
    });

    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));

    let emojiPic = String.fromCodePoint(...codesArray);

    this.props.setFieldValue(
      "comment_text",
      `${this.props.values.comment_text} ${emojiPic} `
    );
  };

  showEmojis = (e) => {
    this.setState({
      showEmojis: !this.state.showEmojis,
    });
  };

  render() {
    let { props } = this;
    let { field } = props;
    return (
      <InputAndEmojiHolder>
        <CommentTextarea
          placeholder="Write comment here"
          aria-label="Comment textarea"
          ref={(c) => (this.textarea = c)}
          rows={1}
          {...field}
          {...props}
        />

        {this.state.showEmojis ? (
          <EmoticonOptionsHolder onClick={this.showEmojis}>
            <NimblePicker
              onSelect={this.addEmoji}
              set="twitter"
              data={data}
              emojiSize={20}
              emoji="point_up"
              exclude={["symbols", "flags", "custom"]}
              sheetSize={16}
              title="Pick your emoji…"
            />
          </EmoticonOptionsHolder>
        ) : (
          <IconHolder>
            <Emoticon handleClick={this.showEmojis} />
          </IconHolder>
        )}
      </InputAndEmojiHolder>
    );
  }
}

export default CommentInputArea;
