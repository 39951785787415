import React, { Component } from "react";
import styled from "styled-components";
import { fontSizes } from "../Shared/styleGuide";
import Col from "react-bootstrap/Col";
import Text from "../Shared/Text";
import Button from "../Shared/Button";
import BorderBtmRow from "../Shared/BorderBtmRow";
import history from "../../history";

const RightDiv = styled(Col)`
  display: flex;
  justify-content: flex-end;
  padding: 0px;
`;
const LeftDiv = styled(Col)`
  padding: 0px;
`;
class LabelTextBtnBorder extends Component {
  render() {
    const { props } = this;

    return (
      <BorderBtmRow bordercolor={props.bordercolor}>
        <LeftDiv xs={{ span: 10 }} md={{ span: 8 }}>
          <Text
            margin={"0px 0px 10px 0px"}
            display={"block"}
            fontSize={props.labelSize}
            textAlign={"left"}
            cursor={props.linkable && "pointer"}
            onClick={() => props.linkable && history.push(`/${props.linkable}`)}
          >
            {props.label}
          </Text>
          <Text display={"block"} textAlign={"left"} fontSize={props.fontSize}>
            {props.children}
          </Text>
        </LeftDiv>
        <RightDiv xs={{ span: 2 }} md={{ span: 4 }}>
          {props.form ? (
            props.showForm ? (
              <Button
                btntype={"grey"}
                onClick={() => props.handleClick(props.label.toLowerCase())}
              >
                <Text fontSize={fontSizes.h4}>{"Close"}</Text>
              </Button>
            ) : (
              !props.disabled && (
                <Button
                  onClick={() => props.handleClick(props.label.toLowerCase())}
                  {...props}
                >
                  <Text fontSize={fontSizes.h4}>{props.action}</Text>
                </Button>
              )
            )
          ) : (
            <Button
              onClick={() => props.handleClick(props.label.toLowerCase())}
              {...props}
            >
              <Text fontSize={fontSizes.h4}>{props.action}</Text>
            </Button>
          )}
        </RightDiv>
        {props.showForm && <LeftDiv md={{ span: 8 }}>{props.form} </LeftDiv>}
      </BorderBtmRow>
    );
  }
}

export default LabelTextBtnBorder;
