import React from "react";
import styled from "styled-components";
import history from "../../history";
import Header from "../Shared/Header";
import Circle from "../Shared/Circle";
import FlexVC from "../Shared/FlexVC";

const CenterContentsCol = styled(FlexVC)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 2rem;
  margin: 0.5rem 0rem;
`;
const TextWithCircle = ({ mode, name, circle_id }) => (
  <CenterContentsCol>
    <Header h3 center light mode={mode}>
      Shared with:
    </Header>
    <Circle
      size={2}
      bordercolor={"white"}
      border
      name={name}
      mode={mode}
      onClick={() => circle_id && history.push(`/circles/${circle_id}`)}
      cursor
    />
    <Header
      h3
      center
      light
      cursor
      mode={mode}
      handleHeaderClick={() =>
        circle_id && history.push(`/circles/${circle_id}`)
      }
    >
      {name}
    </Header>
  </CenterContentsCol>
);

export default TextWithCircle;
