import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import GetStartedScene from "../Scenes/GetStartedScene";
import { getInvites } from "../../store/actions";

class GetStartedSceneConnected extends Component {
  async componentDidMount() {
    await this.props.getInvites();
  }
  render() {
    return <GetStartedScene {...this.props} />;
  }
}

const mapStateToProps = ({ auth, invites }) => {
  const invitesList = Object.values(invites);
  const onlyUserInvites = invitesList.filter((invite) => {
    return invite && invite.invited_email === auth.email;
  });

  const onlyPendingInvites = onlyUserInvites.filter((invite) => {
    return invite.accept_status == "Pending";
  });
  let inviteId =
    onlyUserInvites.length > 0 &&
    onlyPendingInvites &&
    onlyPendingInvites[0] &&
    onlyPendingInvites[0].id;
  return {
    inviteId,
  };
};

export default withRouter(
  connect(mapStateToProps, { getInvites })(GetStartedSceneConnected)
);
