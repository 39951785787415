import React from "react";
import Modal from "../BasicModal";
import ModalHeader from "../ModalHeader";
import Header from "../../Shared/Header";

const ConfirmDraftModal = ({ show, handleClose }) => (
  <Modal show={show} onHide={handleClose} sm>
    <ModalHeader handleClick={handleClose} />
    <Modal.Body>
      <Header h3 center light>
        Your draft has been saved!
      </Header>
    </Modal.Body>
    <Modal.Footer />
  </Modal>
);
export default ConfirmDraftModal;
