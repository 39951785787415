import React from "react";
import styled from "styled-components";
const FlexUl = styled("ul")`
  display: ${({ full }) => (full ? "block" : "inline-flex")};
  flex-direction: ${({ direct }) => direct};
  margin: 0rem;
  padding-inline-start: 0rem;
  width: ${({ width }) => (width ? width : "auto")};

  li {
    padding: ${({ lipad }) => (lipad ? lipad : "0px 7px")};
    padding-inline-start: ${({ nps }) => (nps ? "0px" : "26px")};
    list-style: none;
  }
`;
export default FlexUl;
