import React from "react";
import formatDate from "../../utilities/functions/formatDate";
import CirclePrevQField from "../Lists/CirclePrevQField";
import AbsoluteDiv from "../Shared/AbsoluteDiv";
import FlexVC from "../Shared/FlexVC";
import styled from "styled-components";

const StaticPromptList = styled(FlexVC)`
  position: relative;
  flex-direction: column;
  justify: flex-start;
  width: 100%;
  filter: blur(4px);
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
`;
// ON CIRCLES PAGE
const BlurredPrompts = () => (
  <>
    <StaticPromptList>
      <AbsoluteDiv />
      <CirclePrevQField
        key={10000}
        label={formatDate("2019-12-05T23:33:41.638167Z")}
        action={"Nudge"}
      >
        Describe a strange dream you had recently.
      </CirclePrevQField>
      <CirclePrevQField
        key={20000}
        label={formatDate("2019-11-05T23:33:41.638167Z")}
        action={"Read Response"}
      >
        What is something that is popular now but annoys you? Why?
      </CirclePrevQField>
      <CirclePrevQField
        key={30000}
        label={formatDate("2019-10-05T23:33:41.638167Z")}
        action={"Read Response"}
      >
        What did you want to be when you grew up?
      </CirclePrevQField>
      <CirclePrevQField
        key={40000}
        label={formatDate("2019-09-05T23:33:41.638167Z")}
        action={"Read Response"}
      >
        What is your favorite memory?
      </CirclePrevQField>
      <CirclePrevQField
        key={50000}
        label={formatDate("2019-08-05T23:33:41.638167Z")}
        action={"Read Response"}
      >
        How would you make the world a better place?
      </CirclePrevQField>
    </StaticPromptList>
  </>
);

export default BlurredPrompts;
