import React from "react";
import { colors } from "../styleGuide";

const Bell = ({ mode }) => (
  <svg
    id="bell"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8563 10.1187C19.8563 4.01875 16.575 1.94375 13.5063 1.48125C13.5063 1.45 13.5125 1.41875 13.5125 1.38125C13.5125 0.6125 12.8313 0 12 0C11.1687 0 10.5125 0.6125 10.5125 1.38125C10.5125 1.41875 10.5125 1.45 10.5188 1.48125C7.44375 1.95 4.14375 4.03125 4.14375 10.1313C4.14375 17.2438 2.375 18.0063 0 20.0063H24C21.6375 18 19.8563 17.2313 19.8563 10.1187Z"
      fill={mode ? colors.slateGrey : colors.selago}
    />
    <path
      d="M12.0125 23.9999C13.6875 23.9999 15.0625 22.7561 15.2437 21.3124H8.78125C8.95625 22.7561 10.3375 23.9999 12.0125 23.9999Z"
      fill={mode ? colors.slateGrey : colors.selago}
    />
  </svg>
);
export default Bell;
