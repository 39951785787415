import React, { Component } from "react";
import { connect } from "react-redux";
import { signUp } from "../../store/actions";
import SignUpForm from "../Forms/SignUpForm";
import { Formik } from "formik";
import LoadingScene from "../Scenes/LoadingScene";

class SignUpFormConnected extends Component {
  render() {
    return (
      <Formik
        initialValues={{
          password1: "",
          password2: "",
          first_name: "",
          last_name: "",
          email: "",
          inviteCode: "",
        }}
        onSubmit={(values, actions) => {
          this.props
            .signUp(values, actions)
            .then(() => {})
            .catch((error) => {
              const { data } = error.response;
              let errorMessage = data.non_field_errors ||
                data.email ||
                data.password1 || ["Sign Up Credentials Invalid"];
              actions.setFieldError("general", errorMessage[0]);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {({ values, errors, isSubmitting }) =>
          !isSubmitting ? (
            <SignUpForm values={values} errors={errors} />
          ) : (
            <LoadingScene />
          )
        }
      </Formik>
    );
  }
}

export default connect(null, { signUp })(SignUpFormConnected);
