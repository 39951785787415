import React, { Component } from "react";
import PropTypes from "prop-types";
import { fontSizes } from "../../Shared/styleGuide";
import CircleNameEditModalConnected from "../../containers/CircleNameEditModalConnected";
import Text from "../../Shared/Text";
import Button from "../../Shared/Button";
import styled from "styled-components";
import Row from "react-bootstrap/Row";

const SpaceBetweenRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ margin }) => margin};
  @media (max-width: 576px) {
    justify-content: center;
    margin: 0px;
  }
`;
class CircleNameWithEdit extends Component {
  state = {
    show: false,
  };
  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  handleShow = () => {
    this.setState({
      show: true,
    });
  };
  render() {
    const { circle } = this.props;
    return (
      <SpaceBetweenRow>
        <Text
          fontSize={fontSizes.h1}
          display={"inline-block"}
          margin={"0.2rem"}
          textAlign={"left"}
        >
          {circle.circle_name}
        </Text>
        <Button
          btntype="tertiary"
          fontSize={fontSizes.caption}
          onClick={this.handleShow}
        >
          Change Name
        </Button>
        <CircleNameEditModalConnected
          show={this.state.show}
          handleClose={this.handleClose}
          circle={circle}
        />
      </SpaceBetweenRow>
    );
  }
}
CircleNameWithEdit.propTypes = {};
export default CircleNameWithEdit;
