import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colors } from "../styleGuide";

const ArcContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: ${({ h }) => (h ? h : "inherit")};
  margin: 0;
  margin-top: -1px;
  background: ${({ mode }) => (mode ? colors.selago : colors.slateGrey)};
  color: ${({ mode }) => (mode ? `${colors.cloudBurst}` : `${colors.white}`)};
  #curve {
    position: absolute;
    width: 100%;
    bottom: -1px;
    path {
      fill: white;
    }
  }
  @media (max-width: 576px) {
    padding: 1rem 0rem 1.4rem 0rem;
    height: ${({ mh }) => (mh ? mh : "inherit")};
  }
`;
ArcContainer.propTypes = {
  mode: PropTypes.number,
};
ArcContainer.defaultProps = {
  mode: 1, // 0 for dark, 1 for light,
};
export default ArcContainer;
