import React, { Component } from "react";
import Slider from "./Slider";
import Header from "../../Shared/Header";
import FlexVC from "../../Shared/FlexVC";
import Button from "../../Shared/Button";

class RomanticIntensity extends Component {
  state = {
    vertical: false,
  };

  handleChange = (val) => {
    this.props.setFieldValue("romanticScaleVal", val);
  };
  handleSliderDir = () => {
    if (window.outerWidth <= 576) {
      this.setState({
        vertical: true,
      });
    } else {
      this.setState({
        vertical: false,
      });
    }
  };
  componentDidMount() {
    this.handleSliderDir();
    window.addEventListener("resize", this.handleSliderDir);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleSliderDir);
  }
  render() {
    const {
      handleBack,
      values: { romanticScaleVal },
    } = this.props;
    return (
      <>
        <FlexVC direct="column" justify={"center"} margin={"23px 0px 0px 0px"}>
          <Header h3 light center ln>
            We won't share this with your partner, it just helps us provide
            better quesitons.
          </Header>
        </FlexVC>

        <FlexVC
          direct="row"
          align="center"
          justify="center"
          full
          mobileCol
          margin={this.state.vertical ? "23px 0px 31px 0px" : "100px 0px"}
        >
          <Header h5 center noMargin desOn>
            Had A Few Dates
          </Header>

          <Slider
            min={1}
            max={5}
            defaultValue={romanticScaleVal}
            onChange={(val) => this.handleChange(val)}
            marks={{ 1: "", 2: "", 3: "", 4: "", 5: "" }}
            step={null}
            vertical={this.state.vertical}
          />
          <Header h5 center noMargin desOn>
            Married, Kids, Etc
          </Header>
        </FlexVC>
        <FlexVC direct="row" justify="center" margin={"1rem 0rem"} full>
          <FlexVC direct="column" full>
            <Button type={"submit"} width={"236px"}>
              Create Circle
            </Button>
            <Button
              type={"button"}
              btntype="tertiary"
              onClick={() => handleBack()}
              fontSize="0.875"
            >
              Back
            </Button>
          </FlexVC>
        </FlexVC>
      </>
    );
  }
}

export default RomanticIntensity;
