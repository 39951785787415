import { createStore, applyMiddleware, compose } from "redux";
import reducers from "./reducers";
import { loadStateFromLocalStorage } from "../utilities/functions/localStorage";
import reduxThunk from "redux-thunk";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState = loadStateFromLocalStorage();
export const store = createStore(
  reducers,
  persistedState,
  composeEnhancers(applyMiddleware(reduxThunk))
);
