import React from "react";
import formatDateWithTime from "../../utilities/functions/formatDateWithTime";
import { colors, dfs, mfs, dln, mln } from "../Shared/styleGuide";
import Circle from "../Shared/Circle";
import Text from "../Shared/Text";
import FlexVC from "../Shared/FlexVC";
import Header from "../Shared/Header";
import CommentBubble from "../Shared/CommentBubble";
import ToggleableEllipsis from "../Shared/ToggleableEllipsis";

const CircleIconWithComment = (props) => (
  <FlexVC direct="column" full>
    <FlexVC direct="row" full align={"flex-end"}>
      <FlexVC direct="column">
        <Circle
          margin={0}
          size={2.6125}
          plainBg={true}
          name={props.poster_name}
          bordercolor={"transparent"}
        />
      </FlexVC>
      <FlexVC direct="column" width={"96%"} align="flex-start">
        <CommentBubble>
          <Text textAlign={"left"} margin={"0px 5px"} fontWeight={600}>
            {props.poster_name}
          </Text>
          {props.match.path === "/timeline" ? (
            <ToggleableEllipsis innerChars={props.comment} comment />
          ) : (
            <Text
              wba
              ln={dln.h4}
              mln={mln.h3}
              fontSize={dfs.h4}
              mfontSize={mfs.h3}
              textAlign={"left"}
            >
              {props.comment}
            </Text>
          )}
        </CommentBubble>
      </FlexVC>
    </FlexVC>
    <FlexVC direct="row" full margin={"0px 0px 0px 125px"}>
      <Header h5 light color={colors.greyChateau}>
        {formatDateWithTime(props.commentDate)}
      </Header>
    </FlexVC>
  </FlexVC>
);

export default CircleIconWithComment;
