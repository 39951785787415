import React, { Component } from "react";
import history from "../../history";
import HeaderSection from "../containers/HeaderSectionConnected";
import SceneTemplate from "./SceneTemplate";
import HowItWorksSlide from "../Sections/HowItWorksSlide";
import HowItWorksProgress from "../Sections/HowItWorksProgress";
import FlexVC from "../Shared/FlexVC";
import Button from "../Shared/Button";
import LoadingScene from "../Scenes/LoadingScene";
import styled from "styled-components";

export const FadeIn = styled.div`
  transition: 0.5s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
`;
export const FadeOut = styled.div`
  transition: 0.5s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
`;
class HowItWorksScene extends Component {
  state = {
    step: 0,
    visible: true,
    loading: true,
  };

  handleStep = (direction) => {
    // only three images in rotation
    const newStep = (this.state.step + direction + 3) % 3;
    this.setState({
      step: newStep,
    });
    // takes you to top of page
    window.scrollTo(0, 0);
  };
  componentDidMount() {
    this.setState({ loading: false });
  }
  render() {
    const { step, loading } = this.state;
    const { currentInvite, createdCircle } = this.props;
    if (loading) {
      return <LoadingScene />;
    }
    return (
      <SceneTemplate
        mode={1}
        whole
        createdCircle={createdCircle}
        header={
          <HeaderSection
            header="How It Works"
            mode={1}
            short={!createdCircle}
          />
        }
      >
        <FlexVC direct={"column"} full>
          <HowItWorksSlide step={step} handleStep={this.handleStep} />
          <HowItWorksProgress step={step} />
          <FlexVC direct={"column"} full margin={"3rem 0rem 6rem 0rem"}>
            {step == 2 ? (
              <Button
                width={"236px"}
                btntype="primary"
                type="button"
                onClick={() =>
                  currentInvite
                    ? history.push(`/invites/${currentInvite}`)
                    : history.push("/welcome")
                }
              >
                Get Started
              </Button>
            ) : (
              <Button
                fontSize={"0.825"}
                width={"236px"}
                btntype="tertiary"
                type="button"
                onClick={() =>
                  currentInvite
                    ? history.push(`/invites/${currentInvite}`)
                    : history.push("/welcome")
                }
              >
                Skip Tutorial
              </Button>
            )}
          </FlexVC>
        </FlexVC>
      </SceneTemplate>
    );
  }
}
export default HowItWorksScene;
