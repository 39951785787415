import React from "react";
import { colors } from "../Shared/styleGuide";
import formatDate from "../../utilities/functions/formatDate";
import history from "../../history";
import Header from "../Shared/Header";
import FlexVC from "../Shared/FlexVC";

const CircleNameDate = ({ name, date, circle_id }) => (
  <FlexVC direct={"row"} align={"center"}>
    <Header
      h3
      inblock
      onClick={() => circle_id && history.push(`circles/${circle_id}`)}
    >
      {name}
    </Header>
    <Header
      inblock
      light
      h4
      margin={"0rem 0rem 0rem 0.825rem"}
      color={colors.greyChateau}
    >
      {"|"}
    </Header>
    <Header
      inblock
      light
      h4
      margin={"0rem 0rem 0rem 0.825rem"}
      color={colors.greyChateau}
    >
      {formatDate(date)}
    </Header>
  </FlexVC>
);

export default CircleNameDate;
