import React, { Component } from "react";
import { connect } from "react-redux";
import { createNudge } from "../../store/actions";
import MemberResStatusList from "../Lists/MemberResStatusList";
import GeneralConfirmModal from "../Modals/GeneralConfirmModal";

class MemberResStatusListConnected extends Component {
  state = {
    show: false,
  };
  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  handleShow = () => {
    this.setState({
      show: true,
    });
  };
  handleNudge = async (circleId, memberId) => {
    await this.props.createNudge(circleId, memberId);
    this.handleShow();

  };
  render() {
    return (
      <>
        <GeneralConfirmModal
          show={this.state.show}
          handleClose={this.handleClose}
          message={"Sent!"}
        />
        <MemberResStatusList handleNudge={this.handleNudge} {...this.props} />
      </>
    );
  }
}

export default connect(
  null,
  { createNudge }
)(MemberResStatusListConnected);
