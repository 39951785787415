import React from "react";
import styled from "styled-components";
import btnStyle from "./btnStyle";

const Button = styled("button")`
  display: inline-block;
  user-select: none;
  vertical-align: middle;
  padding: 0.375rem 0.75rem;
  width: ${({ width }) => width};
  color: ${({ btntype }) => btnStyle(btntype).color};
  font-family: Avenir Next;
  font-size: ${({ btntype, fontSize }) =>
    fontSize ? `${fontSize}` : btnStyle(btntype).fontSize}rem;
  line-height: ${({ btntype }) => btnStyle(btntype).fontSize * 1.39}rem;
  text-align: center;
  background: ${({ btntype }) => btnStyle(btntype).activeBg};
  border: 1px solid ${({ btntype }) => btnStyle(btntype).bordercolor};
  text-decoration: ${({ btntype, noline }) =>
    noline ? "none" : btnStyle(btntype).textDecoration};
  border-radius: ${({ btntype }) => btnStyle(btntype).borderRadius};
  height: 100%;
  font-weight: ${({ fontWeight }) => fontWeight};
  :focus {
    outline: none;
    background: ${({ btntype }) => btnStyle(btntype).hoverBg};
  }

  :hover {
    background: ${({ btntype }) => btnStyle(btntype).hoverBg};
    border: 1px solid ${({ btntype }) => btnStyle(btntype).bordercolor};
    box-shadow: 1px 4px 10px ${({ btntype }) => btnStyle(btntype).bxShadow};
  }
  :disabled {
    background: ${({ btntype }) => btnStyle(btntype).disabledBg};
    border: 1px solid ${({ btntype }) => btnStyle(btntype).bordercolor};
  }
  @media (max-width: 576px) {
    ${({ wm }) => wm && `width:94px; height:50px;font-size:14px;padding:0rem`};

    :hover {
      background: ${({ btntype }) => btnStyle(btntype).activeBg};
      border: 1px solid ${({ btntype }) => btnStyle(btntype).bordercolor};
      box-shadow: none;
    }
    :focus {
      outline: none;
      background: ${({ btntype }) => btnStyle(btntype).activeBg};
    }
  }
`;
Button.defaultProps = {
  borderRadius: "5px",
  fontWeight: "500",
};

export default Button;
