import React, { Component } from "react";
import { connect } from "react-redux";
import CircleNameEditModal from "../Modals/CircleNameEditModal";
import ProtoTypes from "prop-types";

class CircleNameEditModalConnected extends Component {
  handleLeaveCircle = (circle_id) => {};
  render() {
    let { show, handleClose, circle } = this.props;
    return (
      <CircleNameEditModal
        handleLeaveCircle={this.handleLeaveCircle}
        show={show}
        handleClose={handleClose}
        circle={circle}
      />
    );
  }
}

CircleNameEditModalConnected.protoTypes = {
  show: ProtoTypes.bool,
  handleClose: ProtoTypes.func,
  circle: ProtoTypes.element
}

export default connect(
  null,
  null
)(CircleNameEditModalConnected);
