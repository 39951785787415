import React, { Component } from "react";
import Footer from "../Shared/Footer";
import Wrapper from "../Shared/Wrapper";
import MainNavbar from "../containers/NavbarConnected";
import MobileNav from "../containers/MobileNavConnected";
import MobileDropdownConnected from "../containers/MobileDropdownConnected";
import Mobile from "../Shared/Mobile";
import HideMobile from "../Shared/HideMobile";

class ResponseSceneTemplate extends Component {
  state = {
    displayMobileMenu: false,
  };
  toggleMobileMenu = (display) => {
    this.setState({
      displayMobileMenu: display,
    });
  };
  render() {
    let { children, header, mode, margin, props } = this.props;
    return (
      <Wrapper {...props}>
        {this.state.displayMobileMenu ? (
          <MobileDropdownConnected
            displayMenu={this.toggleMobileMenu}
            mode={mode}
            {...this.props}
          />
        ) : (
          <>
            <HideMobile>
              <MainNavbar mode={mode} />
            </HideMobile>
            <Mobile>
              <MobileNav mode={mode} displayMenu={this.toggleMobileMenu} />
            </Mobile>
            {header}
            {children}
            <Footer />
          </>
        )}
      </Wrapper>
    );
  }
}
export default ResponseSceneTemplate;
