import React, { Component } from "react";
import { connect } from "react-redux";
import { getCTAs } from "../../store/actions";
import { Route, Redirect } from "react-router-dom";

// Private Route checks if the user is signed in and if a circle was created
class PrivateRoute extends Component {
  render() {
    const { component: Component, auth, ctas, location, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          if (auth.isSignedIn && location.pathname == "/") {
            return (
              <Redirect
                to={{
                  pathname: "/home",
                  state: { from: props.location },
                }}
              />
            );
          } else if (!auth.isSignedIn && location.pathname == "/") {
            return <Component createdCircle={false} {...props} />;
          } else if (auth.isSignedIn) {
            if (ctas.length > 0) {
              return <Component createdCircle={ctas.length > 0} {...props} />;
            } else {
              if (
                location.pathname == "/welcome" ||
                location.pathname == "/error" ||
                location.pathname == "/get-started" ||
                location.pathname == "/how-it-works" ||
                (location.pathname.match(/\/invites\/\S*$/g) &&
                  location.pathname.match(/\/invites\/\S*$/g).length === 1)
              ) {
                return <Component createdCircle={ctas.length > 0} {...props} />;
              } else {
                console.log("I'm redirecting");
                return (
                  <Redirect
                    to={{
                      pathname: "/get-started",
                      state: { from: props.location },
                    }}
                  />
                );
              }
            }
          } else {
            console.log("I've stopped by the is signed in else");
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: props.location },
                }}
              />
            );
          }
        }}
      />
    );
  }
}
const mapStateToProps = ({ auth, ctas }) => {
  return {
    auth,
    ctas: Object.values(ctas),
  };
};
export default connect(mapStateToProps, { getCTAs })(PrivateRoute);
