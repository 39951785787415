import React from "react";
import styled from "styled-components";
import { colors, fontSizes } from "../Shared/styleGuide";

const Oval = styled("button")`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  justify-content: center;
  font-family: Avenir Next;
  color: ${({ color }) => color};
  font-size: ${({ smf }) => (smf ? fontSizes.caption : fontSizes.normal)}rem;
  background: ${({ bg, colorstatic }) =>
    colorstatic ? colors.eastBay : bg ? colors.slateGrey : colors.maverick};
  border-radius: 20px;
  border: 1px solid ${({ bordercolor }) => bordercolor};
  margin: ${({ margin }) => (margin ? margin : "inherit")};
  width: ${({ width }) => (width ? width : "auto")};
  line-height: ${({ short }) => (short ? "auto" : "40px")};
  height: ${({ short }) => (short ? "26px" : "40px")};

  :focus {
    outline: none;
    background: ${colors.eastBay};
  }

  :hover {
    background: ${({ disabled, bg }) =>
      disabled ? (bg ? colors.slateGrey : colors.maverick) : colors.eastBay};
  }
  @media (max-width: 576px) {
  
    :hover,
    :focus {
      background: ${({ disabled, bg }) =>
        disabled ? (bg ? colors.slateGrey : colors.maverick) : colors.eastBay};
  
      outline: none;
      box-shadow: none;
    }
    :focus {
      background: ${colors.eastBay};
    }
`;

Oval.defaultProps = {
  bordercolor: "transparent",
  color: `${colors.white}`,
  bg: false,
};

export default Oval;
