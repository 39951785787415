import React, { Component } from "react";
import SceneTemplate from "./SceneTemplate";
import HeaderSection from "../containers/HeaderSectionConnected";
import PasswordResetConfirmFormConnected from "../containers/PasswordResetConfirmFormConnected";
import FlexVC from "../Shared/FlexVC";
import Header from "../Shared/Header";

class PasswordResetConfirmScene extends Component {
  render() {
    return (
      <SceneTemplate
        mode={0}
        createdCircle={false}
        header={<HeaderSection header={"New Password"} mode={0} short />}
      >
        <FlexVC direct="column" justify="center">
          <Header h2 light center>
            Enter your new password
          </Header>
          <PasswordResetConfirmFormConnected />
        </FlexVC>
      </SceneTemplate>
    );
  }
}

export default PasswordResetConfirmScene;
