import styled from "styled-components";

// Used to temp. make blurred listings unclickable
const AbsoluteDiv = styled("div")`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100%;
  width: 100%;
  background: transparent;
`;

export default AbsoluteDiv;
