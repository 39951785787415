import { combineReducers } from "redux";

// Reducers
import authReducer from "./authReducer.js";
import circleReducer from "./circleReducer";
import promptReducer from "./promptReducer";
import userReducer from "./userReducer";
import circleMemberReducer from "./circleMemberReducer";
import circleInviteReducer from "./circleInviteReducer";
import commentReducer from "./commentReducer";
import responseReducer from "./responseReducer";
import invitesReducer from "./invitesReducer";
import notificationReducer from "./notificationReducer";
import ctaReducer from "./ctaReducer";
import onboardingQReducer from "./onboardingQReducer";
import imageReducer from "./imageReducer";

// Combination of all reducers
const coreReducer = combineReducers({
  auth: authReducer,
  circles: circleReducer,
  prompts: promptReducer,
  users: userReducer,
  members: circleMemberReducer,
  comments: commentReducer,
  responses: responseReducer,
  invites: invitesReducer,
  notifications: notificationReducer,
  ctas: ctaReducer,
  onboardingQs: onboardingQReducer,
  images: imageReducer,
  circleInvites: circleInviteReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    state = undefined;
  }

  return coreReducer(state, action);
};
export default rootReducer;
