import React from "react";
import styled from "styled-components";
import { colors, fontSizes } from "../Shared/styleGuide";
import Box from "../Shared/Box";
import Text from "../Shared/Text";
import Header from "../Shared/Header";
import Check from "../Shared/icons/Check";
import Button from "../Shared/Button";
import BorderBtmRow from "../Shared/BorderBtmRow";
import FlexVC from "../Shared/FlexVC";
import CircleIconNameDate from "../Shared/CircleIconNameDate";
import TextWArrow from "../Shared/TextWArrow";

// Google Analytics
import { Event } from "../Tracking";

const MemberListStatus = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  :last-child {
    border-bottom: transparent;
  }
`;
const CircleListingContainer = styled("div")`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-height: ${(props) => (props.open ? "100%" : "0")};
  overflow: hidden;
  transform: ${(props) => (props.open ? "translateY(0)" : "translateY(100%)")};
  transition-timing-function:${(props) => (props.open ? "ease-out" : "ease-in")}
  transition: ${(props) => (props.open ? "0.3s" : "0.2s")};
  opacity: 1;
`;
class MemberResStatusList extends React.Component {
  state = {
    displayList: true,
  };
  handleDisplay = () => {
    this.setState({
      displayList: !this.state.displayList,
    });
  };
  render() {
    const {
      responses,
      circlename,
      circle_id,
      onClick,
      handleNudge,
    } = this.props;
    return (
      <Box display="flex" padding={"2rem 3rem"}>
        <Header h2 light>
          {circlename}'s progress
        </Header>

        <TextWArrow
          bordercolor={colors.slateGrey}
          btntype={"tertiary"}
          noline={1}
          onClick={onClick || this.handleDisplay}
          show={this.state.displayList}
        >
          Hide
        </TextWArrow>

        <CircleListingContainer open={this.state.displayList}>
          <MemberListStatus>
            {responses.map((res) => (
              <BorderBtmRow
                key={res.id}
                bordercolor={colors.lightGrey}
                lastChild={0}
              >
                <CircleIconNameDate
                  name={res.owner}
                  date={res.created_at}
                  dateText={res.is_submitted === false ? false : true}
                />
                <div>
                  {res.is_submitted === false ? (
                    <Button
                      btntype={"fifth"}
                      type={"button"}
                      onClick={() => {
                        handleNudge(circle_id, res.owner_id);
                        Event("CTA", "Clicked", res.id);
                      }}
                    >
                      Remind
                    </Button>
                  ) : (
                    <Check />
                  )}
                </div>
              </BorderBtmRow>
            ))}
          </MemberListStatus>
          <FlexVC direct={"column"}>
            <Text fontSize={fontSizes.caption} display="block" margin="1rem">
              Send a reminder to members who haven't answered.
            </Text>
          </FlexVC>
        </CircleListingContainer>
      </Box>
    );
  }
}
// <FlexVC direct={"column"}>
//   <Button>Remind all</Button>
//   <Text fontSize={fontSizes.caption} display="block" margin="1rem">
//     A reminder will be sent to all members who haven't answered.
//   </Text>
// </FlexVC>
MemberResStatusList.defaultProps = {};
export default MemberResStatusList;
