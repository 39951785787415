import React from "react";
import CircleIconNameDate from "../Shared/CircleIconNameDate";
import Text from "../Shared/Text";
import ToggleableEllipsis from "../Shared/ToggleableEllipsis";
import FlexVC from "../Shared/FlexVC";
import { dfs, mfs, dln, mln } from "../Shared/styleGuide";

const Response = ({ path, name, date, children }) => (
  <FlexVC direct={"column"} align={"flex-start"} full>
    <CircleIconNameDate
      name={name}
      mfontSize={"1.125"}
      date={date}
      fontWeight={600}
      response
    />
    {path === "/timeline" ? (
      <ToggleableEllipsis innerChars={children} />
    ) : (
      <Text
        ln={dln.h3}
        mln={mln.h3}
        fontSize={dfs.h3}
        mfontSize={mfs.h3}
        textAlign={"left"}
      >
        {children}
      </Text>
    )}
  </FlexVC>
);

export default Response;
