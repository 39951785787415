import { mapKeys } from "lodash";
import { GET_CTAS } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_CTAS:
      return { ...state, ...mapKeys(action.payload, "circle_id") };
    default:
      return state;
  }
};
