import React, { Component } from "react";
import { connect } from "react-redux";
import MobileDropdown from "../Dropdowns/MobileDropdown";

class MobileDropdownConnected extends Component {
  render() {
    let { isSignedIn, userId, mode, name, displayMenu } = this.props;
    return (
      <MobileDropdown
        isSignedIn={isSignedIn}
        userId={userId}
        mode={mode}
        name={name}
        displayMenu={displayMenu}
      />
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    isSignedIn: auth.isSignedIn,
    userId: auth.userId,
    name: `${auth.first_name} ${auth.last_name}`
  };
};
export default connect(mapStateToProps, null)(MobileDropdownConnected);
