import React from "react";
import PropTypes from "prop-types";
import Circle from "../Shared/Circle";
import Text from "../Shared/Text";
import Link from "../Shared/Link";

import NotificationItemContainer from "./NotificationItemContainer";

const NotificationItem = ({
  notice: { name, target_type, target_id, text },
  mobile,
}) => (
  <NotificationItemContainer mobile={mobile}>
    <Circle margin="0rem 0.875rem 0rem 0rem" size="2" name={name} />
    <Text textAlign={mobile ? "left" : "center"} display={"inline-block"}>
      {target_type === "prompt" ? (
        <Link to={`/prompts/${target_id}`} mode={1} noline>
          {text}
        </Link>
      ) : (
        text
      )}
    </Text>
  </NotificationItemContainer>
);

NotificationItem.propTypes = {
  name: PropTypes.string,
};
NotificationItem.defaultProps = {
  name: "fire",
};
export default NotificationItem;
