import React, { Component } from "react";
import CirclePrevQField from "../Lists/CirclePrevQField";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { pick } from "lodash";
import ProtoTypes from "prop-types";

class CirclePrevQFieldConnected extends Component {
  render() {
    const { promptResponses } = this.props;
    return (
      <>
        {promptResponses ? (
          <CirclePrevQField {...this.props} />
        ) : (
          <div>Loading...</div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ responses }, { responsesIDs }) => {
  const promptResponses = Object.values(pick(responses, responsesIDs));
  return {
    promptResponses
  };
};

CirclePrevQFieldConnected.protoTypes = {
  promptResponses: ProtoTypes.array
}

export default withRouter(
  connect(mapStateToProps, null)(CirclePrevQFieldConnected)
);
