import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";

import throttle from "lodash/throttle";
import App from "./components/App";
import { saveStateToLocalStorage } from "./utilities/functions/localStorage";
import { store } from "./store/store";

// Sentry.init({
//   dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
//   environment: `${process.env.REACT_APP_ENV}`,
//   debug: process.env.REACT_APP_SENTRY_DEBUG,
// });

// Subscribe to save the state to localStorage
store.subscribe(
  throttle(() => {
    saveStateToLocalStorage({
      ...store.getState(),
    });
  }, 1000)
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector("#root")
);
