import {
  GET_PROMPTS,
  GET_PROMPT,
  UPDATE_PROMPT,
  UPDATE_PROMPT_RESPONSES,
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_PROMPTS:
      const checkForQT = () => {
        for (let prompt in action.payload) {
          if (!action.payload[prompt].question_text) {
            action.payload[prompt].question_text =
              action.payload[prompt].question.question_text;
          }
        }
      };
      checkForQT();
      return {
        ...state,
        ...action.payload,
      };

    case GET_PROMPT:
      return {
        ...state,
      };
    case UPDATE_PROMPT:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_PROMPT_RESPONSES:
      return {
        ...state,
        [action.payload.promptId]: {
          ...state[action.payload.promptId],
          response: state[action.payload.promptId].response.concat(
            action.payload.id
          ),
        },
      };
    default:
      return state;
  }
};
