import React, { Component } from "react";
import { connect } from "react-redux";
import { createInvite } from "../../store/actions";
import { withRouter } from "react-router-dom";
import MemberAddForm from "../Forms/MemberAddForm";
import { Formik } from "formik";

class MemberAddFormConnected extends Component {
  onSubmit = (values, actions) => {
    this.props.createInvite(this.props.circleInfo.id, values);

    // Clear out form values after submission
    actions.resetForm({
      email: "",
      first_name: "",
      circle_id: this.props.circleInfo.id,
      circle_name: this.props.circleInfo.name,
    });

    // close modal
    this.props.handleClose();
  };

  render() {
    return (
      <Formik
        initialValues={{
          email: "",
          first_name: "",
          circle_id: this.props.circleInfo.id,
          circle_name: this.props.circleInfo.name,
        }}
        onSubmit={(values, actions) => {
          this.onSubmit(values, actions);
        }}
      >
        {({
          values,
          validateField,
          validateForm,
          submitForm,
          setTouched,
          isValid,
          setFieldError,
          setFieldTouched,
          setFieldValue,
          errors,
        }) => (
          <MemberAddForm
            values={values}
            validateField={validateField}
            validateForm={validateForm}
            submitForm={submitForm}
            setTouched={setTouched}
            setFieldValue={setFieldValue}
            isValid={isValid}
            setFieldError={setFieldError}
            setFieldTouched={setFieldTouched}
            errors={errors}
            handleClose={this.props.handleClose}
          />
        )}
      </Formik>
    );
  }
}

export default withRouter(
  connect(
    null,
    { createInvite }
  )(MemberAddFormConnected)
);
