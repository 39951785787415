import styled from "styled-components";

const CarouselSlides = styled("div")`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  position: relative;
`;
export default CarouselSlides;
