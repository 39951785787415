import React, { Component } from "react";
import Circle from "../../Shared/Circle";
import FlexVC from "../../Shared/FlexVC";

/**
 * CircleSpread creates a spread of overlapping circles
 * determined by how many members belong to a circle and
 * follows an alternating color pattern as determined by circleColors
 *
 * Max: 6 circles
 * Min: 1 circles
 *
 * In order to always have the current user's name be the top circle,
 * we loop through members until one less the array length
 * then we manually add a Circle component with the
 * current users's name.
 *
 **/
class CircleSpread extends Component {
  state = {
    circleColors: ["h", "d", "c", "i"],
    members: this.props.members,
    get membersEndIdx() {
      let memLen = this.members.length || 1;
      return memLen >= 6 ? 6 : memLen;
    },
  };
  render() {
    const { members, size, first_name } = this.props;
    const { membersEndIdx, circleColors } = this.state;
    return (
      <FlexVC direct="row">
        {members.slice(1, membersEndIdx).map((member, index) => (
          <Circle
            key={index}
            plainBg={true}
            size={size}
            name={circleColors[index % circleColors.length]}
            margin={"0rem -2.2rem 0rem 0rem"}
          />
        ))}
        <Circle
          resv={circleColors[(membersEndIdx - 1) % circleColors.length]}
          plainBg={true}
          size={size}
          name={first_name}
          margin={membersEndIdx === 1 ? "0rem" : "0rem -2.2rem 0rem 0rem"}
        />
      </FlexVC>
    );
  }
}

CircleSpread.defaultProps = {
  size: 3,
  name: "fire",
};
export default CircleSpread;
