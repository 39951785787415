import React, { Component } from "react";
import PropTypes from "prop-types";
import Pill from "../../Shared/Pill";
import GeneralConfirmModal from "../GeneralConfirmModal";

class CancelInviteBtnWModal extends Component {
  state = {
    show: false,
    disable: false,
  };
  handleClick = () => {
    const { inviteId, deleteInvite, circleId } = this.props;
    this.setState({
      disable: true,
    });

    deleteInvite(inviteId, circleId);
    this.handleShow();
  };
  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  handleShow = () => {
    this.setState({
      show: true,
    });
  };
  render() {
    return (
      <>
        <Pill onClick={this.handleClick} disabled={this.state.disable}>
          Cancel Invite
        </Pill>
        <GeneralConfirmModal
          show={this.state.show}
          handleClose={this.handleClose}
          message={"Invite Cancelled!"}
        />
      </>
    );
  }
}
CancelInviteBtnWModal.propTypes = {};
export default CancelInviteBtnWModal;
