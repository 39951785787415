import styled from "styled-components";

// Holds icons in input and textarea like emoji
const IconHolder = styled("div")`
  position: absolute;
  z-index: 10;
  right: 10px;
  bottom: 16px;
  height: 24px;
  width: 24px;
`;
export default IconHolder;
