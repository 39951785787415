import React from "react";
import styled from "styled-components";
import { colors } from "../Shared/styleGuide";
import Dropdown from "react-bootstrap/Dropdown";
import CustomToggle from "../Shared/CustomToggle";
import NotificationItem from "../Notifications/NotificationItem";
import AlertBell from "../containers/AlertBellConnected";

const MobileDropdown = styled(Dropdown)`
  .dropdown-menu {
    border: 0px;
    border-radius: 0px;
    opacity: 1 !important;
    box-shadow: 0 5px 4px -4px ${colors.transparentBlack};
    top: 15px !important;
    left: 4px !important;
  }
  .dropdown-toggle::after {
    display: none;
  }
`;
const NotificationsMobileDropdown = ({ mode, notifications }) => (
  <MobileDropdown>
    <MobileDropdown.Toggle as={CustomToggle}>
      <AlertBell alert={notifications.length} mode={mode} />
    </MobileDropdown.Toggle>
    <MobileDropdown.Menu>
      {notifications.length < 1 ? (
        <NotificationItem
          notice={{ name: "none", text: "No new notifications" }}
          mobile
        />
      ) : (
        notifications.map((notice, idx) => (
          <NotificationItem
            key={idx}
            notice={notice}
            name={notice.category}
            mobile
          />
        ))
      )}
    </MobileDropdown.Menu>
  </MobileDropdown>
);

export default NotificationsMobileDropdown;
