import React from "react";
import styled from "styled-components";
import { colors, fontSizes } from "../Shared/styleGuide";

const ResponseTextarea = styled("textarea")`
  position: relative;
  height: 400px;
  width: 100%;
  border: 1px solid ${colors.greyChateau};
  border-radius: 6px;
  padding: 20px;
  font-size: ${fontSizes.h2}rem;
  white-space: wrap;
  resize: none;
  outline: none;
  overflow: visible;
`;

export default ResponseTextarea;
