import React from "react";
import styled from "styled-components";
import { colors } from "../styleGuide";

const BarsContainer = styled("div")`
  @media (min-width: 576px) {
    display: none;
  }
`;
// Hamburger or Mobile bars
const Bars = ({ mode, handleClick }) => (
  <BarsContainer onClick={() => handleClick(true)}>
    <svg
      width="34"
      height="26"
      viewBox="0 0 34 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="6"
        y1="5"
        x2="28"
        y2="5"
        stroke={mode ? colors.slateGrey : colors.selago}
        strokeWidth="2"
      />
      <line
        x1="6"
        y1="13"
        x2="28"
        y2="13"
        stroke={mode ? colors.slateGrey : colors.selago}
        strokeWidth="2"
      />
      <line
        x1="6"
        y1="21"
        x2="28"
        y2="21"
        stroke={mode ? colors.slateGrey : colors.selago}
        strokeWidth="2"
      />
    </svg>
  </BarsContainer>
);

export default Bars;
