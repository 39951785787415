import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UpdateEmailForm from "../Forms/UpdateEmailForm";
import { Formik } from "formik";

//TODO: API end not set yet
class UpdateEmailConnected extends Component {
  onSubmit = (values, actions) => {
    actions.resetForm({
      email: ""
    });
    this.props.handleSubmit(this.props.form);
  };
  render() {
    return (
      <Formik
        initialValues={{
          email: ""
        }}
        onSubmit={(values, actions) => {
          this.onSubmit(values, actions);
        }}
        render={UpdateEmailForm}
      />
    );
  }
}

export default withRouter(connect(null, null)(UpdateEmailConnected));
