import history from "../../history";
import { ember } from "../middleware/apis/ember";
import { GET_CTAS } from "./types";

export const getCTAs = () => async (dispatch) => {
  try {
    const resCTA = await ember.get(`/ctas/`);

    dispatch({
      type: GET_CTAS,
      payload: resCTA.data,
    });
  } catch (error) {
    console.log(error);
    history.push("/error");
  }
};
