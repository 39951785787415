import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { pick, mapKeys, pickBy } from "lodash";
import ResponseSection from "../Sections/ResponseSection";

class ResponseSectionConnected extends Component {
  render() {
    const { responses } = this.props;
    return (
      <>
        {responses ? (
          <ResponseSection {...this.props} />
        ) : (
          <div>Loading...</div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, { prompt }) => {
  const { userId } = state.auth;
  const circle = Object.values(state.circles).filter((circle) =>
    circle.posts.includes(prompt.id)
  )[0];
  const responses = pick(state.responses, prompt.response);
  const emptyRes = mapKeys(
    pickBy(responses, (res) => res.is_submitted === false),
    "owner_id"
  );
  const answered = userId && !Object.keys(emptyRes).includes(userId.toString());

  return {
    responses: Object.values(responses).sort(
      (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
    ),
    emptyRes,
    circle,
    answered,
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    null
  )(ResponseSectionConnected)
);
