import React, { Component } from "react";
import CircleIconWithCommentInput from "../Forms/CircleIconWithCommentInput";
import CircleIconWithComment from "../Lists/CircleIconWithComment";
import Button from "../Shared/Button";
import FlexVC from "../Shared/FlexVC";

class CommentListingWithInput extends Component {
  state = {
    limit: 2,
    comments: this.props.comments,
    get remaining() {
      return this.comments.length - this.limit;
    },
    get amountLoaded() {
      return Math.min(this.limit, this.comments.length);
    },
  };
  componentDidUpdate(prevProps) {
    if (this.props.commentsIds !== prevProps.commentsIds) {
      this.setState(function(prevState, props) {
        return {
          comments: props.comments,
          remaining:
            prevState.comments.length !== props.comments.length
              ? prevState.remaining +
                (props.comments.length - prevState.comments.length)
              : prevState.remaining,
          amountLoaded: prevState.amountLoaded,
        };
      });
    }
  }
  loadMore = (amount) => {
    this.setState({
      amountLoaded: this.state.amountLoaded + amount,
      remaining: this.state.remaining - amount,
    });
  };
  renderLoadMoreLink = () => {
    const promptId = this.props.match.params.id;
    let linkAmount = Math.min(this.state.limit, this.state.remaining);
    return promptId ? (
      ""
    ) : linkAmount <= 0 ? (
      ""
    ) : (
      <Button btntype={"tertiary"} onClick={() => this.loadMore(linkAmount)}>
        View {linkAmount} more comments
      </Button>
    );
  };

  renderComments = (props) => {
    const promptId = this.props.match.params.id;
    let comments = promptId
      ? this.state.comments
      : this.state.comments.slice(0, this.state.amountLoaded);

    return comments.map((comment, idx) => (
      <CircleIconWithComment
        key={idx}
        poster_name={comment.poster_name}
        comment={comment.comment_text}
        commentDate={comment.created_at}
        {...props}
      />
    ));
  };
  render() {
    const { props } = this;
    return (
      <FlexVC
        direct={"column"}
        align={"flex-start"}
        padding={"25px 0px 0px 68px"}
        mpad={"25px 0px 0px 0px"}
        full
      >
        <FlexVC direct={"column"} align={"flex-start"} full>
          {this.renderComments(props)}
        </FlexVC>
        <FlexVC direct={"column"} full>
          {this.renderLoadMoreLink()}
        </FlexVC>
        <CircleIconWithCommentInput {...props} />
      </FlexVC>
    );
  }
}
export default CommentListingWithInput;
