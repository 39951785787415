import React from "react";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import { colors } from "../Shared/styleGuide";

const BorderBtmRow = styled(Row)`
  width: 100%;
  border-bottom: 2px solid ${({ bordercolor }) => bordercolor};
  margin: 0px 0px 10px 0px;
  padding-left: 0px;
  padding-bottom: 10px;
  justify-content: space-between;
  align-items: center;

  :last-child {
    border-bottom: 2px solid ${({ lastchild, bordercolor }) =>
      lastchild ? bordercolor : "transparent"};
`;
BorderBtmRow.defaultProps = {
  bordercolor: colors.maverick,
  lastchild: 1
};
export default BorderBtmRow;
