import React from "react";
import { colors } from "../../Shared/styleGuide";

const ArrowRight = () => (
  <svg
    width="22"
    height="39"
    viewBox="0 0 22 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.97084 36.2831L19.9414 19.3125L2.97084 2.34194"
      stroke={colors.salmon}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowRight;
