import React, { Component } from "react";
import Text from "./Text";
import { dfs, mfs, dln, mln } from "../Shared/styleGuide";

class ToggleableEllipsis extends Component {
  state = {
    expand: false,
    charsToDisplay: this.props.comment ? 100 : 200,
  };
  handleToggle = () => {
    this.setState({ expand: !this.state.expand });
  };
  renderText = () => {
    const { innerChars, comment } = this.props;
    const { charsToDisplay, expand } = this.state;
    let displayedText = !expand
      ? innerChars.length < charsToDisplay
        ? innerChars
        : innerChars.substring(0, charsToDisplay)
      : innerChars;
    return (
      <Text
        ln={!comment ? dln.h3 : dln.h3}
        mln={!comment ? mln.h3 : mln.h3}
        fontSize={!comment ? dfs.h3 : dfs.h4}
        mfontSize={!comment ? mfs.h3 : mfs.h3}
        textAlign={"left"}
        wba
      >
        {displayedText}
        {!this.state.expand &&
          displayedText.length >= this.state.charsToDisplay && (
            <Text
              ln={!comment ? dln.h3 : dln.h3}
              mln={!comment ? mln.h3 : mln.h3}
              fontSize={!comment ? dfs.h3 : dfs.h4}
              mfontSize={!comment ? mfs.h3 : mfs.h3}
              fontWeight={600}
              textAlign={"left"}
              onClick={this.handleToggle}
            >
              ...more
            </Text>
          )}
      </Text>
    );
  };

  render() {
    return <>{this.renderText()}</>;
  }
}
export default ToggleableEllipsis;
