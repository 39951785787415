import React from "react";
import FlexVC from "../Shared/FlexVC";
import Header from "../Shared/Header";
import CircleNameDate from "../Sections/CircleNameDate";

const Prompt = (props) => (
  <FlexVC direct={"column"} align="flex-start" full>
    <CircleNameDate
      circle_id={props.circle.id}
      name={props.circle.circle_name}
      {...props}
    />
    <Header
      h3 
      handleHeaderClick={props.handlePromptClick} 
      light
    >
      {props.question}
    </Header>
  </FlexVC>
);

export default Prompt;
