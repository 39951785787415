import React from "react";
import Link from "../Shared/Link";
import Text from "../Shared/Text";
import FlexVC from "../Shared/FlexVC";

const TermsAndPrivacyBlurb = () => (
  <FlexVC full direct="row" margin="0px 0px 18px 0px" mmargin="10px 0px">
    <Text fontWeight={500} fontSize={"0.875"} ln={"1.1875"}>
      By creating an account, you agree to our{" "}
      <Link to="/terms-of-service" target="_blank">
        Terms of Service
      </Link>{" "}
      and{" "}
      <Link to="/privacy" target="_blank">
        Privacy Policy.
      </Link>
    </Text>
  </FlexVC>
);

export default TermsAndPrivacyBlurb;
