import React from "react";
import formatDate from "../../utilities/functions/formatDate";

// Components
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CirclePrevQField from "../containers/CirclePrevQFieldConnected";
import Header from "../Shared/Header";
import BlurredPrompts from "../Lists/BlurredPrompts";

// ON CIRCLES PAGE
const CirclePromptsList = ({ prompts, userId }) => (
  <Row>
    <Col>
      {prompts.length <= 1 && (
        <>
          <Header center h2>
            This is where you’ll see all the prompts your circle has completed.
          </Header>
          <Header center h4>
            The sooner you answer questions the sooner you’ll see them below!
          </Header>
        </>
      )}
      {prompts &&
        prompts.map((prompt, index) => (
          <CirclePrevQField
            key={index}
            label={formatDate(prompt.created_at)}
            responsesIDs={prompt.response}
            id={userId}
            promptId={prompt.id}
          >
            {prompt.question_text}
          </CirclePrevQField>
        ))}
      {prompts.length <= 1 && <BlurredPrompts />}
    </Col>
  </Row>
);

export default CirclePromptsList;
