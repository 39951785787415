import axios from "axios";
import { clearLocalStorage } from "../../../utilities/functions/localStorage";
import { store } from "../../store";
import { signOut } from "../../actions";

// Axios API config
export let ember = axios.create({
  baseURL: `${process.env.REACT_APP_FIRESIDE_API_EMBER}`,
  headers: {
    "Content-Type": "application/json",
  },
});
// Set Req Headers
localStorage.getItem("fs-access") &&
  (ember.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("fs-access")}`);

// Set up general cancel token
const CancelToken = axios.CancelToken;
let cancel;

ember.interceptors.request.use(
  (config) => {
    const access = localStorage.getItem("fs-access");
    const refresh = localStorage.getItem("fs-refresh");

    config.cancelToken = new CancelToken(function executor(c) {
      cancel = c;
    });
    if (access && refresh) {
      config.headers["Authorization"] = `Bearer ${access}`;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
//Res interceptor
ember.interceptors.response.use(
  function(response) {
    // If the request succeeds, we don't have to do anything and just return the response
    return response;
  },
  function(error) {
    const errorResponse = error.response;
    if (isTokenExpiredError(errorResponse)) {
      return refreshTokenAndReattemptRequest(error);
    }
    return Promise.reject(error);
  }
);
function isTokenExpiredError(errorResponse) {
  return errorResponse && errorResponse.status === 401;
}
let isAlreadyFetchingAccessToken = false;

// List of waiting requests that will retry after updating access token
let pendingRequests = [];

async function refreshTokenAndReattemptRequest(error) {
  try {
    const { response: errorResponse } = error;
    const refreshToken = localStorage.getItem("fs-refresh");
    if (!refreshToken) {
      cancel();
      // sign out user due to missing creds
      store.dispatch(signOut());
      return Promise.reject(error);
    }
    /* Continue with token refresh procedure
     * Create a new Promise to retry the request
     * Copy the request config from the failed request
     */
    const retryOriginalRequest = new Promise((resolve) => {
      addRequest((accessToken) => {
        errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
        resolve(ember(errorResponse.config));
      });
    });
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      const response = await ember.post("/api/token/refresh/", {
        refresh: refreshToken,
      });
      if (!response.data) {
        cancel();
        clearLocalStorage();
        ember.defaults.headers.common["Authorization"] = ``;
        store.dispatch(signOut());
      }
      const newAccessToken = response.data.access;

      // update access token
      localStorage.setItem("fs-access", newAccessToken);
      ember.defaults.headers.common["Authorization"] =
        "Bearer " + newAccessToken;

      isAlreadyFetchingAccessToken = false;
      onAccessTokenFetched(newAccessToken);
    }
    if (
      error.config.url ==
      `${process.env.REACT_APP_FIRESIDE_API_EMBER}/api/token/refresh/`
    ) {
      cancel();
      clearLocalStorage();
      ember.defaults.headers.common["Authorization"] = ``;
      store.dispatch(signOut());
    }

    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}

// After new access token, retry requests one by one and empty the queue
function onAccessTokenFetched(accessToken) {
  pendingRequests.forEach((callback) => callback(accessToken));
  pendingRequests = [];
}

function addRequest(callback) {
  pendingRequests.push(callback);
}
