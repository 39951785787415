import history from "../../history";
import { ember } from "../middleware/apis/ember";
import {
  CREATE_COMMENT,
  GET_RESPONSE,
  CREATE_RESPONSE,
  UPDATE_RESPONSE,
  UPDATE_PROMPT_RESPONSES,
  GET_CTAS,
} from "./types";

// Responses
export const createResponse = (
  { is_submitted, response_text, response_id },
  id
) => async (dispatch) => {
  try {
    const res = await ember.put(`/responses/${response_id}/`, {
      submitted_status: is_submitted,
      response_text,
    });

    await dispatch({
      type: CREATE_RESPONSE,
      payload: {
        id: response_id,
        is_submitted,
        response_text,
      },
    });
    if (is_submitted) {
      await dispatch({
        type: UPDATE_PROMPT_RESPONSES,
        payload: {
          promptId: id,
          id: response_id,
        },
      });
    }
    const resCTA = await ember.get(`/ctas/`);
    dispatch({
      type: GET_CTAS,
      payload: resCTA.data,
    });
    !is_submitted && history.push(`/prompts/${id}`);
  } catch (error) {
    // Message to send to error logging route
    console.log(error);

    history.push("/error");
  }
};

export const getResponse = (id) => async (dispatch) => {
  dispatch({
    type: GET_RESPONSE,
    payload: {
      id,
    },
  });
};

export const createComment = (values, responseId) => async (dispatch) => {
  try {
    const res = await ember.post("/comments/", {
      comment_text: values.comment_text,
      response_id: responseId,
    });

    await dispatch({
      type: CREATE_COMMENT,
      payload: res.data,
    });

    await dispatch({
      type: UPDATE_RESPONSE,
      payload: {
        id: responseId,
        commentId: res.data.id,
      },
    });
  } catch (error) {
    // Message to send to error logging route
    console.log(error);

    history.push("/error");
  }
};
