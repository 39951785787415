import React from "react";
import { Field, Form } from "formik";
import Button from "../../Shared/Button";
import FlexVC from "../../Shared/FlexVC";
import RenderFieldComponent from "../../Shared/RenderFieldComponent";
import { validateCircleName } from "../validate.js";

const CircleNameEditForm = (props) => (
  <Form>
    <Field
      component={RenderFieldComponent}
      label={"Circle Name"}
      name={"circle_name"}
      width={"100%"}
      validate={validateCircleName}
      lightLabel
      {...props}
    />
    <FlexVC justify={"center"} direct={"column"} margin={"1rem 0rem 0rem 0rem"}>
      <Button width={"100%"} type="submit">
        Save
      </Button>
      <FlexVC direct={"column"} margin={"1rem 0rem 0rem 0rem"} full>
        <Button
          width={"100%"}
          type="button"
          btntype={"maverick"}
          onClick={() => props.handleClose()}
        >
          Cancel
        </Button>
      </FlexVC>
    </FlexVC>
  </Form>
);

export default CircleNameEditForm;
