import React, { useEffect } from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "../history";

// Components
import PrivateRoute from "./containers/PrivateRoute";
import ScrollToTop from "./Scenes/ScrollToTop";
// Scenes
import LandingScene from "./Scenes/LandingScene";
import EmailInviteScene from "./Scenes/EmailInviteScene";
import PrivacyScene from "./Scenes/PrivacyScene";
import PasswordResetConfirmScene from "./Scenes/PasswordResetConfirmScene";
import TermsOfServiceScene from "./Scenes/TermsOfServiceScene";
import HomeSceneConnected from "./containers/HomeSceneConnected";
import AccountScene from "./containers/AccountSceneConnected";
import ResponseCreateSceneConnected from "./containers/ResponseCreateSceneConnected";
import CirclesSceneConnected from "./containers/CirclesSceneConnected";
import TimelineSceneConnected from "./containers/TimelineSceneConnected";
import PromptShowScene from "./containers/PromptShowSceneConnected";
import ErrorScene from "./Scenes/ErrorScene";
import InvitedToCircleScene from "./containers/InvitedToCircleSceneConnected";
import WelcomeScene from "./Scenes/WelcomeScene";
import CirclesManageScene from "./containers/CirclesManageSceneConnected";
import HIW from "./containers/HIWSceneConnected";
import GetStartedSceneConnected from "./containers/GetStartedSceneConnected";

// Google Analytics
import { PageView, initGA } from "../components/Tracking";
import PhotoUploadScene from "./containers/PhotoUploadSceneConnected";

const App = () => {
  useEffect(() => {
    initGA(`${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}`);
    PageView();
  }, []);
  return (
    <Router history={history}>
      <ScrollToTop>
        <Switch>
          <PrivateRoute path="/" exact component={LandingScene} />
          <Route
            path="/terms-of-service"
            exact
            component={TermsOfServiceScene}
          />
          <Route path="/photo-upload" exact component={PhotoUploadScene} />
          <Route path="/privacy" exact component={PrivacyScene} />

          <Route path="/email-invite" exact component={EmailInviteScene} />
          <Route
            path="/reset/confirm/:uid/:token"
            exact
            component={PasswordResetConfirmScene}
          />
          <PrivateRoute path="/how-it-works" exact component={HIW} />
          <PrivateRoute
            path="/get-started"
            exact
            component={GetStartedSceneConnected}
          />
          <PrivateRoute path="/home" exact component={HomeSceneConnected} />
          <PrivateRoute path="/users/:id" component={AccountScene} />
          <PrivateRoute
            path="/timeline"
            exact
            component={TimelineSceneConnected}
          />
          <PrivateRoute
            path="/circles/:id"
            exact
            component={CirclesSceneConnected}
          />
          <PrivateRoute
            path="/circles/:id/manage"
            exact
            component={CirclesManageScene}
          />

          <PrivateRoute
            path="/prompts/:id/response"
            component={ResponseCreateSceneConnected}
          />
          <PrivateRoute path="/prompts/:id" exact component={PromptShowScene} />
          <PrivateRoute path="/welcome" exact component={WelcomeScene} />
          <PrivateRoute
            path="/invites/:id"
            exact
            component={InvitedToCircleScene}
          />
          <Route component={ErrorScene} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default App;
