import React from "react";
import { Form } from "formik";
import FirstQOptions from "../../containers/FirstQOptionsConnected";
import MemberType from "./MemberType";
import InviteMembers from "./InviteMembers";
import NameAndImg from "./NameAndImg";
import RomanticIntensity from "./RomanticIntensity";
import BasicHero from "../../Heros/BasicHero";
import SceneTemplate from "../../Scenes/SceneTemplate";

class CreateCircleForm extends React.Component {
  state = {
    step: 0,
    memberType: false,
    headerText: {
      0: "Choose your circle type",
      1: "Choose your circle's first question",
      2: `Invite your ${this.memberType ? "partner" : "circle members"}`,
      3: "Name your circle",
      4: "How serious is this relationship?",
    },
  };

  handleStep = () => {
    this.setState({
      step: this.state.step + 1,
    });
    // takes you to top of page
    window.scrollTo(0, 0);
  };

  handleBack = () => {
    let step = this.state.step <= 0 ? 0 : this.state.step - 1;
    this.setState({
      step,
    });

    // takes you to top of page
    window.scrollTo(0, 0);
  };

  handleMemberType = (val) => {
    // If val is true, then it is a romantic circle
    this.setState({
      memberType: val,
    });
  };
  render() {
    const { memberType, step, headerText } = this.state;
    const { createdCircle } = this.props;
    return (
      <SceneTemplate
        mode={1}
        createdCircle={createdCircle}
        header={
          <BasicHero
            header={headerText[step]}
            mode={1}
            short={!createdCircle}
          />
        }
        whole
      >
        <Form>
          {step === 0 && (
            <MemberType
              handleStep={this.handleStep}
              handleBack={this.handleBack}
              handleMemberType={this.handleMemberType}
              {...this.props}
            />
          )}
          {step === 1 && (
            <FirstQOptions
              handleStep={this.handleStep}
              handleBack={this.handleBack}
              {...this.props}
            />
          )}
          {this.state.step === 2 && (
            <InviteMembers
              memberType={memberType}
              handleStep={this.handleStep}
              handleBack={this.handleBack}
              {...this.props}
            />
          )}
          {step === 3 && (
            <NameAndImg
              memberType={memberType}
              handleStep={this.handleStep}
              handleBack={this.handleBack}
              {...this.props}
            />
          )}
          {step === 4 && (
            <RomanticIntensity handleBack={this.handleBack} {...this.props} />
          )}
        </Form>
      </SceneTemplate>
    );
  }
}

export default CreateCircleForm;
