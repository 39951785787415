import React from "react";
import Arc from "./Arc";
import ArcContainer from "./ArcContainer";

const ArcBackground = (props) => (
  <ArcContainer
    mode={props.mode}
    height={props.h}
    mheight={props.mh}
    fwd={props.fwd}
    {...props}
  >
    <Arc />
    {props.children}
  </ArcContainer>
);

export default ArcBackground;
