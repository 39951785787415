import React, { Component } from "react";
import history from "../../history";
import NotificationBox from "../Notifications/NotificationBox";
import MemberResStatusList from "../containers/MemberResStatusListConnected";

class PromptResStatus extends Component {
  state = {
    display: false
  };

  render() {
    const { answered, responses, circlename, circle_id, promptId } = this.props;
    return (
      <>
        {answered ? (
          <>
            {this.state.display ? (
              <MemberResStatusList
                onClick={() => this.setState({ display: false })}
                responses={responses}
                circlename={circlename}
                circle_id={circle_id}
              />
            ) : (
              <NotificationBox
                notification={"You’ve answered this question."}
                followUp={
                  "Responses will be revealed once everyone has responded."
                }
                btnText={"Nudge Members"}
                btntype={"secondary"}
                handleClick={() => this.setState({ display: true })}
              />
            )}
          </>
        ) : (
          <NotificationBox
            name={circlename}
            followUp={"Responses will be revealed once everyone has responded."}
            btnText={"Respond Here"}
            handleClick={() => history.push(`/prompts/${promptId}/response`)}
          />
        )}
      </>
    );
  }
}
export default PromptResStatus;
