import React from "react";
import Button from "../Shared/Button";
import Circle from "../Shared/Circle";
import FlexVC from "../Shared/FlexVC";
import Header from "../Shared/Header";
import HideMobile from "../Shared/HideMobile";
import history from "../../history";
import EllipsisText from "../Shared/EllipsisText";

const InviteCard = ({ invite }) => (
  <FlexVC
    direct={"column"}
    justify={"center"}
    align={"center"}
    margin={"1.5rem 1.625rem 2.9375rem 1.625rem"}
    mmargin={"0.625rem 1.125rem"}
    wrap
  >
    <Circle
      outerdark={"#697789"}
      margin={"0rem"}
      filter={"opacity(50%) blur(4px)"}
      innerwhite
      size={7}
      msize={6.25}
      onClick={() => history.push(`/invites/${invite.id}`)}
    />

    <FlexVC
      height={"80px"}
      width={"152px"}
      mheight={"60px"}
      mwidth={"auto"}
      direct={"column"}
      justify={"center"}
      align={"center"}
    >
      <EllipsisText
        width={9.5}
        display={"inline-flex"}
        direct={"column"}
        margin={"0.8rem 0rem"}
      >
        {invite.circle_name}
      </EllipsisText>
    </FlexVC>
    <HideMobile>
      <Button
        width="140px"
        onClick={() => history.push(`/invites/${invite.id}`)}
      >
        View Invite
      </Button>
    </HideMobile>
  </FlexVC>
);

const PendingInvites = ({ invites }) => (
  <FlexVC direct={"column"} justify={"center"}>
    <Header h3 light>
      Pending Invites
    </Header>
    <FlexVC direct={"row"} justify={"center"} wrap>
      {invites.map((invite) => (
        <InviteCard key={invite.id} invite={invite} />
      ))}
    </FlexVC>
  </FlexVC>
);

export default PendingInvites;
