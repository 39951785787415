import React from "react";
import styled from "styled-components";
import { colors, fontSizes } from "../Shared/styleGuide";

const MenuBtn = styled("div")`
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
  color: ${({ mode, mobile }) =>
    mobile
      ? mode
        ? colors.cloudBurst
        : colors.white
      : colors.cloudBurst} !important;
  padding: 0.25rem 1.5rem;
  border: 0px transparent solid;
  background: transparent;

  :hover {
    color: ${({ mode, mobile }) =>
      mobile
        ? mode
          ? colors.white
          : colors.cloudBurst
        : colors.white} !important;
    text-decoration: none;
    background: ${({ mobile }) =>
      mobile ? "transparent" : colors.cloudBurst} !important;
  }
  :focus {
    color: ${({ mode, mobile }) =>
      mobile
        ? mode
          ? colors.white
          : colors.cloudBurst
        : colors.white} !important;
    text-decoration: none;
    background: ${({ mobile }) =>
      mobile ? "transparent" : colors.cloudBurst} !important;
  }
`;

MenuBtn.defaultProps = {};

export default MenuBtn;
