const calcMid = (len) => Math.min(Math.floor(len / 2), 3);
const calcAbs = (idx, len) => {
  const mid = calcMid(len);
  return Math.abs(mid - idx);
};
export const calcTransform = (idx, len) => {
  let mid = Math.min(Math.floor(len / 2), 3);
  let transform = ``;
  let abs = Math.abs(mid - idx);
  const z = abs * 100;
  const x = abs * 100;
  const y = abs * 20;

  if (idx === mid) {
    transform =
      "translateX(300px) translateY(0px) translateX(0px) translateZ(0px)";
  } else if (idx <= mid) {
    transform = `
      translateX(300px) translateY(-${y}px) translateX(-${x}px) translateZ(-${z}px)
      `;
  } else {
    transform = `
    translateX(300px) translateY(-${y}px) translateX(${x}px) translateZ(-${z}px)`;
  }

  return transform;
};

export const calcZIndex = (idx, len) => {
  let z = 0;
  const mid = calcMid(len);
  z = idx === mid ? `100` : `${10 - calcAbs(idx, len)}`;
  return z;
};
export const calcMargin = (idx, len) => {
  let margin = `0`;
  const mid = calcMid(len);

  if (idx < mid) {
    margin = `margin-right: -3%;`;
  } else if (idx > mid) {
    margin = `margin-left: -3%;`;
  } else {
    margin = `margin: 0px;`;
  }
  return margin;
};

export const calcCircleSize = (len, order) => {
  const sizes = {
    "1": 11,
    "2": 8.25,
    "3": 6.75,
  };
  const mid = calcMid(len);
  const size = order === mid ? `14.5` : `${sizes[calcAbs(order, len)]}`;
  return size;
};
