import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Logo from "../Shared/icons/Logo";

const StyledLogo = styled("div")`
  margin-bottom: ${({ mb }) => (mb ? mb : "2rem")};
  #fireside-logo {
    height: ${({ size }) => size}px;
    width: ${({ size }) => size}px;
  }
  @media (max-width: 576px) {
    #fireside-logo {
      height: ${({ msize }) => msize}px;
      width: ${({ msize }) => msize}px;
    }
  }
`;

const FlexibleLogo = ({ size, mb, msize }) => (
  <StyledLogo msize={msize} size={size} mb={mb}>
    <Logo />
  </StyledLogo>
);
FlexibleLogo.propTypes = {
  size: PropTypes.number,
};
FlexibleLogo.defaultProps = {
  size: 24,
};

export default FlexibleLogo;
