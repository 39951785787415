import styled from "styled-components";
import { Form as FormikForm } from "formik";

const Form = styled(FormikForm)`
  display: flex;
  flex-direction: column;
  width: 343px;
  margin-top: 0.5rem;
  @media (max-width: 576px) {
    width: ${({ mobileMed }) => (mobileMed ? "250px" : "192px")};
    margin-top: 0rem;
  }
`;

export default Form;
