import React from "react";
import styled from "styled-components";
import Check from "../Shared/icons/Check";

const StyledMemberList = styled("ul")`
  display: flex;
  width: 142px;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0rem;

  li {
    list-style: none;
    font-size: 20px;
    #check {
      width: 30px;
      height: 30px;
    }
    @media (max-width: 576px) {
      font-size: 14px;
      #check {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
const InvitedMemberListItem = ({ member }) => (
  <StyledMemberList>
    <li>{member.first_name}</li>
    <li>
      <Check />
    </li>
  </StyledMemberList>
);

export default InvitedMemberListItem;
