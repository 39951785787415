import React from "react";
import FlexUl from "../Shared/FlexUl";
import Circle from "../Shared/Circle";
import Text from "../Shared/Text";
import SignOutButton from "../containers/SignOutButton";
import MobileMenuLink from "../Shared/MobileMenuLink";
import FlexVC from "../Shared/FlexVC";
import Close from "../Shared/icons/Close";
import MobileDropdownContainer from "../Shared/MobileDropdownContainer";
import Brand from "../Navbar/Brand";

const MobileDropdown = (props) => (
  <MobileDropdownContainer mode={props.mode}>
    <FlexVC
      direct="row"
      align="center"
      justify={"space-between"}
      margin={"0.1875rem 0rem 3rem 0.25rem"}
    >
      <FlexVC direct="row">
        <Brand createdCircle={props.createdCircle} mode={props.mode} />
      </FlexVC>
      <Close handleClick={props.displayMenu} mode={props.mode} />
    </FlexVC>

    <FlexVC direct="row" align="center">
      <Circle size={2.4} name={props.name} />
      <Text fontSize={"1.5"} mode={props.mode}>
        {props.name}
      </Text>
    </FlexVC>

    <FlexUl direct="column" lipad={"20px"}>
      <li>
        <MobileMenuLink mode={props.mode} to={`/home`}>
          My Circles
        </MobileMenuLink>
      </li>
      <li>
        <MobileMenuLink mode={props.mode} to={`/timeline`}>
          Timeline
        </MobileMenuLink>
      </li>
      <li>
        <MobileMenuLink mode={props.mode} to={`/users/${props.userId}`}>
          My Account
        </MobileMenuLink>
      </li>
      <li>
        <SignOutButton mode={props.mode} mobile={1} />
      </li>
    </FlexUl>
  </MobileDropdownContainer>
);
export default MobileDropdown;
