import React, { Component } from "react";
import { colors } from "../Shared/styleGuide";
import { calcCircleSize } from "./carouselFunctions";
import history from "../../history";
import ReactDOM from "react-dom";

import Circle from "../Shared/Circle";
import Header from "../Shared/Header";
import Button from "../Shared/Button";
import { Slide, SlideText, SlideContent } from "./Slide";
import ProtoTypes from "prop-types";

// Google Analytics
import { Event } from "../Tracking";

class CarouselSlide extends Component {
  state = {
    prevBox: 0,
  };
  componentWillReceiveProps() {
    // get initial position

    const domNode = ReactDOM.findDOMNode(this);
    const boundingBox = domNode.getBoundingClientRect();
    this.setState({ prevBox: boundingBox });
  }
  componentDidUpdate(previousProps) {
    const domNode = ReactDOM.findDOMNode(this);
    // get final position
    const newBox = domNode.getBoundingClientRect();
    const { prevBox } = this.state;

    //find difference
    const deltaX = prevBox.left - newBox.left;
    requestAnimationFrame(() => {
      //invert
      domNode.style.transform = `translate(${deltaX}px, 0px)`;
      domNode.style.transition = "transform 0s";

      //play
      requestAnimationFrame(() => {
        domNode.style.transform = "none";
        domNode.style.transition = "transform 100ms ease";
      });
    });
  }
  renderSlideContent = () => {
    let { slide, len, pos, order, sliding, noscale, idx, cta } = this.props;
    let mid = len < 7 ? Math.floor(len / 2) : 3;
    return (
      <>
        {order + 1 <= len && (
          <SlideContent>
            <Circle
              size={calcCircleSize(len, order)}
              name={slide.circle_name}
              border={1}
              bordercolor={colors.white}
              margin={0}
              noscale={len === 1}
              onClick={() => {
                history.push(`/circles/${slide.id}`);
                Event("Circle", "Viewed", slide.id)
              }}
            />
            <SlideText
              mid={mid}
              order={order}
              width={calcCircleSize(len, order) - 0.5}
            >
              <Header
                h3
                center
                ellipsis
                width={calcCircleSize(len, order)}
                noMargin
                inblock
                handleHeaderClick={() => history.push(`/circles/${slide.id}`)}
              >
                {slide.circle_name}
              </Header>
              {slide.circle_notice && (
                <Header
                  light
                  h4
                  width={calcCircleSize(len, order)}
                  center
                  noMargin
                >
                  {slide.circle_notice}
                </Header>
              )}
              <Button
                onClick={() =>
                  cta.category === "unsubmitted"
                    ? history.push(`prompts/${cta.prompt_id}/response`)
                    : history.push(`circles/${cta.circle_id}`)
                }
              >
                {cta ? cta.button_text : "NO CTA"}
              </Button>
            </SlideText>
          </SlideContent>
        )}
      </>
    );
  };
  render() {
    let { len, order, index } = this.props;
    return (
      <Slide order={order} len={len}>
        {this.renderSlideContent()}
      </Slide>
    );
  }
}

CarouselSlide.protoTypes = {
  slide: ProtoTypes.element,
  len: ProtoTypes.number,
  pos: ProtoTypes.node,
  order: ProtoTypes.number,
  noscale: ProtoTypes.number,
  idx: ProtoTypes.number,
  cta: ProtoTypes.node,
};

export default CarouselSlide;
