import React, { Component } from "react";
import { connect } from "react-redux";
import { updateCircle } from "../../store/actions";
import { withRouter } from "react-router-dom";
import CircleNameEditForm from "../Forms/CircleNameEditForm";
import { Formik } from "formik";
import ProtoTypes from "prop-types";

class CircleNameEditFormConnected extends Component {
  onSubmit = (values, actions) => {
    const { id } = this.props.circle;
    this.props.updateCircle(values, id);
    actions.resetForm({ circle_name: values.circle_name });

    // Close modal on submission
    this.props.handleClose();
  };

  render() {
    return (
      <Formik
        initialValues={{
          circle_name: this.props.circle.circle_name,
        }}
        onSubmit={(values, actions) => {
          this.onSubmit(values, actions);
        }}
      >
        {({
          values,
          validateField,
          validateForm,
          submitForm,
          setTouched,
          isValid,
          setFieldError,
          setFieldTouched,
          setFieldValue,
          errors,
        }) => (
          <CircleNameEditForm
            values={values}
            validateField={validateField}
            validateForm={validateForm}
            submitForm={submitForm}
            setTouched={setTouched}
            setFieldValue={setFieldValue}
            isValid={isValid}
            setFieldError={setFieldError}
            setFieldTouched={setFieldTouched}
            errors={errors}
            handleClose={this.props.handleClose}
          />
        )}
      </Formik>
    );
  }
}

CircleNameEditFormConnected.protoTypes = {
  circle: ProtoTypes.element,
  updateCircle: ProtoTypes.func,
  handleClose: ProtoTypes.func
}

export default withRouter(
  connect(
    null,
    { updateCircle }
  )(CircleNameEditFormConnected)
);
