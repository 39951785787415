import React from "react";
import styled from "styled-components";

const List = styled.ul`
  list-style-type: none;
  margin: 0px;
  padding: 0px;
`;
const ListItem = styled.li`
  display: inline-block;
  height: 8px;
  width: 8px;
  margin: 0px 4px;
  border: 2px solid #c9c3c7;
  border-radius: 50%;
  background: ${({ step, idx }) => (step == idx ? "#c9c3c7" : "transparent")};
`;
const HowItWorksProgress = ({ step }) => (
  <List>
    <ListItem key={"0"} idx={"0"} step={step}></ListItem>
    <ListItem key={"1"} idx={"1"} step={step}></ListItem>
    <ListItem key={"2"} idx={"2"} step={step}></ListItem>
  </List>
);
export default HowItWorksProgress;
