import { colors } from "../Shared/styleGuide";
import styled from "styled-components";

const HeroRow = styled("div")`
  width: 100%;
  height: 100%;
  padding-top: ${({ short }) => (short ? "0rem" : "1.5rem")};
  padding-bottom: ${({ short }) => (short ? "6rem" : "8rem")};
  position: relative;
  background: ${({ mode }) => (mode ? colors.selago : colors.slateGrey)};
  color: ${({ mode }) => (mode ? `${colors.cloudBurst}` : `${colors.white}`)};
  overflow: hidden;

  :after {
    content: "";
    position: absolute;
    left: -5%;
    right: -5%;
    bottom: -75px;
    border-radius: 50%;
    height: 150px;
    width: 110%;
    background: white;
  }

  @media (max-width: 576px) {
    padding-top: ${({ short }) => (short ? "0rem" : "2rem")};
    padding-bottom: 6rem;
  }
`;
export default HeroRow;
