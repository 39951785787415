import React from "react";
import PropTypes from "prop-types";
import Header from "../../Shared/Header";
import FlexVC from "../../Shared/FlexVC";
import HeroRow from "../../Heros/HeroRow";
import Carousel from "../../Carousel/Carousel";

const HomeHeroSection = ({ mode, name, header, tagline, circles, ctas }) => (
  <HeroRow mode={mode}>
    <FlexVC direct="column" justify="center">
      <Header h1 center>
        {header} {name}
      </Header>
      <Header light h2 center grey>
        {tagline}
      </Header>
    </FlexVC>
    <Carousel slides={circles} ctas={ctas} />
  </HeroRow>
);

HomeHeroSection.propTypes = {
  mode: PropTypes.number,
  header: PropTypes.string,
  name: PropTypes.string,
  tagline: PropTypes.string,
  circles: PropTypes.array,
};
HomeHeroSection.defaultProps = {
  mode: 1, // 0 for dark, 1 for light
};
export default HomeHeroSection;
