import React from "react";
import Button from "../../Shared/Button";
import RenderFieldComponent from "../../Shared/RenderFieldComponent";
import FlexEndCol from "../../Shared/FlexEndCol";
import Form from "../../Shared/Form";
import { Field } from "formik";
import { validateName } from "../validate.js";

const UpdateNameForm = props => (
  <Form>
    <Field
      component={RenderFieldComponent}
      name="first_name"
      inputtype={"text"}
      label={"First Name"}
      validate={validateName}
      {...props}
    />
    <Field
      component={RenderFieldComponent}
      name="last_name"
      inputtype={"text"}
      label={"Last Name"}
      validate={validateName}
      {...props}
    />
    <FlexEndCol>
      <Button btntype={"primary"}>Save</Button>
    </FlexEndCol>
  </Form>
);
export default UpdateNameForm;
