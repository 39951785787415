import { mapKeys } from "lodash";
import { GET_ONBOARDING_QS, DELETE_ONBOARDING_QS } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_ONBOARDING_QS:
      return { ...state, ...mapKeys(action.payload, "id") };
    case DELETE_ONBOARDING_QS:
      return {};
    default:
      return state;
  }
};
