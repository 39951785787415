import React, { Component } from "react";
import styled from "styled-components";
import { colors } from "../Shared/styleGuide";
// Components
import FlexVC from "../Shared/FlexVC";
import AbsoluteDiv from "../Shared/AbsoluteDiv";
import Response from "./Response";
import PromptResStatus from "../Notifications/PromptResStatus";
import CommentListingWithInputConnected from "../containers/CommentListingWithInputConnected";

const ResAndCommentHolder = styled(FlexVC)`
  flex-direction: column;
  width: 100%;
  align-items: "flex-start";
  border-bottom: 2px solid ${colors.lightGrey};
  padding-bottom: 14px;
  margin-bottom: 18px;
  :last-child {
    border-bottom: transparent;
  }
`;
const ResponsesList = styled(FlexVC)`
  position: relative;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 21px;
  border-left: 3px solid ${colors.greyChateau};
  z-index: 10;
  user-select: ${({ blur }) => (blur ? `none` : `text`)};
  filter: ${({ blur }) => (blur ? `blur(5px)` : `blur(0)`)};
  }
  margin: ${({ blur }) => (blur ? "14px 0px 89px 0px" : "23px 0px 89px 0px")};

  @media (max-width: 576px) {
    padding-left: 16px;
    margin: 11px 0px 44px 0px;

  }
`;
class ResponseSection extends Component {
  state = {
    blur:
      Object.values(this.props.emptyRes).length === 0 ||
      prompt.is_active === false
        ? false
        : true,
  };

  render() {
    const { responses, circle, emptyRes, match, answered, prompt } = this.props;
    return (
      <FlexVC full direct="column">
        {Object.values(emptyRes).length !== 0 && prompt.is_active === true && (
          <PromptResStatus
            answered={answered}
            circlename={circle.circle_name}
            circle_id={circle.id}
            responses={responses}
            promptId={prompt.id}
          />
        )}
        <ResponsesList blur={this.state.blur}>
          {this.state.blur && <AbsoluteDiv />}
          {responses.map(
            (response, idx) =>
              response.is_submitted === true && (
                <ResAndCommentHolder
                  direct={"column"}
                  full
                  align="flext-start"
                  key={idx}
                >
                  <Response
                    path={match.path}
                    name={response.owner}
                    date={response.created_at}
                  >
                    {response.response_text}
                  </Response>
                  <CommentListingWithInputConnected
                    commentsIds={response.comments}
                    responseId={response.id}
                    {...this.props}
                  />
                </ResAndCommentHolder>
              )
          )}
        </ResponsesList>
      </FlexVC>
    );
  }
}
export default ResponseSection;
