// Utilities
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_UP = "SIGN_UP";
export const RESET = "RESET";
export const PASSWORD_RESET = "PASSWORD_RESET";
export const PASSWORD_RESET_CONFIRM = "PASSWORD_RESET_CONFIRM";
export const PASSWORD_CHANGE = "PASSWORD_CHANGE";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const GET_WELCOME_MODAL_VIEWS = "GET_WELCOME_MODAL_VIEWS";
export const REQUESTING = "REQUESTING";
// Uploading Images
export const CREATE_IMAGE = "CREATE_IMAGE";
export const GET_IMAGE = "GET_IMAGE";

// Invites
export const GET_INVITES = "GET_INVITES";
export const UPDATE_INVITES = "UPDATE_INVITES";
export const CREATE_CURRENT_INVITE = "CREATE_CURRENT_INVITE";
export const GET_CURRENT_INVITE = "GET_CURRENT_INVITE";
export const DELETE_CURRENT_INVITE = "DELETE_CURRENT_INVITE";
export const INVALID_INVITE = "INVALID_INVITE";

// Users
export const GET_USER = "GET_USER";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";

// Circles
export const CREATE_CIRCLE = "CREATE_CIRCLE";
export const GET_CIRCLES = "GET_CIRCLES";
export const GET_CIRCLE = "GET_CIRCLE";
export const UPDATE_CIRCLE = "UPDATE_CIRCLE";

// Circle Invites
export const CREATE_CIRCLE_INVITE = "CREATE_CIRCLE_INVITE";
export const GET_CIRCLE_INVITES = "GET_CIRCLE_INVITES";
export const UPDATE_CIRCLE_INVITES = "UPDATE_CIRCLE_INVITES";
export const DELETE_CIRCLE_INVITE = "DELETE_CIRCLE_INVITE";

// Members
export const CREATE_CIRCLE_MEMBERS = "CREATE_CIRCLE_MEMBERS";
export const CREATE_CIRCLE_MEMBER = "CREATE_CIRCLE_MEMBER";
export const GET_CIRCLE_MEMBERS = "GET_CIRCLE_MEMBERS";
export const REMOVE_CIRCLE_MEMBER = "REMOVE_CIRCLE_MEMBER";

// Prompts
export const GET_PROMPTS = "GET_PROMPTS";
export const GET_PROMPT = "GET_PROMPT";
export const UPDATE_PROMPT = "UPDATE_PROMPT";
export const UPDATE_PROMPT_RESPONSES = "UPDATE_PROMPT_RESPONSES";

// Responses
export const CREATE_RESPONSE = "CREATE_RESPONSE";
export const GET_RESPONSES = "GET_RESPONSES";
export const GET_RESPONSE = "GET_RESPONSE";
export const UPDATE_RESPONSE = "UPDATE_RESPONSE";
export const UPDATE_RESPONSES = "UPDATE_RESPONSES";

// Comments
export const CREATE_COMMENT = "CREATE_COMMENT";
export const GET_COMMENT = "GET_COMMENT";
export const GET_COMMENTS = "GET_COMMENTS";
export const UPDATE_COMMENTS = "UPDATE_COMMENTS";

// Notifications
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const DELETE_NOTIFICATIONS = "DELETE_NOTIFICATIONS";

// CTAs
export const GET_CTAS = "GET_CTAS";

// Nudges
export const CREATE_NUDGE = "CREATE_NUDGE";

// Onboarding Qs
export const GET_ONBOARDING_QS = "GET_ONBOARDING_QS";
export const DELETE_ONBOARDING_QS = "DELETE_ONBOARDING_QS";
