import React from "react";
import styled from "styled-components";
import Text from "../Shared/Text";

const EllipsisText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  line-height: 1.5rem;
  ${({ width }) => `width: ${width}rem`};
`;

export default EllipsisText;
