import React, { Component } from "react";
import CreateCircleFormConnected from "../containers/CreateCircleFormConnected";
import LoadingScene from "../Scenes/LoadingScene";

class WelcomeScene extends Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    this.setState({ loading: false });
  }
  render() {
    let { createdCircle } = this.props;
    return this.state.loading ? (
      <LoadingScene />
    ) : (
      <CreateCircleFormConnected createdCircle={createdCircle} />
    );
  }
}
export default WelcomeScene;
