import React from "react";
import { Field } from "formik";
import Button from "../../Shared/Button";
import Form from "../../Shared/Form";
import RenderFieldComponent from "../../Shared/RenderFieldComponent";
import FlexVC from "../../Shared/FlexVC";
import { validatePassword } from "../validate.js";

const ResetPasswordConfirmForm = ({ errors }) => (
  <Form mobileMed>
    <FlexVC direct={"column"} full>
      <Field
        component={RenderFieldComponent}
        name="new_password1"
        inputtype={"password"}
        placeholder={"New Password"}
        mobileMed
        validate={validatePassword}
      />
      <Field
        component={RenderFieldComponent}
        name="new_password2"
        inputtype={"password"}
        placeholder={"Confirm Password"}
        mobileMed
        validate={validatePassword}
      />
      <Button width={"100%"} type="submit" btntype="primary" mobileMed>
        Set New Password
      </Button>
      {errors.general}
    </FlexVC>
  </Form>
);

export default ResetPasswordConfirmForm;
