import React, { Component } from "react";
import Button from "../../Shared/Button";
import FlexVC from "../../Shared/FlexVC";
import Option from "../../Shared/Option";
import history from "../../../history";

// Only one question can be selected
class FirstQOptions extends Component {
  state = {
    options: this.props.options || [],
    get isLoading() {
      return this.options.length < 1 ? true : false;
    },
  };

  handleSelect = (idx) => {
    delete this.props.errors.question_id;

    const updatedOptions = [...this.state.options];
    const optionsLen = updatedOptions.length;

    // make only one question selected
    for (let i = 0; i < optionsLen; i++) {
      if (i == idx) {
        updatedOptions[i].selected = true;
        updatedOptions[i].disabled = false;
      } else {
        updatedOptions[i].selected = false;
        updatedOptions[i].disabled = true;
      }
    }
    this.setState({
      options: [...updatedOptions],
    });
  };

  handleCancel = () => {
    history.push("/home");
  };

  handleContinue = () => {
    const { setFieldError, setFieldValue, handleStep } = this.props;

    const selected = this.state.options.filter(
      (option) => option.selected === true
    );

    if (selected.length === 0) {
      // where will this appear?
      setFieldError("question_id", "Select One");
    } else {
      // Add question to form values
      setFieldValue("question_id", selected[0].id);
      handleStep();
    }
  };
  render() {
    const { handleBack } = this.props;
    return (
      <>
        <FlexVC
          direct="column"
          justify="center"
          full
          margin={"0rem 0rem 2rem 0rem"}
        >
          {this.state.options.map((option, index) => (
            <Option
              key={index}
              margin="2rem 2.625rem"
              onClick={() => {
                this.handleSelect(index);
              }}
              children={option.question_text}
              bg={option.selected && !option.disabled}
              oLg
            />
          ))}
        </FlexVC>
        <FlexVC direct="row" justify="center" margin={"1rem 0rem"} full>
          <FlexVC direct="column" full>
            <Button
              type={"button"}
              width={"236px"}
              onClick={() => this.handleContinue()}
            >
              Invite Members
            </Button>
            <Button
              type={"button"}
              btntype="tertiary"
              fontSize="0.875"
              onClick={() => handleBack()}
            >
              Back
            </Button>
          </FlexVC>
        </FlexVC>
      </>
    );
  }
}

export default FirstQOptions;
