import React from "react";
import Modal from "./BasicModal";
import Close from "../Shared/icons/Close";
import Logo from "../Shared/FlexibleLogo";
import FlexVC from "../Shared/FlexVC";
const ModalHeader = ({ handleClick, children }) => (
  <Modal.Header>
    <FlexVC direct={"row"} align={"flex-start"} justify={"space-between"} full>
      <FlexVC direct={"row"} full />
      <FlexVC direct={"row"} justify={"center"} full>
        <Logo size={43} mb={"0rem"} msize={"34"} />
      </FlexVC>
      <FlexVC direct={"row"} justify={"flex-end"} full>
        <Close mode={1} handleClick={handleClick} />
      </FlexVC>
      {children}
    </FlexVC>
  </Modal.Header>
);

export default ModalHeader;
