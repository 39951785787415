import React, { Component } from "react";
import { connect } from "react-redux";
import MobileNav from "../Navbar/MobileNav";

class MobileNavConnected extends Component {
  render() {
    let {
      isSignedIn,
      userId,
      mode,
      name,
      displayMenu,
      createdCircle
    } = this.props;
    return (
      <MobileNav
        isSignedIn={isSignedIn}
        userId={userId}
        mode={mode}
        name={name}
        displayMenu={displayMenu}
        createdCircle={createdCircle}
      />
    );
  }
}

const mapStateToProps = ({ auth, circles }) => {
  let createdCircle = Object.values(circles).length > 0;

  return {
    isSignedIn: auth.isSignedIn,
    userId: auth.userId,
    name: `${auth.first_name} ${auth.last_name}`,
    createdCircle
  };
};
export default connect(mapStateToProps, null)(MobileNavConnected);
