import {
  GET_CIRCLE_INVITES,
  CREATE_CIRCLE_INVITE,
  DELETE_CIRCLE_INVITE,
} from "../actions/types";
import { mapKeys } from "lodash";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_CIRCLE_INVITES:
      return { ...state, ...mapKeys(action.payload, "id") };
    case CREATE_CIRCLE_INVITE:
      return { ...state, [action.payload.id]: action.payload };
    case DELETE_CIRCLE_INVITE:
      return {
        ...state,
        [action.payload.inviteId]: {
          ...state[action.payload.inviteId],
          accept_status: "Cancelled",
        },
      };
    default:
      return state;
  }
};
