import React from "react";
import Button from "../../Shared/Button";
import RenderFieldComponent from "../../Shared/RenderFieldComponent";
import FlexEndCol from "../../Shared/FlexEndCol";
import Form from "../../Shared/Form";
import { Field } from "formik";
import { validateEmail } from "../validate.js";

const UpdateEmailForm = props => (
  <Form>
    <Field
      component={RenderFieldComponent}
      name="email"
      inputtype={"email"}
      label={"Email"}
      {...props}
      validate={validateEmail}
    />
    <FlexEndCol>
      <Button btntype={"primary"}>Save</Button>
    </FlexEndCol>
  </Form>
);
export default UpdateEmailForm;
