import React from "react";
import styled from "styled-components";
import { colors } from "../Shared/styleGuide";

const NotificationItemContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${colors.lightGrey};
  padding: 0.875rem;
  word-break: break-all;
  overflow: hidden;
  max-width:  ${({ mobile }) => (mobile ? `100vw` : "519px")};
  min-width: ${({ mobile }) => (mobile ? `100vw` : "350px")}; 
  width:  ${({ mobile }) => (mobile ? `100vw` : "auto")};

:last-child {
  border-bottom-color: transparent;
`;
export default NotificationItemContainer;
