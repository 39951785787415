import React, { Component } from "react";
import CarouselLeftArrow from "./CarouselLeftArrow";
import CarouselRightArrow from "./CarouselRightArrow";
import CarouselContainer from "./CarouselContainer";
import CarouselSlides from "./CarouselSlides";
import CarouselSlide from "./CarouselSlide";
import Header from "../Shared/Header";
import PropTypes from "prop-types";
class Carousel extends Component {
  state = {
    slides: [],
    sliding: false,
    direction: "next",
    position: 0,
  };
  handleOrder(index) {
    const { position, slidesLen } = this.state;
    const numItems = slidesLen || 1;
    if (index - position < 0) {
      return numItems - Math.abs(index - position);
    }
    return index - position;
  }

  // Used for responsiveness. Top function needed for setting up initial view
  setNumOfSlides = () => {
    const { slidesLen } = this.state;
    if (window.outerWidth <= 600 || slidesLen < 3) {
      this.setState({
        numOfSlides: 1,
      });
    } else if (window.outerWidth <= 768 || slidesLen < 5) {
      this.setState({
        numOfSlides: 3,
      });
    } else if (window.outerWidth <= 980 || slidesLen < 7) {
      this.setState({
        numOfSlides: 5,
      });
    } else {
      this.setState({
        numOfSlides: 7,
      });
    }
  };

  prevSlide = () => {
    const { position, slidesLen } = this.state;
    const numItems = slidesLen;
    this.handleSliding("prev", position === 0 ? numItems - 1 : position - 1);
  };
  nextSlide = () => {
    const { position, slidesLen } = this.state;
    const numItems = slidesLen || 1;

    this.handleSliding("next", position === numItems - 1 ? 0 : position + 1);
  };

  handleSliding = (direction, position) => {
    this.setState({
      sliding: true,
      direction,
      position,
    });
    setTimeout(() => {
      this.setState({
        sliding: false,
      });
    }, 500);
  };
  static getDerivedStateFromProps(props, state) {
    if (props.slides !== state.slides) {
      return {
        slides: props.slides,
        slidesLen: props.slides.length,
        get numOfSlides() {
          const { slidesLen } = this;
          if (window.outerWidth <= 600 || slidesLen < 3) {
            return 1;
          } else if (window.outerWidth <= 768 || slidesLen < 5) {
            return 3;
          } else if (window.outerWidth <= 980 || slidesLen < 7) {
            return 5;
          } else {
            return 7;
          }
        },
      };
    }

    return null;
  }
  componentDidMount() {
    this.setNumOfSlides();
    window.addEventListener("resize", this.setNumOfSlides);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setNumOfSlides);
  }
  renderSlides() {
    return this.state.slides.map((slide, index) => (
      <CarouselSlide
        order={this.handleOrder(index)}
        index={index}
        key={index}
        len={this.state.numOfSlides}
        slide={slide}
        cta={this.props.ctas[slide.id]}
      />
    ));
  }
  render() {
    return (
      <>
        {this.state.slidesLen > 0 ? (
          <CarouselContainer>
            <CarouselSlides>
              <CarouselLeftArrow
                numSlides={this.state.numOfSlides}
                slidesLen={this.state.slidesLen}
                onClick={() => this.prevSlide()}
              />
              <CarouselRightArrow
                numSlides={this.state.numOfSlides}
                slidesLen={this.state.slidesLen}
                onClick={() => this.nextSlide()}
              />
              {this.renderSlides()}
            </CarouselSlides>
          </CarouselContainer>
        ) : (
          <Header h3 center>
            "Loading..."
          </Header>
        )}
      </>
    );
  }
}
Carousel.propTypes = {
  slides: PropTypes.array,
  ctas: PropTypes.object,
};
export default Carousel;
