import React from "react";
import Text from "../../Shared/Text";
import FlexVC from "../../Shared/FlexVC";
import Button from "../../Shared/Button";
import history from "../../../history";
import CustomToggle from "../../Shared/CustomToggle";
import DesktopDropdown from "../../Dropdowns/DesktopDropdown";
import CircleSpread from "./CircleSpread";

const CircleSpreadTextBtn = ({ circle, members, first_name }) => (
  <FlexVC direct={"row"} justify={"space-between"}>
    <FlexVC direct={"row"} mobileCol>
      <CircleSpread members={members} first_name={first_name} />
      <FlexVC
        direct={"col"}
        margin={
          circle.members.length > 1
            ? "0rem 0rem 0rem 2.5rem"
            : "0rem 0rem 0rem 0.3rem"
        }
      >
        <DesktopDropdown mobile={"block"} mode={1} centerarrow hidearrow>
          <DesktopDropdown.Toggle as={CustomToggle}>
            <Text>
              {circle.members && circle.members.length} member
              {circle.members.length > 1 && "s"}
            </Text>
          </DesktopDropdown.Toggle>
          <DesktopDropdown.Menu>
            {circle.members &&
              members.map((member) => (
                <Text
                  display={"block"}
                  margin={"0.5rem"}
                  textAlign={"left"}
                  key={member.id}
                >
                  {member.first_name} {member.last_name}
                </Text>
              ))}
          </DesktopDropdown.Menu>
        </DesktopDropdown>
      </FlexVC>
    </FlexVC>
    <Button
      type="button"
      btntype="tertiary"
      onClick={() => history.push(`/circles/${circle.id}/manage`)}
      fontSize={"0.825"}
    >
      Customize Your Circle
    </Button>
  </FlexVC>
);

export default CircleSpreadTextBtn;
