import React from "react";
import styled from "styled-components";
import { colors } from "../Shared/styleGuide";
import loadingScreen from "../Shared/images/loadingScreen.gif";

const LoadingContainer = styled("div")`
  display: flex;
  flex: column;
  align-items: center;
  justify-content: center;
  background: ${colors.white};
  overflow: hidden;
  height: ${window.innerHeight}px;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    display: inline-block;
    margin: 0 auto;
  }
`;

const LoadingScene = () => {
  return (
    <LoadingContainer>
      <img
        id="loading"
        src={loadingScreen}
        alt="Loading screen with Fireside logo pulsing"
      />
    </LoadingContainer>
  );
};

export default LoadingScene;
