import React from "react";
import Header from "../Shared/Header";
import FlexVC from "../Shared/FlexVC";
import Oval from "../Shared/Oval";

const MemberInvStatus = ({ member }) => (
  <FlexVC direct="row" justify={"space-between"} margin={"0.25rem"} full>
    <Header h5 light noMargin>
      {member.invited_first_name}
    </Header>
    <Oval
      width={"90px"}
      margin={" 0rem 0rem 0rem 1.375rem"}
      bg={member.accept_status == "Accept" ? true : false}
      smf
      short
      disabled
    >
      {member.accept_status == "Accept" ? "Accepted" : "Invited"}
    </Oval>
  </FlexVC>
);

export default MemberInvStatus;
