import React from "react";
import Header from "../Shared/Header";
import Circle from "../Shared/Circle";
import FlexVC from "../Shared/FlexVC";

const CircleModalType = ({ name, title }) => (
  <FlexVC justify={"center"} direct={"column"}>
    <Circle name={name} size={10} msize={6.1875} margin={"0rem"} />
    <FlexVC direct={"column"} margin={"1rem 0rem"}>
      <Header h3 center>
        {title}
      </Header>
    </FlexVC>
  </FlexVC>
);
export default CircleModalType;
