import React from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Header from "../../Shared/Header";
import TextWithCircle from "../../Shared/TextWithCircle";
import FlexVC from "../../Shared/FlexVC";
import HeroRow from "../../Heros/HeroRow";

const HeaderSection = (props) => (
  <HeroRow mode={props.mode} short={props.short}>
    <Container>
      <Row>
        <Col md={props.whole ? { span: 12 } : { span: 10, offset: 1 }}>
          <FlexVC direct={"column"} justify={"center"} mmargin={"0px"} full>
            {props.preheader && (
              <Header h3 light center mode={props.mode} margin={"1rem"}>
                {props.preheader}
              </Header>
            )}
            <Header h1 center mode={props.mode} margin={"0.5rem"}>
              {props.header}
            </Header>
            {props.name ? (
              <TextWithCircle
                name={props.name}
                circle_id={props.circle_id}
                {...props}
              />
            ) : (
              <Header h3 center mode={props.mode} light>
                {props.tagline}
              </Header>
            )}
          </FlexVC>
        </Col>
      </Row>
    </Container>
  </HeroRow>
);

HeaderSection.propTypes = {
  mode: PropTypes.number,
};
HeaderSection.defaultProps = {
  mode: 1, // 0 for dark, 1 for light
};
export default HeaderSection;
