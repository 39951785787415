import moment from "moment-timezone";
let jstz = require("jstimezonedetect");

const formatDateWithTime = date => {
  let converted = moment(date)
    .tz(jstz.determine().name())
    .format("MMMM Do, YYYY [at] h:mm a");
  return converted + "";
};
export default formatDateWithTime;
