import React, { Component } from "react";
import { connect } from "react-redux";
import { createComment } from "../../store/actions";
import { withRouter } from "react-router-dom";
import CreateCommentForm from "../Forms/CreateCommentForm/CreateCommentForm";
import { Formik } from "formik";

// Google Analytics
import { Event } from "../Tracking";

class CreateCommentConnected extends Component {
  onSubmit = (values, actions) => {
    this.props.createComment(values, this.props.responseId);
    actions.resetForm();
    Event("Comment", "Submitted", this.props.responseId);
  };
  s;
  render() {
    return (
      <Formik
        initialValues={{ comment_text: "" }}
        onSubmit={(values, actions) => {
          this.onSubmit(values, actions);
        }}
        render={CreateCommentForm}
      />
    );
  }
}

export default withRouter(
  connect(null, { createComment })(CreateCommentConnected)
);
