import React, { Component } from "react";
import { fontSizes } from "../Shared/styleGuide";

// Components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LabelTextBtnBorder from "../Shared/LabelTextBtnBorder";
import Header from "../Shared/Header";
import UpdatePasswordConnected from "../containers/UpdatePasswordConnected";
import UpdateNameConnected from "../containers/UpdateNameConnected";
import UpdateEmailConnected from "../containers/UpdateEmailConnected";

/// Need to add which form to show or not
class BasicInfoSection extends Component {
  state = {
    showNameForm: false,
    showEmailForm: false,
    showPasswordForm: false
  };

  handleClick = form => {
    switch (form) {
      case "name":
        this.setState({
          showNameForm: !this.state.showNameForm
        });
        break;
      case "email":
        this.setState({
          showEmailForm: !this.state.showEmailForm
        });
        break;
      case "password":
        this.setState({
          showPasswordForm: !this.state.showPasswordForm
        });
        break;
      default:
        break;
    }
  };
  handleSubmitClose = form => {
    switch (form) {
      case "name":
        this.setState({
          showNameForm: false
        });
        break;
      case "email":
        this.setState({
          showEmailForm: false
        });
        break;
      case "password":
        this.setState({
          showPasswordForm: false
        });
        break;
      default:
        break;
    }
  };
  render() {
    const { props } = this;
    return (
      <Row>
        <Col>
          <Header h5 uppercase margin={"0.5rem 0.5rem 0.5rem 0rem"}>
            Basic Information
          </Header>
          <LabelTextBtnBorder
            label={"Name"}
            labelSize={fontSizes.h4}
            action={"Edit"}
            btntype={"tertiary"}
            showForm={this.state.showNameForm}
            handleClick={this.handleClick}
            form={
              <UpdateNameConnected
                form={"name"}
                handleSubmit={this.handleSubmitClose}
              />
            }
          >
            {props.name}
          </LabelTextBtnBorder>
          <LabelTextBtnBorder
            label={"Email"}
            labelSize={fontSizes.h4}
            action={"Edit"}
            btntype={"tertiary"}
            showForm={this.state.showEmailForm}
            handleClick={this.handleClick}
            form={
              <UpdateEmailConnected
                form={"email"}
                handleSubmit={this.handleSubmitClose}
              />
            }
            disabled
          >
            {props.email}
          </LabelTextBtnBorder>

          <LabelTextBtnBorder
            label={"Password"}
            labelSize={fontSizes.h4}
            action={"Update"}
            btntype={"tertiary"}
            showForm={this.state.showPasswordForm}
            handleClick={this.handleClick}
            form={
              <UpdatePasswordConnected
                form={"password"}
                handleSubmit={this.handleSubmitClose}
              />
            }
          >
            You'll need your current password to make this change.
          </LabelTextBtnBorder>
        </Col>
      </Row>
    );
  }
}
export default BasicInfoSection;
