import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../Shared/styleGuide';

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  color: ${colors.cloudBurst} !important;
  padding: 0.25rem 1.5rem;
  :hover {
    color: ${colors.selago} !important;
    text-decoration: none;
    background:${colors.cloudBurst} !important;
  }
  :focus {
    color: ${colors.selago} !important;
    text-decoration: none;
    background:${colors.cloudBurst} !important;
  }
`;

const MenuLink = ( props ) => {
  return (
    <StyledLink {...props}>
      { props.children }
    </StyledLink>
  );
};

MenuLink.defaultProps = {
};

export default MenuLink;
