import React, { Component } from "react";
import HeaderSection from "../containers/HeaderSectionConnected";
import MemberInvStatusList from "../Lists/MemberInvStatusList";
import Header from "../Shared/Header";
import Button from "../Shared/Button";
import FlexVC from "../Shared/FlexVC";
import SceneTemplate from "./SceneTemplate";
import history from "../../history";
import LoadingScene from "../Scenes/LoadingScene";

class InvitedToCircleScene extends Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    this.setState({ loading: false });
  }
  render() {
    const {
      handleInvite,
      invitor,
      circle_name,
      invited_members,
      invited_id,
      newUser,
      createdCircle,
    } = this.props;
    if (this.state.loading) {
      return <LoadingScene />;
    }

    return (
      <SceneTemplate
        mode={1}
        whole
        createdCircle={createdCircle}
        header={
          <HeaderSection
            header="You've been invited to a Fireside Circle!"
            mode={1}
            short={!createdCircle}
          />
        }
      >
        <FlexVC direct="column" padding="0.5rem" justify="center">
          <Header h3 center light>
            {invitor} wants you to be in their circle, {circle_name} with:
          </Header>
          <MemberInvStatusList members={invited_members} full />
          <FlexVC direct={"column"} margin={"0.5rem 0rem"}>
            <FlexVC direct={"column"} margin={"0.5rem 0rem"}>
              <Button
                onClick={() => handleInvite(invited_id, 1)}
                width="236px"
                btntype="primary"
              >
                Accept Invite
              </Button>
            </FlexVC>
            {newUser && (
              <FlexVC direct={"column"} margin={"0.4375rem 0rem"}>
                <Button
                  onClick={() => {
                    history.push(`/how-it-works`);
                  }}
                  width="236px"
                  btntype="secondary"
                >
                  How It Works
                </Button>
              </FlexVC>
            )}
            <FlexVC direct={"column"} margin={"0.4375rem 0rem"}>
              <Button
                width="236px"
                btntype="maverick"
                onClick={() => handleInvite(invited_id, 0)}
              >
                Deny Invite
              </Button>
            </FlexVC>

            <FlexVC direct={"column"} margin={"0.4375rem 0rem"}>
              <Button
                onClick={() => history.push(`/welcome`)}
                width="236px"
                btntype="tertiary"
                fontSize={"0.875"}
              >
                Skip & Create Circle
              </Button>
            </FlexVC>
          </FlexVC>
        </FlexVC>
      </SceneTemplate>
    );
  }
}

export default InvitedToCircleScene;
