import React from "react";
import { colors } from "../styleGuide";
import styled from "styled-components";

// @media (min-width: 576px)

const ChatThreadsContainer = styled("span")`
  @media (min-width: 576px) {
    display: none;
  }
`;
const ChatThreads = ({ mode, onClick }) => (
  <ChatThreadsContainer>
    <svg
      id="chat-threads"
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.45314 0C3.52012 0 1.9531 1.56702 1.9531 3.50004V7.05506L1.95018 7.06126C1.93818 7.08621 1.91958 7.11895 1.89227 7.15966C1.83695 7.24213 1.75782 7.33977 1.65659 7.45044C1.45356 7.67241 1.19479 7.91124 0.93891 8.14179L0.816816 8.25148L0.81679 8.2515L0.816781 8.25151L0.816765 8.25152C0.612572 8.43469 0.410754 8.61572 0.277531 8.75563C0.235513 8.79976 0.192582 8.84769 0.154915 8.89672C0.122405 8.93903 0.0698656 9.01235 0.0358539 9.10479C0.00646023 9.18468 -0.0620514 9.43716 0.133918 9.65765C0.295437 9.83937 0.510678 9.84221 0.565929 9.84221H13.4999C15.4329 9.84221 17 8.27519 17 6.34218V3.50004C17 1.56702 15.4329 0 13.4999 0H5.45314ZM15.2863 23.0003C17.7716 23.0003 19.7864 20.9855 19.7864 18.5002V14.2727C19.7995 14.2337 19.8267 14.1726 19.8786 14.0873C19.9457 13.977 20.0394 13.85 20.1558 13.7095C20.3894 13.4279 20.6858 13.1264 20.9756 12.8383L21.1151 12.7001C21.3467 12.471 21.5719 12.2481 21.7201 12.0765C21.7666 12.0227 21.8125 11.966 21.8519 11.9094C21.8872 11.8587 21.9371 11.7805 21.9684 11.6867C21.9944 11.6088 22.055 11.3711 21.8806 11.1547C21.7207 10.9563 21.4955 10.9475 21.4252 10.9475H6.71049C4.22518 10.9475 2.21044 12.9622 2.21044 15.4475V18.5002C2.21044 20.9855 4.22518 23.0003 6.71049 23.0003H15.2863Z"
        fill={mode ? colors.slateGrey : colors.selago}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.50001 5.50002C7.0523 5.50002 7.50002 5.0523 7.50002 4.50001C7.50002 3.94772 7.0523 3.5 6.50001 3.5C5.94772 3.5 5.5 3.94772 5.5 4.50001C5.5 5.0523 5.94772 5.50002 6.50001 5.50002ZM9.50011 5.50006C10.0524 5.50006 10.5001 5.05234 10.5001 4.50005C10.5001 3.94776 10.0524 3.50004 9.50011 3.50004C8.94781 3.50004 8.50009 3.94776 8.50009 4.50005C8.50009 5.05234 8.94781 5.50006 9.50011 5.50006ZM13.5001 4.50005C13.5001 5.05234 13.0524 5.50006 12.5001 5.50006C11.9478 5.50006 11.5001 5.05234 11.5001 4.50005C11.5001 3.94776 11.9478 3.50004 12.5001 3.50004C13.0524 3.50004 13.5001 3.94776 13.5001 4.50005ZM8.50009 17.5002C9.05239 17.5002 9.50011 17.0525 9.50011 16.5002C9.50011 15.9479 9.05239 15.5002 8.50009 15.5002C7.9478 15.5002 7.50008 15.9479 7.50008 16.5002C7.50008 17.0525 7.9478 17.5002 8.50009 17.5002ZM12.5001 16.5002C12.5001 17.0525 12.0524 17.5002 11.5001 17.5002C10.9478 17.5002 10.5001 17.0525 10.5001 16.5002C10.5001 15.9479 10.9478 15.5002 11.5001 15.5002C12.0524 15.5002 12.5001 15.9479 12.5001 16.5002ZM14.5002 17.5002C15.0525 17.5002 15.5002 17.0525 15.5002 16.5002C15.5002 15.9479 15.0525 15.5002 14.5002 15.5002C13.9479 15.5002 13.5001 15.9479 13.5001 16.5002C13.5001 17.0525 13.9479 17.5002 14.5002 17.5002Z"
        fill={mode ? colors.selago : colors.slateGrey}
      />
    </svg>
  </ChatThreadsContainer>
);

export default ChatThreads;
