import styled from "styled-components";

const CarouselContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
  position: relative;
  @media (max-width: 576px) {
    margin-top: 1.625rem;
  }
`;
export default CarouselContainer;
