import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "../Shared/Button";
import MemberAddModal from "../Modals/MemberAddModal";

class MemberAddBtnWModal extends Component {
  state = {
    show: false,
  };
  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  handleShow = () => {
    this.setState({
      show: true,
    });
  };
  render() {
    const { circle } = this.props;
    return (
      <>
        <Button btntype="tertiary-lite" onClick={this.handleShow}>
          Add a member
        </Button>
        <MemberAddModal
          show={this.state.show}
          handleClose={this.handleClose}
          circle={circle}
        />
      </>
    );
  }
}
MemberAddBtnWModal.propTypes = {};
export default MemberAddBtnWModal;
