import {
  CREATE_COMMENT,
  GET_COMMENTS,
  UPDATE_COMMENTS,
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_COMMENTS:
      return {
        ...state,
        ...action.payload,
      };

    case CREATE_COMMENT:
      return {
        ...state,
        [action.payload.id]: {
          ...action.payload,
        },
      };

    case UPDATE_COMMENTS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
