//(required, xx@xx.xx format, no spaces allowed)
export function validateEmail(value) {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(value)) {
    error = "Invalid email address";
  }
  return error;
}
//(required, limit 50 characters, no numbers, no symbols)
export function validateName(value) {
  let error;

  if (!value) {
    error = "Required";
  } else if (!/[A-Za-z]{1,50}/i.test(value)) {
    error = "No symbols or numbers allowed.";
  }
  return error;
}

//(required, at least 1 character not just a space, limit 50 characters, numbers and symbols acceptable)
export function validateCircleName(value) {
  let error;
  const copyOfValue = value;

  if (!value) {
    error = "Required";
  } else if (!copyOfValue.replace(/\s/g, "").length) {
    error = "Cannot only be a space";
  } else if (!(value.length < 50 && value.length >= 1)) {
    error = "At least 1 character and limit 50";
  }
  return error;
}
//my have at least 1 character not just a space, any letters numbers or symbols are allowed
export function validateTextArea(value) {
  let error;
  const copyOfValue = value;
  if (!copyOfValue.replace(/\s/g, "").length) {
    error = "Cannot only be a space";
  } else if (value.length < 1) {
    error = "Must have at least one character";
  }
  return error;
}

//must match invitation code
export function validateInviteCode(value) {
  let error;
  let code = "FireBeta";
  if (!value) {
    error = "Required";
  } else if (code !== value) {
    error = "Incorrect Invitation code";
  }
  return error;
}
// must be unique
export function validateUnique(arr, val) {
  let error;
  const arrLen = arr.length;
  for (let i = 0; i < arrLen; i++) {
    if (val == arr[i].email) {
      error = "Already listed";
    }
  }
  return error;
}

//(required, at least 8 characters, must have lower case, uppercase, number, and symbol)
export function validatePassword(value) {
  let error;
  if (!value) {
    error = "Required";
  } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/i.test(value)) {
    error =
      "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters.";
  }
  return error;
}
export const validateConfirmPassword = (pass, value) => {
  let error = "";
  if (pass && value) {
    if (pass !== value) {
      error = "Passwords do not match";
    }
  }
  return error;
};
