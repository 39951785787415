import React, { Component } from "react";
import SceneTemplate from "./SceneTemplate";
import HeaderSection from "../containers/HeaderSectionConnected";
import Button from "../Shared/Button";
import FlexVC from "../Shared/FlexVC";
import Header from "../Shared/Header";
import { colors, fontSizes } from "../Shared/styleGuide";
import styled from "styled-components";
import ToggleNudgeMembersConnected from "../containers/ToggleNudgeMembersConnected";
import LoadingScene from "../Scenes/LoadingScene";

// Google Analytics
import { Event } from "../Tracking";

const Box = styled("div")`
  display: flex;
  flex-direction: column;
  width: 769px;
  min-height: 237px;
  margin-top: 1.75rem;
  margin-bottom: 2.6875rem;
  background: ${colors.white};
  color: ${colors.slateGrey};
  overflow-wrap: break-word;
  font-family: Avenir Next;
  line-height: 1rem;
  font-size: ${fontSizes.normal}rem;
  padding: 1.4375rem;
  border: 1px solid #999da6;
  box-sizing: border-box;
  border-radius: 6px;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
class ResponseSubmittedScene extends Component {
  state = {
    displayNudgeMembers: false,
    loading: true,
  };
  componentDidMount() {
    this.setState({ loading: false });
  }
  handleDisplayingNudgeMembers = () => {
    this.setState({
      displayNudgeMembers: !this.state.displayNudgeMembers,
    });
  };
  render() {
    const { prompt, circle, response, responsesList } = this.props;
    if (this.state.loading) {
      return <LoadingScene />;
    }
    return (
      <SceneTemplate
        mode={0}
        header={
          <HeaderSection
            header={prompt.question_text}
            mode={0}
            name={circle.circle_name}
            circle_id={circle.id}
          />
        }
      >
        <FlexVC direct={"column"}>
          {!this.state.displayNudgeMembers && (
            <FlexVC direct={"row"} margin={"0rem 0rem 2.875rem 0rem"}>
              <Button
                btntype={"secondary"}
                onClick={() => this.handleDisplayingNudgeMembers()}
              >
                Nudge Members
              </Button>
            </FlexVC>
          )}
          {this.state.displayNudgeMembers && (
            <ToggleNudgeMembersConnected
              circlename={circle.circle_name}
              circle_id={circle.id}
              responsesList={responsesList}
            />
          )}
          <Header h3>Your Response</Header>
          <Box>{response.response_text}</Box>
        </FlexVC>
      </SceneTemplate>
    );
  }
}
export default ResponseSubmittedScene;
