import React from "react";

const Check = () => (
  <svg
    id="check"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="15" fill="#697789" />
    <path
      d="M5.25049 15.3748L11.5005 21.7498L24.0005 8.99982"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Check;
