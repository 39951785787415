import React from "react";
import Modal from "../BasicModal";
import ModalHeader from "../ModalHeader";
import CircleModalType from "../CircleModalType";
import MemberAddFormConnected from "../../containers/MemberAddFormConnected";

const MemberAddModal = ({ show, handleClose, circle }) => (
  <Modal
    show={show}
    aligny={"flex-start"}
    alignx={"flex-start"}
    onHide={handleClose}
  >
    <ModalHeader handleClick={handleClose} />
    <Modal.Body>
      <CircleModalType name={circle.circle_name} title={"Add A Member"} />
      <MemberAddFormConnected
        handleClose={handleClose}
        circleInfo={{ name: circle.circle_name, id: circle.id }}
      />
    </Modal.Body>
    <Modal.Footer />
  </Modal>
);
export default MemberAddModal;
