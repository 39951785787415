import React, { Component } from "react";
import styled from "styled-components";
import { colors } from "../Shared/styleGuide";

const StyledLink = styled("span")`
  color: ${({ mode }) => (mode ? colors.cloudBurst : colors.white)};
  cursor: pointer !important;

  :hover {
    color: ${({ mode }) => (mode ? colors.cloudBurst : colors.white)};
    text-decoration: none;
  }
`;
class CustomToggle extends Component {
  handleClick = () => {
    this.props.onClick();
  };

  render() {
    const { bsPrefix, ...rest } = this.props;
    return (
      <StyledLink onClick={this.handleClick} {...rest}>
        {this.props.children}
      </StyledLink>
    );
  }
}
CustomToggle.defaultProps = {
  mode: 1,
};
export default CustomToggle;
