import React from "react";
import history from "../../history";
import PropTypes from "prop-types";

import FlexUl from "../Shared/FlexUl";
import Bell from "../Shared/icons/Bell";
import Bars from "../Shared/icons/Bars";
import ChatThreads from "../Shared/icons/ChatThreads";
import NotificationMenuConnected from "../containers/NotificationMenuConnected";

const NavMobileIcons = ({ mode, displayMenu }) => (
  <FlexUl nps>
    <li>
      <ChatThreads mode={mode} onClick={() => history.push(`/timeline`)} />
    </li>
    <li>
      <NotificationMenuConnected mobile={1} mode={mode} />
    </li>
    <li>
      <Bars mode={mode} handleClick={displayMenu} />
    </li>
  </FlexUl>
);
NavMobileIcons.propTypes = {
  mode: PropTypes.number,
  displayMenu: PropTypes.func,
};

export default NavMobileIcons;
