import React from "react";
import InvitedMemberListItem from "../Lists/InvitedMemberListItem";
import Text from "../Shared/Text";
import FlexVC from "../Shared/FlexVC";

const InvitedMembersList = ({ members }) => (
  <FlexVC direct={"column"} margin={"0rem"} full>
    <Text
      textAlign="center"
      display="block"
      fontSize="1.25"
      fontWeight="600"
      margin="0.5rem 0rem 1.375rem 0rem"
      mfontSize="1.125"
    >
      Invited Members
    </Text>
    {members.map((member, index) => (
      <InvitedMemberListItem key={index} member={member} />
    ))}
  </FlexVC>
);

export default InvitedMembersList;
