import React, { Component } from "react";
import { connect } from "react-redux";
import { signOut } from "../../store/actions";
import MenuBtn from "../Shared/MenuBtn";

class SignOutButton extends Component {
  onSubmit = () => {
    this.props.signOut();
  };
  render() {
    return (
      <MenuBtn
        onClick={() => this.onSubmit()}
        mode={this.props.mode}
        mobile={this.props.mobile}
      >
        Sign Out
      </MenuBtn>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth
  };
};
export default connect(
  mapStateToProps,
  { signOut }
)(SignOutButton);
