import { ember } from "../middleware/apis/ember";
import { GET_USER } from "./types";

// Get info on user
export const getUser = (id) => async (dispatch, getState) => {
  const { userId } = getState().auth;
  const res = await ember.get(`/users/${userId}/`);

  dispatch({
    type: GET_USER,
    payload: res.data,
  });
};
