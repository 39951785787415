import React from 'react';
import styled from 'styled-components';
import { colors } from '../Shared/styleGuide';

const NavMiddle= styled('div')`
  display: inline-flex;
  justify-content: center;
  width: 33%;
  ul {
    display: inline-flex;
    list-style: none;
    margin: 0px;
    padding: 0px;
    width: 100%;
    text-align: center;
    li {
      padding: 0px 5%;
      width: 50%;
      border-right: 1px solid ${({ mode }) => mode ? colors.cloudBurst : colors.white };
      :last-child {
        border-right: transparent;
      }
    }
  }
  @media (max-width: 576px) {
    display: none;
  }
`
export default NavMiddle;
