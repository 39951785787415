import styled from "styled-components";
import { colors } from "./styleGuide";
const CommentTextarea = styled("textarea")`
  padding: 6px 25px;
  white-space: wrap;
  height: auto;
  border-radius: 19px;
  resize: none;
  outline: none;
  overflow: visible;
  position: relative;
  width: 100%;
  border: 1px solid ${colors.greyChateau};
  border-radius: 19px;
`;
export default CommentTextarea;
