import React, { Component } from "react";
import ArrowUp from "../Shared/icons/ArrowUp";
import ArrowDown from "../Shared/icons/ArrowDown";
import Text from "../Shared/Text";

import ToggleArrowContainer from "../Shared/ToggleArrowContainer";

class TextWArrow extends Component {
  render() {
    const { bordercolor, children, onClick, show } = this.props;
    return (
      <div onClick={onClick}>
        <Text>{children}</Text>
        <ToggleArrowContainer>
          {show ? (
            <ArrowUp bordercolor={bordercolor} />
          ) : (
            <ArrowDown bordercolor={bordercolor} />
          )}
        </ToggleArrowContainer>
      </div>
    );
  }
}

export default TextWArrow;
