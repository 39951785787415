import React from "react";
import MemberInvStatus from "../Lists/MemberInvStatus";
import FlexVC from "../Shared/FlexVC";

const MemberInvStatusList = ({ members }) => (
  <FlexVC direct="column" align={"space-between"}>
    {members &&
      members
        .sort((a, b) => (b.accept_status < a.accept_status ? 1 : -1))
        .map((member) => <MemberInvStatus member={member} />)}
  </FlexVC>
);

export default MemberInvStatusList;
