import React, { Component } from "react";
import styled from "styled-components";
import history from "../../history";
import { colors } from "../Shared/styleGuide";

// Components
import TextWArrow from "../Shared/TextWArrow";
import CircleIconWithText from "../Shared/CircleIconWithText";
import HorizontalCenteredRow from "../Shared/HorizontalCenteredRow";

const CircleListingContainer = styled("div")`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-height: ${(props) => (props.open ? "100%" : "0")};
  overflow: hidden;
  transform: ${(props) => (props.open ? "translateY(0)" : "translateY(100%)")};
  transition-timing-function:${(props) => (props.open ? "ease-out" : "ease-in")}
  transition: ${(props) => (props.open ? "0.3s" : "0.2s")};
  opacity: 1;
`;
const AllCirclesToggleContainer = styled("div")`
  width: 100%;
  text-align: center;
`;
class CirclesListingWArrowBtn extends Component {
  state = {
    show: false,
  };
  onClick = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  render() {
    const { props, state } = this;

    // Show circles with unsubmitted responses first
    props.circles.sort(function(a, b) {
      if (props.ctas[a["id"]]["category"] < props.ctas[b["id"]]["category"]) {
        return 1;
      }
      if (props.ctas[a["id"]]["category"] > props.ctas[b["id"]]["category"]) {
        return -1;
      }
      return 0;
    });

    return (
      <HorizontalCenteredRow>
        <AllCirclesToggleContainer>
          <TextWArrow
            btntype={"tertiary"}
            noline
            onClick={this.onClick}
            show={this.state.show}
          >
            All Circles
          </TextWArrow>
        </AllCirclesToggleContainer>
        <CircleListingContainer open={state.show}>
          <CircleIconWithText
            name={"+"}
            plainBg={true}
            containerDisplay={"inline-flex"}
            onClick={() => {
              history.push(`/welcome`);
            }}
          />
          {props.circles.map((item) => (
            <CircleIconWithText
              key={item.id}
              name={item.circle_name}
              containerDisplay={"inline-block"}
              bordercolor={
                props.ctas[item.id] &&
                props.ctas[item.id].category === "unsubmitted"
                  ? colors.salmon
                  : colors.maverick
              }
              innerwhite
              outer
              onClick={() => {
                history.push(`/circles/${item.id}`);
              }}
            >
              {item.circle_name}
            </CircleIconWithText>
          ))}
        </CircleListingContainer>
      </HorizontalCenteredRow>
    );
  }
}
export default CirclesListingWArrowBtn;
