import React from "react";

const Emoticon = ({ handleClick }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={() => handleClick()}
  >
    <path
      d="M20.4896 3.51158C15.8081 -1.17034 8.19144 -1.17034 3.51088 3.51045C-1.17059 8.19124 -1.17014 15.8081 3.51133 20.4898C8.19144 25.1704 15.8081 25.1704 20.4891 20.4889C25.1701 15.8081 25.1699 8.1917 20.4896 3.51158ZM19.1796 19.1798C15.2209 23.1385 8.77934 23.139 4.82041 19.1803C0.860804 15.2209 0.861256 8.77869 4.82041 4.81999C8.77911 0.861511 15.2204 0.861059 19.18 4.82044C23.1387 8.77914 23.1383 15.2214 19.1796 19.1798ZM7.52185 8.75086C7.52185 7.97604 8.15026 7.34764 8.92507 7.34764C9.69966 7.34764 10.3281 7.97582 10.3281 8.75086C10.3281 9.52612 9.69966 10.1543 8.92507 10.1543C8.15026 10.1543 7.52185 9.52612 7.52185 8.75086ZM13.8844 8.75086C13.8844 7.97604 14.5133 7.34764 15.2881 7.34764C16.0627 7.34764 16.6911 7.97582 16.6911 8.75086C16.6911 9.52612 16.0629 10.1543 15.2881 10.1543C14.5133 10.1543 13.8844 9.52612 13.8844 8.75086ZM17.2018 14.4979C16.3322 16.509 14.2956 17.8083 12.0137 17.8083C9.68269 17.8083 7.63409 16.5024 6.79456 14.4812C6.65064 14.1352 6.8147 13.7378 7.16115 13.5939C7.24623 13.5588 7.33448 13.5421 7.42138 13.5421C7.6875 13.5421 7.94003 13.6993 8.04843 13.9607C8.67683 15.4737 10.2332 16.4508 12.0137 16.4508C13.7539 16.4508 15.3012 15.473 15.9556 13.9591C16.1045 13.6149 16.5042 13.4563 16.8481 13.6054C17.1921 13.7546 17.3507 14.154 17.2018 14.4979Z"
      fill="#999DA6"
    />
  </svg>
);

export default Emoticon;
