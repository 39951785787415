import React from "react";
import { colors } from "../Shared/styleGuide";
import formatDate from "../../utilities/functions/formatDate";
import Circle from "../Shared/Circle";
import Header from "../Shared/Header";
import FlexVC from "../Shared/FlexVC";

const CircleIconNameDate = (props) => (
  <FlexVC direct="row" align="flex-start" margin={"0px 0px 9px 0px"}>
    <Circle
      margin={"0px 20px 0px 0px"}
      size={3.3}
      plainBg={true}
      bordercolor={"transparent"}
      {...props}
    />
    <FlexVC direct={"column"} full align="flex-start" justify="flex-start">
      <Header h3>{props.name}</Header>
      <Header h5 light color={colors.greyChateau} margin={"0rem"}>
        {props.response
          ? formatDate(props.date)
          : props.dateText
          ? `Responded ${formatDate(props.date)}`
          : "No response yet"}
      </Header>
    </FlexVC>
  </FlexVC>
);

export default CircleIconNameDate;
