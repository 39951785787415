import React, { Component } from "react";
import styled from "styled-components";
import CarouselArrow from "./CarouselArrow";
import ArrowLeft from "../Shared/icons/ArrowLeft";
import ProtoTypes from "prop-types";

const CarouselArrowWrapper = styled(CarouselArrow)`
  left: -120px;
  }
`;
class CarouselLeftArrow extends Component {
  render() {
    return (
      <CarouselArrowWrapper
        numSlides={this.props.numSlides}
        slidesLen={this.props.slidesLen}
        onClick={this.props.onClick}
      >
        <ArrowLeft />
      </CarouselArrowWrapper>
    );
  }
}
CarouselLeftArrow.protoTypes = {
  numSlides: ProtoTypes.number,
  slidesLen: ProtoTypes.number,
  onClick: ProtoTypes.func
}
export default CarouselLeftArrow;
