import React, { Component } from "react";
import Button from "../../Shared/Button";
import Header from "../../Shared/Header";
import Circle from "../../Shared/Circle";
import FlexVC from "../../Shared/FlexVC";
import CircleNameEditModalConnected from "../../containers/CircleNameEditModalConnected";

// Should be moved to section and renamed
class CircleManageHero extends Component {
  state = {
    show: false,
  };
  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  handleShow = () => {
    this.setState({
      show: true,
    });
  };
  render() {
    const { circle } = this.props;
    return (
      <FlexVC direct={"column"} full justify={"center"}>
        <FlexVC direct={"column"}>
          <Circle
            msize={4.125}
            size={7.90625}
            border={1}
            bordercolor={"white"}
            name={circle.circle_name}
            margin={"0rem 1.625rem 1rem 1.625rem"}
          />
        </FlexVC>
        <FlexVC direct={"column"} full>
          <Header h2 noRes center>
            {circle.circle_name}
          </Header>
          <Button btntype="tertiary-lite" onClick={this.handleShow}>
            Change Name
          </Button>
          <CircleNameEditModalConnected
            show={this.state.show}
            handleClose={this.handleClose}
            circle={circle}
          />
        </FlexVC>
      </FlexVC>
    );
  }
}
CircleManageHero.propTypes = {};
export default CircleManageHero;
