import React from "react";
import styled from "styled-components";
import { colors } from "../Shared/styleGuide";
import { dfs } from "../Shared/styleGuide";

const StyledBubble = styled("div")`
  position: relative;
  font-weight: 400;
  color: ${colors.cloudBurst};
  font-size: ${dfs.normal}rem;
  background: ${colors.selago};
  padding: 10px;
  margin-left: 0.9375rem;
  white-space: wrap;
  border-radius: 20px;
  width: 96%;
  /* creates curve */
  :before {
    content: "";
    position: absolute;
    height: 30px;
    left: 0px;
    bottom: 0px;
    background: ${({ bgColor }) => bgColor};
    border-right: 60px solid ${({ bgColor }) => bgColor};
    z-index: -10;
    -webkit-border-bottom-right-radius: 50px 50px;
    -moz-border-radius-bottomright: 50px 50px;
    border-bottom-right-radius: 50px 70px;
    -webkit-transform: translate(-5px, -2px);
    -moz-transform: translate(-5px, -2px);
    -ms-transform: translate(-5px, -2px);
    -o-transform: translate(-5px, -2px);
    transform: translate(-5px, -2px);
  }

  /* creates point*/
  :after {
    content: "";
    position: absolute;
    width: 15px;
    height: 40px;
    bottom: 0px;
    left: 0px;
    background: ${colors.white};
    z-index: -1;
    -webkit-border-bottom-right-radius: 50px 60px;
    -moz-border-radius-bottom-right: 50px 60px;
    border-bottom-right-radius: 50px 60px;
    -webkit-transform: translate(-10px, -1px);
    -moz-transform: translate(-10px, -1px);
    -ms-transform: translate(-10px, -1px);
    -o-transform: translate(-10px, -1px);
    transform: translate(-10px, -1px);
  }
`;

const CommentBubble = (props) => {
  return <StyledBubble {...props}>{props.children}</StyledBubble>;
};

CommentBubble.defaultProps = {
  bgColor: `${colors.selago}`,
};

export default CommentBubble;
