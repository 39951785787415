import React from "react";
import { Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../Shared/styleGuide";

const Link = styled(RouterLink)`
  display: inline;
  color: ${({ mode }) => (mode ? colors.cloudBurst : colors.salmon)}!important;
  text-decoration: ${({ noline }) => (noline ? "none" : "underline")};

  :hover {
    color: ${({ mode }) =>
      mode ? colors.cloudBurst : colors.sundown}!important;
  }
  :focus {
    color: ${({ mode }) => (mode ? colors.cloudBurst : colors.sundown)};
  }
  :visited {
    color: ${({ mode }) => (mode ? colors.cloudBurst : colors.sundown)};
  }
`;

Link.defaultProps = {};

export default Link;
