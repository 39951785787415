import React from "react";
import { Link } from "react-router-dom";
import DesktopDropdown from "../Dropdowns/DesktopDropdown";
import SignOutButton from "../containers/SignOutButton";
import MenuLink from "../Shared/MenuLink";
import CustomToggle from "../Shared/CustomToggle";
import Brand from "./Brand";
import NavLeftSide from "./NavLeftSide";
import NavMiddle from "./NavMiddle";
import NavRightSide from "./NavRightSide";
import StyledNavbar from "./StyledNavbar";
import NavRightIcons from "./NavRightIcons";

const MainNavbar = (props) => (
  <>
    {props.isSignedIn && props.createdCircle && (
      <StyledNavbar {...props}>
        <NavLeftSide>
          <Brand createdCircle={props.createdCircle} mode={props.mode} />
        </NavLeftSide>
        <NavMiddle mode={props.mode}>
          <ul>
            <li key={0}>
              <Link to={`/home`}>My Circles</Link>
            </li>
            <li key={1}>
              <Link to={`/timeline`}>Timeline </Link>
            </li>
          </ul>
        </NavMiddle>
        <NavRightSide>
          <NavRightIcons name={props.name} mode={props.mode} />
          <DesktopDropdown mode={props.mode} nav>
            <DesktopDropdown.Toggle as={CustomToggle}>
              {props.name}
            </DesktopDropdown.Toggle>
            <DesktopDropdown.Menu>
              <MenuLink mode={props.mode} to={`/home`}>
                My Circles
              </MenuLink>
              <MenuLink mode={props.mode} to={`/timeline`}>
                Timeline
              </MenuLink>
              <MenuLink mode={props.mode} to={`/users/${props.userId}`}>
                My Account
              </MenuLink>
              <SignOutButton mode={props.mode} />
            </DesktopDropdown.Menu>
          </DesktopDropdown>
        </NavRightSide>
      </StyledNavbar>
    )}
  </>
);

MainNavbar.defaultProps = {
  mode: 1, // 0 for dark, 1 for light
};
export default MainNavbar;
