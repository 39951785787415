import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteNotifications } from "../../store/actions";
import AlertBell from "../Notifications/AlertBell";

// Getting notifications for navbar
class AlertBellConnected extends Component {
  render() {
    return <AlertBell {...this.props} />;
  }
}

export default connect(
  null,
  { deleteNotifications }
)(AlertBellConnected);
