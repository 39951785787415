import React from 'react';
import styled from 'styled-components';
import Col from 'react-bootstrap/Col';

const FlexEndCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
 padding: ${({ padding }) => padding ? padding : '10px'};

`
export default FlexEndCol;
