import React, { Component } from "react";
import styled from "styled-components";
import ArrowRight from "../Shared/icons/ArrowRight";
import CarouselArrow from "./CarouselArrow";
import Carousel from "./Carousel";
import ProtoTypes from "prop-types";

const CarouselArrowWrapper = styled(CarouselArrow)`
  right: -120px;
`;

class CarouselRightArrow extends Component {
  render() {
    return (
      <CarouselArrowWrapper
        numSlides={this.props.numSlides}
        slidesLen={this.props.slidesLen}
        onClick={this.props.onClick}
      >
        <ArrowRight />
      </CarouselArrowWrapper>
    );
  }
}
CarouselRightArrow.protoTypes = {
  numSlides: ProtoTypes.number,
  slidesLen: ProtoTypes.number,
  onClick: ProtoTypes.func
}
export default CarouselRightArrow;
