import React from "react";
import Circle from "../../Shared/Circle";
import FlexVC from "../../Shared/FlexVC";
import CreateCommentConnected from "../../containers/CreateCommentConnected";

// Google Analytics
import { Event } from "../../Tracking";

const CircleIconWithCommentInput = (props) => (
  <FlexVC direct="row" full align="flex-start" justify="flex-start">
    <FlexVC direct="column">
      <Circle
        margin={0}
        size={2.6125}
        plainBg={true}
        name={props.name}
        bordercolor={"transparent"}
        {...props}
        onClick={() => {
          Event("Circle", "Viewed", props.id);
        }}
      />
    </FlexVC>
    <FlexVC direct="column" full align="flex-start" margin={"0px 0px 0px 14px"}>
      <CreateCommentConnected {...props} />
    </FlexVC>
  </FlexVC>
);

export default CircleIconWithCommentInput;
