import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "../Shared/Button";
import LeaveCircleModal from "../Modals/LeaveCircleModal";

class LeaveCircleBtnWModal extends Component {
  state = {
    show: false,
  };
  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  handleShow = () => {
    this.setState({
      show: true,
    });
  };
  render() {
    const { circle, members } = this.props;
    return (
      <>
        <Button type="button" onClick={this.handleShow} width="100%">
          Leave Circle
        </Button>
        <LeaveCircleModal
          show={this.state.show}
          handleClose={this.handleClose}
          circle={circle}
        />
      </>
    );
  }
}
LeaveCircleBtnWModal.propTypes = {};
export default LeaveCircleBtnWModal;
