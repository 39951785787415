import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PromptWithResponses from "../Lists/PromptWithResponses";
import { pick, mapKeys, pickBy } from "lodash";

// Used on timeline
class PromptWithResponsesConnected extends Component {
  render() {
    const { responses } = this.props;
    return (
      <>
        {responses ? (
          <PromptWithResponses {...this.props} />
        ) : (
          <div>Loading...</div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ responses }, { prompt }) => {
  const promptResponses = pick(responses, prompt.response);
  const emptyRes = mapKeys(
    pickBy(promptResponses, (res) => res.is_submitted === false),
    "owner_id"
  );
  return {
    responses: Object.values(promptResponses).sort(
      (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
    ),
    emptyRes: Object.values(emptyRes),
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    null
  )(PromptWithResponsesConnected)
);
