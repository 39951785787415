import React from 'react';
import styled from 'styled-components';
import { colors } from "../Shared/styleGuide";

const NavLeftSide = styled('div')`
  display: inline-flex;
  width: 33%;
  a {
    color: ${({ mode }) => mode ?  colors.cloudBurst : colors.white };
  }
  a:hover {
    color: ${({ mode }) => mode ? colors.cloudBurst : colors.white };
    text-decoration: none;
  }

`
export default NavLeftSide;
