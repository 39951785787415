import React from "react";
import styled from "styled-components";
import { colors } from "../Shared/styleGuide";
import Dropdown from "react-bootstrap/Dropdown";
import ProtoTypes from "prop-types";

const DesktopDropdown = styled(Dropdown)`
  .dropdown-menu {
    border: 1px solid transparent;
    border-radius: 10px;
    opacity: 1 !important;
    box-shadow: 0 1px 4px 0 ${colors.transparentBlack};
    transform: ${({ nav, not, centerarrow }) =>
      nav
        ? "translate3d(-89px, 25px, 0px)"
        : centerarrow
        ? "translate3d(-25%, 35px, 0px)"
        : not
        ? "translate3d(-315px, 32px, 0px)"
        : "inherit"} !important;
    :before {
      content: "";
      position: absolute;
      top: 0;
      right: ${({ centerarrow }) => (centerarrow ? "50%" : "0")};

      border: 8px solid transparent;
      border-bottom-color: white;
      border-top: 0;
      margin-top: -8px;
      margin-right: ${({ centerarrow }) => (centerarrow ? "0" : "15px")};
    }
  }

  .dropdown-toggle {
    color: ${({ mode }) => {
      if (mode === 0) return colors.selago;
      if (mode === 1) return colors.cloudBurst;
      return colors.cloudBurst;
    }};
    :after {
      display: ${({ hidearrow }) => (hidearrow ? "none" : "inline-flex")};
      border: 8px solid transparent;
      border-top-color: ${({ mode }) =>
        mode ? colors.cloudBurst : colors.white};
      vertical-align: 0;
    }
  }
  @media (max-width: 576px) {
    display: ${({ mobile }) => (mobile ? mobile : "none")};
    .dropdown-menu {
      transform: ${({ centerarrow }) =>
        centerarrow ? "translate3d(-15%, 35px, 0px)" : "inherit"} !important;
    }
  }
`;
DesktopDropdown.protoTypes = {
  nav: ProtoTypes.bool,
  not: ProtoTypes.bool,
  centerarrow: ProtoTypes.bool,
};
export default DesktopDropdown;
