import React, { Component } from "react";
import SceneTemplate from "./SceneTemplate";
import HeaderSection from "../containers/HeaderSectionConnected";
import ResponseSectionConnected from "../containers/ResponseSectionConnected";
import formatDate from "../../utilities/functions/formatDate";
import LoadingScene from "../Scenes/LoadingScene";

class PromptShowScene extends Component {
  state = { loading: true };
  componentDidMount() {
    this.setState({ loading: false });
  }
  render() {
    const { prompt, circle } = this.props;
    if (this.state.loading) {
      return <LoadingScene />;
    }
    return (
      <SceneTemplate
        mode={0}
        header={
          <HeaderSection
            preheader={`Question from ${formatDate(prompt.created_at) ||
              formatDate("2019-06-07T04:21:54.788842Z")}`}
            header={prompt.question_text}
            mode={0}
            name={circle.circle_name}
            circle_id={circle.id}
          />
        }
      >
        <ResponseSectionConnected {...this.props} />
      </SceneTemplate>
    );
  }
}
export default PromptShowScene;
