import React, { Component } from "react";
import Header from "../Shared/Header";
import FlexVC from "../Shared/FlexVC";
import HideMobile from "../Shared/HideMobile";

import Button from "../Shared/Button";
import SignUpFormConnected from "../containers/SignUpFormConnected";
import SignInFormConnected from "../containers/SignInFormConnected";
import PasswordResetFormConnected from "../containers/PasswordResetFormConnected";

class LandingFormSection extends Component {
  renderForm() {
    let form;
    switch (this.props.formToDisplay) {
      case "Sign Up":
        form = <SignUpFormConnected />;
        break;
      case "Sign In":
        form = <SignInFormConnected selectForm={this.props.selectForm} />;
        break;
      case "Reset Password":
        form = <PasswordResetFormConnected />;
        break;
      default:
        form = <SignUpFormConnected />;
    }
    return form;
  }
  renderHeaderOne() {
    return this.props.formToDisplay === "Sign In" ? (
      <Header h2 light center noMargin>
        Welcome Back to Fireside!
      </Header>
    ) : this.props.formToDisplay === "Sign Up" ? (
      <Header h2 light center noMargin>
        Welcome to Fireside!
      </Header>
    ) : (
      <Header h2 light center noMargin>
        Please enter your email{" "}
      </Header>
    );
  }
  renderHeaderTwo() {
    return this.props.formToDisplay === "Sign Up" ? (
      <Header h3 light center>
        Let's set up your account
      </Header>
    ) : null;
  }
  render() {
    const { formToDisplay, selectForm } = this.props;
    return (
      <FlexVC direct="column">
        <HideMobile>{this.renderHeaderOne()}</HideMobile>
        {this.renderHeaderTwo()}
        {this.renderForm()}
        <Button
          type="button"
          btntype="tertiary"
          fontSize="0.875"
          onClick={() =>
            formToDisplay === "Sign In"
              ? selectForm("Sign Up")
              : selectForm("Sign In")
          }
        >
          {formToDisplay === "Sign In" ? "Sign Up" : "Sign In"}
        </Button>
      </FlexVC>
    );
  }
}

export default LandingFormSection;
