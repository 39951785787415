import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ErrorScene from "../Scenes/ErrorScene";
import * as Sentry from "@sentry/react";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <Sentry.ErrorBoundary fallback={ErrorScene}>
        {this.props.children}
      </Sentry.ErrorBoundary>
    );
  }
}

export default withRouter(ScrollToTop);
