import React, { Component } from "react";
import { connect } from "react-redux";
import BasicHero from "../Heros/BasicHero";

class HeaderSectionConnected extends Component {
  render() {
    let { createdCircle } = this.props;
    return <BasicHero createdCircle={createdCircle} {...this.props} />;
  }
}

const mapStateToProps = ({ circles }) => {
  let createdCircle = Object.values(circles).length > 0;

  return {
    createdCircle,
  };
};
export default connect(
  mapStateToProps,
  null
)(HeaderSectionConnected);
