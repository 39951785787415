import React, { Component } from "react";
import CircleViewHero from "../Heros/CircleViewHero";
import CirclePromptsListConnected from "../containers/CirclePromptsListConnected";
import SceneTemplate from "./SceneTemplate";
import LoadingScene from "../Scenes/LoadingScene";

class CirclesScene extends Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    this.setState({ loading: false });
  }
  render() {
    const { props } = this;
    if (this.state.loading) {
      return <LoadingScene />;
    }
    return (
      <SceneTemplate
        mode={1}
        header={<CircleViewHero mode={1} circle={props.circle} {...props} />}
      >
        <CirclePromptsListConnected />
      </SceneTemplate>
    );
  }
}
export default CirclesScene;
