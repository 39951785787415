import {
  GET_CIRCLES,
  GET_CIRCLE,
  CREATE_CIRCLE,
  UPDATE_CIRCLE,
  UPDATE_CIRCLE_INVITES,
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_CIRCLES:
      return { ...state, ...action.payload };
    case CREATE_CIRCLE:
      return { ...state, [action.payload.id]: action.payload }; // may need to update
    case GET_CIRCLE:
      return { ...state, ...action.payload };
    case UPDATE_CIRCLE:
      return { ...state, ...action.payload };
    case UPDATE_CIRCLE_INVITES:
      if (action.payload.verb === "REMOVE") {
        // Still considered an invite of circle but becomes "Cancelled"
        return {
          ...state,
        };
      }
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          invites: state[action.payload.id].invites.concat(
            action.payload.invitedId
          ),
        },
      };
    default:
      return state;
  }
};
