import React from "react";
import NavLeftSide from "./NavLeftSide";
import NavRightSide from "./NavRightSide";
import StyledNavbar from "./StyledNavbar";
import Brand from "./Brand";
import NavMobileIcons from "./NavMobileIcons";

const MobileNav = (props) => (
  <>
    {props.isSignedIn && props.createdCircle && (
      <StyledNavbar {...props}>
        <NavLeftSide>
          <Brand createdCircle={props.createdCircle} mode={props.mode} />
        </NavLeftSide>
        <NavRightSide>
          {props.createdCircle && (
            <NavMobileIcons mode={props.mode} displayMenu={props.displayMenu} />
          )}
        </NavRightSide>
      </StyledNavbar>
    )}
  </>
);

MobileNav.defaultProps = {
  mode: 1, // 0 for dark, 1 for light
};
export default MobileNav;
