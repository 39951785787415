import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ToggleNudgeMembers from "../Lists/ToggleNudgeMembers";
import { pick } from "lodash";

class ToggleNudgeMembersConnected extends Component {
  render() {
    const { responses, ...rest } = this.props;
    return (
      <>
        {responses ? (
          <ToggleNudgeMembers responses={responses} {...rest} />
        ) : (
          <div>Loading...</div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, { responsesList }) => {
  const responses = pick(state.responses, responsesList);
  return {
    responses: Object.values(responses)
  };
};
export default withRouter(
  connect(mapStateToProps, null)(ToggleNudgeMembersConnected)
);
