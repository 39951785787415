import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import {
  createCircles,
  getOnboardingQs,
  deleteOnboardingQs,
} from "../../store/actions";
import { withRouter } from "react-router-dom";
import CreateCircleForm from "../Forms/CreateCircleForm/CreateCircleForm";
import ProtoTypes from "prop-types";

class CreateCircleFormConnected extends Component {
  onSubmit = (values, actions) => {
    this.props.createCircles(values);
    actions.resetForm({
      circle_name: "",
      first_name: "",
      email: "",
      member_types: [],
      members: [],
      romanticScaleVal: 3,
      question_id: null,
    });
  };
  render() {
    const { getOnboardingQs, deleteOnboardingQs, userEmail } = this.props;
    let { createdCircle } = this.props;
    return (
      <Formik
        validateOnBlur
        validateOnChange={false}
        initialValues={{
          circle_name: "",
          member_types: [],
          members: [],
          first_name: "",
          email: "",
          romanticScaleVal: 3,
          question_id: null,
        }}
        onSubmit={(values, actions) => {
          this.onSubmit(values, actions);
        }}
      >
        {({
          values,
          validateField,
          validateForm,
          submitForm,
          setTouched,
          isValid,
          setFieldError,
          setFieldTouched,
          setFieldValue,
          errors,
        }) => (
          <CreateCircleForm
            values={values}
            validateField={validateField}
            validateForm={validateForm}
            submitForm={submitForm}
            setTouched={setTouched}
            setFieldValue={setFieldValue}
            isValid={isValid}
            setFieldError={setFieldError}
            setFieldTouched={setFieldTouched}
            errors={errors}
            createdCircle={createdCircle}
            getOnboardingQs={getOnboardingQs}
            deleteOnboardingQs={deleteOnboardingQs}
            userEmail={userEmail}
          />
        )}
      </Formik>
    );
  }
}
const mapStateToProps = ({ auth: { email } }) => {
  return {
    userEmail: email,
  };
};

CreateCircleFormConnected.protoTypes = {
  values: ProtoTypes.object,
  validateField: ProtoTypes.func,
  validateForm: ProtoTypes.func,
  submitForm: ProtoTypes.func,
  setTouched: ProtoTypes.func,
  setFieldValue: ProtoTypes.func,
  isValid: ProtoTypes.bool,
  setFieldError: ProtoTypes.func,
  setFieldTouched: ProtoTypes.func,
  errors: ProtoTypes.object,
  createdCircle: ProtoTypes.func,
  getOnboardingQs: ProtoTypes.func,
  deleteOnboardingQs: ProtoTypes.func,
  userEmail: ProtoTypes.string
}
export default withRouter(
  connect(
    mapStateToProps,
    { createCircles, getOnboardingQs, deleteOnboardingQs }
  )(CreateCircleFormConnected)
);
