/*
 ** Input: String
 ** Output: Hash code of string returned as String
 */
const strToHash = string => {
  let hash = 0;
  if (string.length > 0) {
    for (let i = 0; i < string.length; i++) {
      let char = string.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }
  }
  return hash + "";
};

export default strToHash;
