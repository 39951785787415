import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCircles } from "../../store/actions";
import TimelineScene from "../Scenes/TimelineScene";

class TimelineSceneConnected extends Component {
  async componentDidMount() {
    await this.props.getCircles();
  }
  render() {
    return <TimelineScene {...this.props} />;
  }
}

const mapStateToProps = ({ prompts, auth: { first_name, last_name } }) => {
  return {
    prompts: Object.values(prompts).sort(
      (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
    ),
    name: `${first_name} ${last_name}`,
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    { getCircles }
  )(TimelineSceneConnected)
);
