import { ember } from "../middleware/apis/ember";
import { GET_NOTIFICATIONS, DELETE_NOTIFICATIONS } from "./types";

export const getNotifications = () => async (dispatch) => {
  try {
    const res = await ember.get(`/notifications/`);

    dispatch({
      type: GET_NOTIFICATIONS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
  }
};
// Clears notifications from redux state
export const deleteNotifications = () => async (dispatch) => {
  dispatch({
    type: DELETE_NOTIFICATIONS,
  });
};
