import { GET_NOTIFICATIONS, DELETE_NOTIFICATIONS } from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_NOTIFICATIONS:
      return {};
    default:
      return state;
  }
};
