import React from "react";
import { Field, Form } from "formik";
import { fontSizes, colors } from "../../Shared/styleGuide";
import { validateTextArea } from "../validate.js";
import styled from "styled-components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "../../Shared/Button";
import FlexVC from "../../Shared/FlexVC";
import HideMobile from "../../Shared/HideMobile";
import ResizableTextarea from "../ResizableTextarea";
import Arc from "../../Shared/Arc/ArcBackground";

// Google Analytics
import { Event } from "../../Tracking";

const BgWArc = styled("div")`
  width: 100%;
  margin: -1px 0px;
  background: ${colors.slateGrey};
  height: 250px;
`;
const TextAreaHolder = styled("div")`
  margin-top: -250px;

  @media (max-width: 576px) {
    margin: 0px;
  }
`;
const ResponseForm = (props) => (
  <>
    <HideMobile>
      <BgWArc />
      <Arc mode={0} />
    </HideMobile>
    <Container>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <Form>
            <TextAreaHolder>
              <Field
                name="response_text"
                placeholder="Something"
                ariaLabel="Response textarea"
                component={ResizableTextarea}
                validate={validateTextArea}
                {...props}
              />

              <FlexVC direct={"row"} justify={"flex-end"}>
                <Button
                  btntype={"tertiary-lite"}
                  noline={1}
                  fontSize={fontSizes.h3}
                  type="button"
                  onClick={() => {
                    props.setFieldValue("is_submitted", false, false);
                    props.submitForm();
                    Event("Response", "Drafted", props.values.response_id);
                  }}
                  disabled={props.isSubmitting}
                >
                  Save draft
                </Button>
                <Button
                  btntype={"primary"}
                  type="button"
                  onClick={() => {
                    props.setFieldValue("is_submitted", true, false);
                    props.submitForm();
                  }}
                  width="163px"
                  disabled={props.isSubmitting}
                >
                  Respond
                </Button>
              </FlexVC>
            </TextAreaHolder>
          </Form>
        </Col>
      </Row>
    </Container>
  </>
);

export default ResponseForm;
