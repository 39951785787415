import React from "react";
import styled from "styled-components";
import { fontSizes, colors } from "../Shared/styleGuide";

const Option = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  margin: 1rem 1.375rem;
  width: ${({ oLg }) => (oLg ? "360px" : "236px")};
  min-height: ${({ oLg }) => (oLg ? "60px" : "40px")};
  overflow: hidden;
  font-family: Avenir Next;
  font-size: ${({ oLg }) => (oLg ? "1.125rem" : "1.25rem")};
  line-height: ${({ oLg }) => (oLg ? "1.25rem" : "1rem")};
  text-align: center;
  color: ${({ color }) => color};
  background: ${({ bg }) =>
    bg ? `${colors.slateGrey}` : `${colors.maverick}`};

  border: 1px solid
    ${({ bg }) => (bg ? `${colors.slateGrey}` : `${colors.maverick}`)};
  border-radius: ${({ borderRadius }) => borderRadius};

  :hover,
  :focus {
    background: ${colors.slateGrey};
    outline: none;
    box-shadow: 1px 4px 10px ${colors.transparentBlack};
  }

  @media (max-width: 576px) {
    margin: 0.5rem 0rem;
    padding: 0.375rem 0.75rem;

    width: ${({ oLg }) => (oLg ? "292px" : "192px")};
    font-size: ${fontSizes.h4}rem;
    min-height: ${({ oLg }) => (oLg ? "50px" : "40px")};

    :hover,
    :focus {
      background: ${({ bg }) =>
        bg ? `${colors.slateGrey}` : `${colors.maverick}`};
      outline: none;
      box-shadow: none;
    }
  }
`;
Option.defaultProps = {
  fontSize: fontSizes.normal,
  borderRadius: "5px",
  color: colors.white,
};

export default Option;
