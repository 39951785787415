import React, { Component } from "react";
import useQuery from "../../utilities/functions/useQuery";
import SceneTemplate from "./SceneTemplate";
import HeaderSection from "../containers/HeaderSectionConnected";
import FlexVC from "../Shared/FlexVC";
import Header from "../Shared/Header";
import EmailInviteFormConnected from "../containers/EmailInviteFormConnected";

const EmailInviteScene = ({ createdCircle = false }) => {
  let query = useQuery();
  const inviteeName = query.get("invitee_first_name");
  const email = query.get("email");
  const invitorName = query.get("invitor_first_name");
  return (
    <SceneTemplate
      mode={0}
      createdCircle={createdCircle}
      header={
        <HeaderSection header={"Sign Up"} mode={0} short={!createdCircle} />
      }
    >
      <FlexVC direct="column" justify="center">
        <Header h2 light center>
          Welcome to Fireside, {inviteeName}!
        </Header>
        <Header h3 light center>
          Let's set up your account
        </Header>
        <EmailInviteFormConnected email={email} inviteeName={inviteeName} />
      </FlexVC>
    </SceneTemplate>
  );
};

export default EmailInviteScene;
