import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CirclesScene from "../Scenes/CirclesScene";
import { getCircle } from "../../store/actions";
import ProtoTypes from "prop-types";

class CircleSceneConnected extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getCircle(id);
  }
  render() {
    const { circle } = this.props;
    return <>{circle && <CirclesScene {...this.props} />}</>;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps.match.params;
  return {
    circle: state.circles[id],
    first_name: state.auth.first_name,
  };
};

CircleSceneConnected.protoTypes = {
  id: ProtoTypes.string,
  match: ProtoTypes.object,
  circle: ProtoTypes.object
}

export default withRouter(
  connect(
    mapStateToProps,
    { getCircle }
  )(CircleSceneConnected)
);
