import {
  GET_INVITES,
  UPDATE_INVITES,
  CREATE_CURRENT_INVITE,
  DELETE_CURRENT_INVITE,
} from "../actions/types.js";
import { mapKeys } from "lodash";

const INTIAL_STATE = { currentId: null };

export default (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case GET_INVITES:
      return { currentId: state.currentId, ...mapKeys(action.payload, "id") };
    case UPDATE_INVITES:
      return {
        ...state,
        [action.payload.invitedId]: {
          ...state[action.payload.invitedId],
          accept_status: action.payload.accept_status,
        },
      };
    case CREATE_CURRENT_INVITE:
      return { ...state, ...action.payload };
    case DELETE_CURRENT_INVITE:
      return { ...state, currentId: null };
    default:
      return state;
  }
};
