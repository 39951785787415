import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HIWScene from "../Scenes/HIWScene";

class HIWSceneConnected extends Component {
  render() {
    return <HIWScene {...this.props} />;
  }
}

const mapStateToProps = ({ invites }) => {
  return {
    currentInvite: invites.currentId,
  };
};
export default withRouter(connect(mapStateToProps, null)(HIWSceneConnected));
