import React, { Component } from "react";
import styled from "styled-components";
import { fontSizes, colors } from "../Shared/styleGuide";
import history from "../../history";
import Col from "react-bootstrap/Col";
import Text from "../Shared/Text";
import Button from "../Shared/Button";
import BorderBtmRow from "../Shared/BorderBtmRow";

// Google Analytics
import { Event } from "../Tracking";

const RightDiv = styled(Col)`
  display: flex;
  justify-content: flex-end;
  padding: 0px;
  font-size: 1rem;
`;
const LeftDiv = styled(Col)`
  padding: 0px;
`;
const QuestionText = styled(Text)`
  padding: 0px;
  cursor: pointer;
  font-size: ${fontSizes.h2};
  @media (min-width: 576px) {
    font-size: ${fontSizes.h3};
  }
`;
// Circle Scene
class CirclePrevQField extends Component {
  state = {
    buttonText: "Respond",
  };

  handleButtonType = () => {
    let { buttonText } = this.state;

    let btnType;
    switch (buttonText) {
      case "Nudge":
        btnType = "secondary";
        break;
      case "Respond":
        btnType = "primary";
        break;
      default:
        btnType = "tertiary";
    }
    if (this.props.action) {
      switch (this.props.action) {
        case "Nudge":
          btnType = "secondary";
          break;
        case "Respond":
          btnType = "primary";
          break;
        default:
          btnType = "tertiary";
      }
    }
    return btnType;
  };

  handleButtonAction = () => {
    let buttonText = "";
    const { promptResponses, id } = this.props;

    if (promptResponses) {
      const pendingRes = promptResponses
        .map((res) => res.is_submitted == false)
        .includes(true);
      if (
        pendingRes &&
        promptResponses.filter((res) => res.owner_id === id)[0].is_submitted ===
          false
      ) {
        // if user has not responded
        buttonText = "Respond";
      } else if (pendingRes || promptResponses.length === 1) {
        // some members did not respond, check length for 1st prompt res
        buttonText = "Nudge";
      } else {
        // no pending responses
        buttonText = "Read Responses";
      }
    }
    this.setState({
      buttonText,
    });
  };

  handleOnClick = () => {
    const { buttonText } = this.state;
    const { promptId } = this.props;
    const route =
      buttonText === "Respond"
        ? `/prompts/${promptId}/response`
        : `/prompts/${promptId}`;
    history.push(`${route}`);
  };
  handlePromptClick = () => {
    history.push(`/prompts/${this.props.promptId}`);
    Event("Prompt", "Viewed", this.props.promptId)
  };
  componentDidMount() {
    this.handleButtonType();
    this.handleButtonAction();
  }
  render() {
    const { action, label, children } = this.props;
    const { buttonText } = this.state;

    return (
      <BorderBtmRow bordercolor={colors.selago}>
        <LeftDiv xs={{ span: 12 }} md={{ span: 8 }}>
          <Text
            margin={"0px 0px 10px 0px"}
            display={"block"}
            fontSize={fontSizes.caption}
            textAlign={"left"}
            color={colors.greyChateau}
          >
            {label}
          </Text>
          <QuestionText
            onClick={() => this.handlePromptClick()} // Need to change cursor to pointer
            display={"block"}
            textAlign={"left"}
          >
            {children}
          </QuestionText>
        </LeftDiv>
        <RightDiv xs={{ span: 12 }} md={{ span: 4 }}>
          <Button
            fontSize="1"
            btntype={this.handleButtonType()}
            noline
            onClick={() => {
              !action && this.handleOnClick();
              Event("Prompt", "Viewed", this.props.promptId)
            }}
          >
            {buttonText || action}
          </Button>
        </RightDiv>
      </BorderBtmRow>
    );
  }
}

export default CirclePrevQField;
