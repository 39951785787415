import React, { Component } from "react";
import { connect } from "react-redux";
import { createImage, getUser } from "../../store/actions";
import PhotoUploadScene from "../Scenes/PhotoUploadScene";

class PhotoUploadSceneConnected extends Component {
  componentDidMount() {
    this.props.getUser();
  }
  render() {
    return <PhotoUploadScene {...this.props} />;
  }
}

const mapStateToProps = ({ users }) => {
  return {
    photoURL: Object.values(users)[0] && Object.values(users)[0].photo_url,
  };
};
export default connect(
  mapStateToProps,
  { createImage, getUser }
)(PhotoUploadSceneConnected);
