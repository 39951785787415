import React, { Component } from "react";
import Prompt from "../Sections/Prompt";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class PromptConnected extends Component {
  render() {
    const { circle } = this.props;
    return <>{circle ? <Prompt {...this.props} /> : <div>Loading...</div>}</>;
  }
}

const mapStateToProps = ({ circles }, { promptId }) => {
  const circle = Object.values(circles).filter(circle =>
    circle.posts.includes(promptId)
  )[0] || { circle_name: "Fireside Circle" };

  return {
    circle
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(PromptConnected)
);
