import history from "../../history";
import { ember } from "../middleware/apis/ember";
import { normalize } from "normalizr";
import { Schemas } from "../middleware/schemas";
import { getCTAs } from "./ctaActions";
import {
  GET_CIRCLE,
  UPDATE_CIRCLE,
  GET_CIRCLES,
  GET_COMMENTS,
  GET_RESPONSES,
  CREATE_CIRCLE,
  GET_CIRCLE_MEMBERS,
  GET_INVITES,
  GET_PROMPTS,
  CREATE_NUDGE,
  UPDATE_CIRCLE_INVITES,
  GET_CIRCLE_INVITES,
  CREATE_CIRCLE_INVITE,
} from "./types";

// Circles
export const getCircles = () => async (dispatch) => {
  try {
    const res = await ember.get(`/circles/`);

    // Normalize response
    const normalizedData = normalize(res.data, Schemas.CIRCLES_ARRAY);
    dispatch({
      type: GET_CIRCLES,
      payload: normalizedData.entities.circles,
    });
    dispatch({
      type: GET_PROMPTS,
      payload: normalizedData.entities.posts,
    });
    dispatch({
      type: GET_RESPONSES,
      payload: normalizedData.entities.response,
    });
    dispatch({
      type: GET_COMMENTS,
      payload: normalizedData.entities.comments,
    });
    dispatch({
      type: GET_CIRCLE_MEMBERS,
      payload: normalizedData.entities.members,
    });

    dispatch({
      type: GET_CIRCLE_INVITES,
      payload: normalizedData.entities.Invites,
    });
  } catch (error) {
    console.log(error);

    history.push("/error");
  }
};

export const getCircle = (id) => async (dispatch) => {
  try {
    const res = await ember.get(`/circles/${id}/`);

    // Normalize response
    const normalizedData = normalize(res.data, Schemas.CIRCLE);
    dispatch({
      type: GET_CIRCLE,
      payload: normalizedData.entities.circles,
    });
    dispatch({
      type: GET_PROMPTS,
      payload: normalizedData.entities.posts,
    });
    dispatch({
      type: GET_RESPONSES,
      payload: normalizedData.entities.response,
    });
    dispatch({
      type: GET_COMMENTS,
      payload: normalizedData.entities.comments,
    });
    dispatch({
      type: GET_CIRCLE_MEMBERS,
      payload: normalizedData.entities.members,
    });
    dispatch({
      type: GET_CIRCLE_INVITES,
      payload: normalizedData.entities.Invites,
    });
  } catch (error) {
    console.log(error);
    history.push("/error");
  }
};

export const createCircles = (formValues) => async (dispatch, getState) => {
  const { userId } = getState().auth;
  const circle_category = formValues.member_types[0].category;

  const relationship_depth =
    circle_category == "partner" ? formValues.romanticScaleVal : null;
  try {
    const res = await ember.post("/circles/", {
      user_id: userId,
      meta_data: { circle_category, relationship_depth },
      circle_name: formValues.circle_name,
      question_id: formValues.question_id,
    });

    dispatch({
      type: CREATE_CIRCLE,
      payload: res.data,
    });

    formValues.members.map(
      async (member) =>
        await ember.post(`/invites/`, {
          email: member.email,
          first_name: member.first_name,
          circle_id: res.data.id,
        })
    );
    // call CTAs again
    await dispatch(getCTAs());
    history.push(`/home`);
  } catch (error) {
    console.log(error);

    history.push("/error");
  }
};
export const updateCircle = (formValues, circle_id) => async (dispatch) => {
  try {
    const res = await ember.put(`/circles/${circle_id}/`, { ...formValues });
    const normalizedData = normalize(res.data, Schemas.CIRCLE);
    dispatch({
      type: UPDATE_CIRCLE,
      payload: normalizedData.entities.circles,
    });
  } catch (error) {
    console.log(error);
    history.push("/error");
  }
};
export const updateCircleInvites = (circleId, invitedId, verb) => async (
  dispatch
) => {
  dispatch({
    type: UPDATE_CIRCLE_INVITES,
    payload: {
      id: circleId,
      invitedId,
      verb,
    },
  });
};
export const createNudge = (circle_id, member_id) => async (dispatch) => {
  try {
    const res = await ember.post(`/nudge/`, { circle_id, member_id });
    dispatch({
      type: CREATE_NUDGE,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    history.push("/error");
  }
};
