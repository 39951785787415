import React, { Component } from "react";
import { connect } from "react-redux";
import { signIn } from "../../store/actions";
import SignInForm from "../Forms/SignInForm";
import { Formik } from "formik";
import LoadingScene from "../Scenes/LoadingScene";

class SignInFormConnected extends Component {
  render() {
    return (
      <Formik
        initialValues={{
          password: "",
          email: "",
        }}
        onSubmit={(values, actions) => {
          this.props
            .signIn(values, actions)
            .then(() => {})
            .catch((error) => {
              console.log("error seen by form");
              const { data } = error.response && error.response;
              let errorMessage = data.non_field_errors ||
                data.email ||
                data.password || ["Sign In Credentials Invalid"];
              actions.setFieldError("general", errorMessage[0]);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {({ values, errors, isSubmitting }) =>
          !isSubmitting ? (
            <SignInForm
              values={values}
              errors={errors}
              selectForm={this.props.selectForm}
            />
          ) : (
            <LoadingScene />
          )
        }
      </Formik>
    );
  }
}

export default connect(null, { signIn })(SignInFormConnected);
