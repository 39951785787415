import React from "react";
import styled from "styled-components";
import { colors } from "../Shared/styleGuide";

const CarouselArrow = styled("div")`
  position: absolute;
  top: 0px;
  display: ${({ slidesLen }) => (slidesLen > 1 ? "flex" : "none")};
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: ${colors.salmon};
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.1s cubic-bezier(0.4, 0, 1, 1);
  font-size: 2.5rem;
  z-index: 999;
  :focus {
    outline: 0;
  }
  height: ${({ numSlides }) => {
    if (numSlides === 1) return "14rem";
    if (numSlides === 3) return "11rem";
    if (numSlides === 5) return "8.25rem";
    return "6.75rem";
  }};
`;
export default CarouselArrow;
