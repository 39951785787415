import React from "react";
import Modal from "../BasicModal";
import Header from "../../Shared/Header";
import Circle from "../../Shared/Circle";
import Button from "../../Shared/Button";
import FlexVC from "../../Shared/FlexVC";
import history from "../../../history";
import ModalHeader from "../ModalHeader";

const CircleBtnCard = ({ title, btntext, btnpath, name }) => (
  <FlexVC
    direct={"column"}
    justify={"center"}
    margin={"0rem 3.03125rem"}
    mmargin={"0rem 1.28125rem"}
    width={"129px"}
    mwidth={"94px"}
    wrap
  >
    <Header h4 light center nlh>
      {title}
    </Header>
    <Circle
      margin={"0rem 0rem 1.5625rem 0rem"}
      innerwhite
      bordercolor={"white"}
      border
      size={7.375}
      msize={4.95}
      name={name}
    />
    <Button
      width={"129px"}
      height={"60px"}
      onClick={() => history.push(`${btnpath}`)}
      wm
    >
      {btntext}
    </Button>
  </FlexVC>
);

const WelcomeModal = ({ show, handleClose, response }) => (
  <Modal show={show} onHide={handleClose} wide>
    <ModalHeader handleClick={handleClose} />

    <Modal.Body>
      <Header h1 center margin={"0.5rem 0rem"}>
        Welcome To Fireside!
      </Header>
      <FlexVC direct={"column"}>
        <Header h3 light center noMargin={false} nlh>
          You're well on your way!
        </Header>
        <Header h3 light center nlh>
          Now take the next step
        </Header>
      </FlexVC>
      <FlexVC
        direct={"row"}
        justify={"center"}
        align={"flex-start"}
        margin={"1.3125rem 0rem 4.125rem 0rem"}
        mmargin={"1rem 0rem 2.125rem 0rem"}
      >
        <CircleBtnCard
          btntext={"Answer First Prompt"}
          btnpath={`/prompts/${response.prompt}/response`}
          title={"Share with your circle"}
          name={"prompts"}
        />
        <CircleBtnCard
          btntext={"Create New Circle"}
          btnpath={`/welcome`}
          title={"Invite more people"}
          name={"Circle"}
        />
      </FlexVC>
    </Modal.Body>
  </Modal>
);
export default WelcomeModal;
