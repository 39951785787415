import React from "react";
import styled from "styled-components";
import { dfs, colors } from "../Shared/styleGuide";

const Text = styled("div")`
  display: ${({ display }) => display};
  ${({ direct }) => direct && `flex-direction:${direct};`}
  color: ${({ mode, color }) => {
    if (mode === 0) return colors.selago;
    if (mode === 1) return colors.cloudBurst;
    if (color) return color;

    return colors.cloudBurst;
  }};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize}rem;
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  line-height: ${({ ln, fontSize }) => (ln ? ln : fontSize * 1.05)}rem;
  margin: ${({ margin }) => margin};
  text-transform: ${({ textTransform }) => textTransform};
  word-wrap: break-word;
  word-break:${({ wba }) => (wba ? "break-all" : "break-word")};
  opacity: ${({ opacity }) => opacity};
  cursor: ${({ cursor }) => cursor};
  @media (max-width: 576px) {
    font-size: ${({ mfontSize }) => mfontSize}rem;
    line-height: ${({ mln }) => (mln ? mln : "inherit")};

  }
`;

Text.defaultProps = {
  display: "inline",
  fontSize: dfs.normal,
  fontWeight: 400,
  textAlign: "center",
  fontFamily: "Avenir Next, sans serif",
  margin: "0rem",
  textTransform: "none",
  opacity: 1.0,
  cursor: "auto",
  color: colors.cloudBurst,
};

export default Text;
