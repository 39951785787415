import React, { Component } from "react";
import history from "../../history";
import ResponseSectionConnected from "../containers/ResponseSectionConnected";
import PromptConnected from "../containers/PromptConnected";
import FlexVC from "../Shared/FlexVC";

class PromptWithResponses extends Component {
  handlePromptClick = (id) => {
    history.push(`/prompts/${id}`);
  };
  handleCircleNameClick = (circle_id) => {};
  render() {
    const { prompt, emptyRes, responses } = this.props;

    return (
      <>
        {emptyRes.length === 0 && responses.length > 1 && (
          <FlexVC direct={"column"} align="flex-start" full>
            <PromptConnected
              date={prompt.created_at}
              promptId={prompt.id}
              question={prompt.question_text}
              handlePromptClick={() => this.handlePromptClick(prompt.id)}
            />
            <ResponseSectionConnected {...this.props} />
          </FlexVC>
        )}
      </>
    );
  }
}
export default PromptWithResponses;
