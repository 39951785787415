import React, { Component } from "react";
import MemberResStatusList from "../containers/MemberResStatusListConnected";
import FlexVC from "../Shared/FlexVC";

class ToggleNudgeMembers extends Component {
  render() {
    const { circlename, circle_id, responses } = this.props;
    return (
      <FlexVC direct={"column"} full align={"flex-end"}>
        <MemberResStatusList
          circlename={circlename}
          circle_id={circle_id}
          responses={responses}
        />
      </FlexVC>
    );
  }
}
ToggleNudgeMembers.defaultProps = {};
export default ToggleNudgeMembers;
