import React from "react";
import SceneTemplate from "./SceneTemplate";
import HeaderSection from "../containers/HeaderSectionConnected";
import Header from "../Shared/Header";
import Text from "../Shared/Text";
import Link from "../Shared/Link";
import FlexVC from "../Shared/FlexVC";

const PrivacyScene = ({ createdCircle = false }) => (
  <SceneTemplate
    mode={0}
    createdCircle={createdCircle}
    header={
      <HeaderSection
        header="Privacy Policy"
        tagline={`Effective Date: July 2, 2018`}
        mode={0}
        short={!createdCircle}
      />
    }
  >
    <FlexVC direct="column" margin="1rem 0rem">
      <article>
        <section>
          Thank you for using this service (the “Service”), which is owned and
          operated by Fireside Technologies Inc. (“Fireside,” “we,” “us,” and
          “our”). This Online Privacy Policy (this “Policy”) describes the
          information we collect from or about you through the Service. It also
          describes how we use and share that information and some of the steps
          we take to protect your privacy.{" "}
        </section>
        <section>
          <Header noMargin h3>
            Scope of This Policy
          </Header>
          <p>
            Fireside collects certain information about or related to you
            through this Service. Some of the information Fireside collects may
            be “personal information”—information that identifies you
            personally, alone or in combination with other information available
            to us. Other information may be nonpersonally identifiable, such as
            your IP address.
          </p>
          ‍
          <p>
            Fireside has provided this Policy so that you will know what
            information Fireside collects, how Fireside uses this information,
            the types of third parties with which Fireside may share this
            information, and some of the choices that are available to you.
          </p>
          ‍
          <p>
            This Policy may be supplemented or amended from time to time by
            additional privacy notices (“Privacy Notices”), provided at the time
            we collect your information. For example, certain pages of this
            Service may contain Privacy Notices providing more details about the
            information we collect on those particular pages, why we need that
            information, and choices you may have about the ways we use that
            information. In other cases, specific Privacy Notices may be
            required to comply with the privacy laws of one of the countries,
            provinces, or states in which we do business.
          </p>
        </section>
        <section>
          <Header noMargin h3>
            Your Consent
          </Header>
          <p>
            By using this Service, you are consenting to the collection, use,
            disclosure, and transfer of your information as described in this
            Policy (and any Privacy Notices that apply to you). If you do not
            consent to the collection, use, disclosure and transfer of your
            information as described in this Policy (and any Privacy Notices
            that apply to you), you may not use this Service. If you have
            questions about this Policy, or any Privacy Notice, please contact
            us via e-mail at hello@fireside.social.
          </p>
        </section>
        <section>
          <Header noMargin h3>
            Our Terms of Use
          </Header>
          <p>
            This Policy is part of the
            <Link to="/terms-of-service">Terms of Use</Link> that govern your
            use of this Service. A link to our{" "}
            <Link to="/terms-of-service">Terms of Use</Link> is provided{" "}
            <Link to="/terms-of-service">here</Link>.
          </p>
        </section>
        <section>
          <Header noMargin h3>
            Your Choices
          </Header>
          ‍
          <p>
            <em>In General.</em> We respect your right to make choices about the
            ways we collect, use, and disclose your information. This Policy
            describes some of your choices, such as your choice to opt out of
            receiving “cookies”. In addition, and as required by applicable law,
            you can opt out of receiving promotional emails from us by clicking
            on the “unsubscribe” link at the bottom of each promotional email.
          </p>
          <p>
            We may ask you to indicate your choices at the time and on the page
            where you provide your information.{" "}
          </p>
          ‍<em>Do Not Track Mechanisms</em>
          Because of the changing state of technology and indecision within the
          industry regarding the meaning of DNT signals, we currently do not
          make any guarantee that we will honor DNT signals.
        </section>
        <section>
          <Header noMargin h3>
            This Policy May Change
          </Header>
          <p>
            Fireside reserves the right to update or modify this Policy and any
            Privacy Notice, at any time and without prior notice, by posting the
            revised version of the Policy or Privacy Notice on this Service.
            These changes will only apply to the information we collect after we
            have posted the revised Policy or Privacy Notice on this Service.
          </p>
          ‍
          <p>
            Your use of this Service following any such change constitutes your
            agreement that all information collected from or about you after the
            revised Policy is posted will be subject to the terms of the revised
            Policy. You may access the current version of this Policy{" "}
            <Link to="/privacy">here</Link>.
          </p>
        </section>
        <section>
          <Header noMargin h3>
            Information We Collect
          </Header>
          <p>
            <em>Information You Manually Provide.</em> Fireside collects the
            information you manually provide (using your phone, email, keyboard,
            mouse, or touchpad) when you use this Service. For example, we
            collect the information you provide when you contact us with
            questions, express interest in advertising on the Service, register
            with the Service and create a profile, or otherwise interact with
            this Service. Some of the information you manually provide may be
            personal information, such as your name and contact information.
          </p>
          <p>
            <em>Information From Third-Party Social Media Platforms.</em> You
            may be able to register with, log on to, or enhance your profile on
            this Service by choosing to automatically populate the requested
            data fields with information you previously provided to a
            third-party social media platform (such as Facebook or Twitter). By
            doing this, you are asking the third-party platform to send us
            information, including personal information, from your profile on
            that platform. We treat that information as we do any other
            information you give to us when you register, log on, or enhance
            your profile.
          </p>
          <p>
            <em>Information from your browser or device.</em> Fireside collects
            information that is sent to us automatically by your web browser or
            mobile device. This information typically includes your IP address,
            the name of your operating system, the name and version of your
            browser, the date and time of your visit, and the pages you visit.
            The information we receive may depend on your browser or device
            settings.
          </p>
          <p>
            The information we receive from your web browser and device is not,
            in and of itself, personally identifiable. Generally, we use this
            information in the aggregate to help us improve this Service and
            make it more compatible with the technology used by our visitors.
            However, we may combine it with other information in an attempt to
            identify you or we may combine it with information that does
            identify you. We may also review our server logs for security
            purposes—for example, to detect intrusions into our network—and we
            might share our server logs, which contain visitors’ IP addresses,
            with the appropriate investigative authorities who could use that
            information to trace and identify you.
          </p>
          ‍
          <p>
            <em>Your Location.</em> Using GPS technology, and with your opt-in
            consent, we may also collect precise, real-time location information
            from your mobile device and use that information to deliver tailored
            content through this Service. We may also share information about
            your location with your friends and other contacts, to the extent
            you interact with them using the social networking tools available
            on this Service. Your mobile device may allow you to adjust your
            settings so that location information is not available to any mobile
            web Service or application. If you have questions about the security
            and privacy settings of your mobile or tablet device, please refer
            to instructions from your mobile service provider or the
            manufacturer of your device.
          </p>
          ‍
          <p>
            <em>Information Collected by Cookies and Other Technologies.</em> We
            use “cookies” and other technologies to collect information and
            support certain features of this Service. For example, we may use
            these technologies to:
            <ul>
              <li>
                {" "}
                collect information about the ways visitors use this
                Service—which pages they visit, which links they use, and how
                long they stay on each page;
              </li>
              <li>
                support the features and functionality of this Service—for
                example, to save you the trouble of reentering information
                already in our database or to prompt the settings you
                established on previous visits;
              </li>
              <li>
                personalize your experience when you use this Service; and
              </li>
              <li>
                improve our marketing efforts, including through use of targeted
                advertising.
              </li>
            </ul>
          </p>
          ‍
          <p>
            The information we collect using cookies and similar technologies is
            not, in and of itself, personally identifiable, but we may link it
            to personal information that you provide. If you do not wish to
            receive cookies, you may set your browser to reject cookies or to
            alert you when a cookie is placed on your computer. Although you are
            not required to accept cookies when you visit this Service, you may
            be unable to use all of the functionality of this Service if your
            browser rejects our cookies.
          </p>
        </section>
        <section>
          {" "}
          <Header noMargin h3>
            Information About Third-Party Cookies
          </Header>
          <p>
            In addition to the cookies Fireside delivers to your computer or
            mobile device through this Service, certain third parties may
            deliver cookies to you for a variety of reasons. For example, we may
            use Google Analytics, including the following Google Analytics
            Advertising Features: Adsense, Adwords, Doubleclick, Remarketing. To
            learn more about Google Analytics, click{" "}
            <a
              href="https://analytics.google.com/analytics/web/"
              target="_blank"
            >
              here
            </a>
            .
          </p>
          <p>
            Other third parties may also deliver cookies to your computer or
            mobile device for the purpose of tracking your online behaviors
            across non-affiliated web Services and delivering targeted
            advertisements either on this Service or on other web Services.
          </p>
        </section>
        <section>
          <em>How We Use Your Information</em>
          <ul>
            <li>
              to provide the information, products and services you request;
            </li>
            <li>to provide you with effective customer service;</li>
            <li>
              to provide you with a personalized experience when you use this
              Service;
            </li>
            <li>
              to contact you with information and notices related to your use of
              this Service;
            </li>
            <li>
              to contact you with special offers and other information we
              believe will be of interest to you (in accordance with any privacy
              preferences you have expressed to us);
            </li>
            <li>
              to invite you to participate in surveys and provide Feedback to us
              (in accordance with any privacy preferences you have expressed to
              us);
            </li>
            <li>
              to improve the content, functionality and usability of this
              Service;
            </li>
            <li>to better understand your needs and interests;</li>
            <li>to improve our products and services;</li>
            <li>to improve our marketing and promotional efforts;</li>
            <li>for security, credit or fraud prevention purposes;</li>
            <li>
              for any other purpose identified in an applicable Privacy Notice,
              click-through agreement or other agreement between you and us.
            </li>
            <li>
              to contact you about our own and third parties' goods and services
              that may be of interest to you or that you may have requested
              information about;
            </li>
            <li>
              in any other way we may describe when you provide the information;
            </li>
            <li>to fulfill any other purpose for which you provide it; and</li>
            <li>for any other purpose with your consent.</li>
          </ul>
        </section>
        <section>
          <Header noMargin h3>
            How We Share Your Information
          </Header>
          ‍
          <p>
            <em>With Third–Party Vendors.</em> Fireside shares information
            collected through this Service with third-party vendors who act for
            us or on our behalf. For example, we may use third-party vendors to
            design and operate this Service; to conduct surveys; and to help us
            with our promotional efforts. These third-party vendors may need
            information about you to perform their functions. Additionally, we
            will share your information with third-party vendors when you
            request us to do so. For instance, you may request that we provide
            your information to hard money lenders so that you may receive
            information about offers and rates.
          </p>
          <p>
            <em>With Other Users of this Service.</em> User-Generated Content
            you post on this Service can be read, collected, and used by others.
            (In this Policy, “User-Generated Content” or “UGC” refers to your
            publicly available profile information and all content that you post
            using the social networking tools we make available to you. UGC does
            not include “Feedback” (as that term is defined in our Terms of Use
            .{" "}
          </p>
          <p>
            <em>With Third-Party Social Media Platforms. </em>We may provide
            functionality on this Service that allows you to automatically post
            information about the actions you take on this Service to a
            third-party social media platform (such as Facebook or Twitter). If
            you choose to take advantage of this functionality, people with
            access to your profile on the third-party platform may be able to
            see the actions you have taken—for example, the items you have
            purchased. Thus, you should have no expectation of privacy in those
            actions. Further, if you choose to link your profile on this Service
            with an account on a third-party social media platform, we may share
            the information in your profile with that third-party platform. We
            may share or provide some of your information, such as an email
            address, to third-party social media platforms so that we can
            identify you in order to engage with you on those platforms.
          </p>
          <p>
            <em>With Other, Carefully Selected Business Partners.</em> From time
            to time, we may share your information with selected third parties
            for their own marketing purposes. For example, we may partner with
            third parties to sponsor contests or other promotions, and we may
            share with these third parties the information you submit to us to
            participate in the contest or take advantage of the promotion.
            Before doing so, however, we may offer you the opportunity to “opt
            out” or “opt in,” as required by applicable law.
          </p>
          <p>
            <em>In Aggregate or De-identified Form.</em> We use information
            collected through this Service to create a compiled, aggregate view
            of usage patterns. As Part of a Business Transfer. Your information
            may be transferred to successor organization if, for example, we
            transfer the ownership or operation of this Service to another
            organization or if we merge with another organization. If such a
            transfer occurs, the successor organization’s use of your
            information will still be subject to this Policy and the privacy
            preferences you have expressed to us.
          </p>
          <p>
            <em>
              To Comply with Laws and Protect Our Rights and the Rights of
              Others.
            </em>{" "}
            We may disclose your information when we, in good faith, believe
            disclosure is appropriate to comply with the law, a court order or a
            subpoena. We may also disclose your information to prevent or
            investigate a possible crime, such as fraud or identity theft; to
            protect the security of this Service; to enforce or apply our online
            Terms of Use or other agreements; or to protect our own rights or
            property or the rights, property or safety of our users or others.
          </p>
          <p>
            <em>
              As Described in a Privacy Notice or click-through agreement.
            </em>{" "}
            We reserve the right to disclose your information as described in
            any Privacy Notice posted on a page of this Service where you
            provide that information. By providing your information on that page
            you will be consenting to the disclosure of your information as
            described in that privacy notice. We also reserve the right to
            disclose your information as described in any click–through
            agreement to which you have agreed.
          </p>
        </section>
        <section>
          <Header noMargin h3>
            How We Protect Your Information
          </Header>
          <p>
            {" "}
            Fireside takes reasonable precautions to provide a level of security
            appropriate to the sensitivity of the information we collect.
            Although we use reasonable measures to help protect your information
            against unauthorized use or disclosure, we cannot guarantee the
            security of information provided over the Internet or stored in our
            databases and will not be responsible for breaches of security
            beyond our reasonable control.
          </p>
        </section>
        <section>
          <Header noMargin h3>
            A Note About Children’s Privacy
          </Header>
          <p>
            <Text textTransform="uppercase">
              You must be at least 18 years old to have our permission to use
              this Service. We do not knowingly collect, use or disclose
              personal information about visitors under 18 years of age. If you
              are under the age of 18, you can use this service only in
              conjunction with your parent’s or guardian’s permission.
            </Text>
          </p>
        </section>
        <section>
          {" "}
          <Header noMargin h3>
            Links to Other Web Services
          </Header>
          <p>
            This Policy applies only to this Service. If you visit another
            Fireside web Service, please take a moment to review the privacy
            policy posted on that Service to learn what information may be
            collected through that Service and how it is processed.
          </p>
          <p>
            This Service may also contain links to web Services that are not
            operated by Fireside or its affiliates. These links are provided for
            your reference and convenience only and do not imply any endorsement
            of the products sold or information provided through these web
            Services, nor any association with their operators. Fireside does
            not control these web Services and is not responsible for their data
            practices. Any information you provide to third parties on their web
            Services is covered under their privacy and data collection policies
            and is not covered by this Policy. We urge you to review the privacy
            policy posted on any Service you visit before using the Service or
            providing any personal information.
          </p>
        </section>
        <section>
          <Header noMargin h3>
            Access to Your Information
          </Header>
          <p>
            If you would like to review, correct and update the personal
            information you have provided to us through this Service, you can
            contact us by email at hello@fireside.social. We will respond to
            your request within the time limit set out by the applicable privacy
            legislation. We will use reasonable efforts to comply with your
            request as required by applicable law.
          </p>
        </section>
        <section>
          <Header noMargin h3>
            Retention of Personal Information
          </Header>
          <p>
            Fireside retains the personal information collected on this Service
            as long as necessary to provide the services, products and
            information you request or as permitted by applicable law.
          </p>
        </section>
        <section>
          {" "}
          <Header noMargin h3>
            Governing Law
          </Header>
          <p>
            This Policy shall be governed under the laws of the State of
            Delaware, United States of America without regard to its conflicts
            of law provisions.
          </p>
        </section>
        <section>
          {" "}
          <Header noMargin h3>
            Questions About This Policy or Fireside’s Data Practices
          </Header>
          <p>
            If you have questions or concerns about this Policy, any Privacy
            Notice, or Fireside’s data practices, please contact us by email at
            hello@firesidel.social
          </p>
        </section>
      </article>
    </FlexVC>
  </SceneTemplate>
);
export default PrivacyScene;
