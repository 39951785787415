import React from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

const BasicModal = styled(Modal)`
  .modal-header {
    border: none;
    padding: 1rem 1rem 0rem 1rem;
  }
  .modal-dialog {
    max-width: ${({ wide, sm }) => (sm ? "272px" : wide ? "708px" : "390px")};
    margin: 56px auto;
    justify-content: center;
  }
  .modal-body {
    padding: ${({ npb }) => (npb ? "0rem" : "0.875rem 2rem 1rem 2rem")};
  }
  .modal-footer {
    justify-content: ${({ alignx }) => (alignx ? alignx : "center")};
    align-items: ${({ aligny }) => (aligny ? aligny : "center")};
    flex-direction: column;
    padding: 0.5rem 2rem 0.5rem 2rem;
    border: 0px solid transparent;
  }

  @media (max-width: 576px) {
    .modal-dialog {
      width: 293px;
    }
  }
`;
export default BasicModal;
