import React from "react";
import PropTypes from "prop-types";
import FlexUl from "../Shared/FlexUl";
import Circle from "../Shared/Circle";
import NotificationMenuConnected from "../containers/NotificationMenuConnected";

const NavRightIcons = ({ name, mode }) => (
  <FlexUl nps>
    <li>
      <NotificationMenuConnected mode={mode} />
    </li>
    <li>
      <Circle
        size={1.5}
        plainBg={true}
        margin={"0px 5px 0px 0px"}
        name={name}
      />
    </li>
  </FlexUl>
);
NavRightIcons.propTypes = {
  name: PropTypes.string,
  mode: PropTypes.number,
  notifications: PropTypes.array,
};
export default NavRightIcons;
