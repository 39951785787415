import React from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import DesktopDropdown from "../Dropdowns/DesktopDropdown";
import NotificationsMobileDropdown from "../Dropdowns/NotificationsMobileDropdown";
import CustomToggle from "../Shared/CustomToggle";
import NotificationItem from "./NotificationItem";
import AlertBell from "../containers/AlertBellConnected";

const NotificationMenu = ({ mode, notifications, mobile }) => (
  <>
    {mobile ? (
      <NotificationsMobileDropdown notifications={notifications} mode={mode} />
    ) : (
      <DesktopDropdown mode={mode} hidearrow mobile not>
        <Dropdown alignRight>
          <Dropdown.Toggle as={CustomToggle}>
            <AlertBell alert={notifications.length} mode={mode} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {notifications.length < 1 ? (
              <NotificationItem
                notice={{ name: "none", text: "No new notifications" }}
              />
            ) : (
              notifications.map((notice, idx) => (
                <NotificationItem
                  key={idx}
                  notice={notice}
                  name={notice.category}
                />
              ))
            )}
          </Dropdown.Menu>
        </Dropdown>
      </DesktopDropdown>
    )}
  </>
);
NotificationItem.propTypes = {
  name: PropTypes.string,
  mode: PropTypes.number,
  alert: PropTypes.number,
  notifications: PropTypes.array,
};
NotificationMenu.defaultProps = {
  mode: 1, // 0 for dark, 1 for light,
  notifications: [],
  alert: 0,
};
export default NotificationMenu;
