import React from "react";
import styled from "styled-components";
import { colors } from "../Shared/styleGuide";
import { fontSizes } from "../Shared/styleGuide";

const Footer = styled("div")`
  display: block;
  box-sizing: border-box;
  text-align: center;
  min-height: 16px;
  font-family: Avenir Next;
  color: ${({ color }) => color};
  background: ${colors.slateGrey};
  line-height: 1rem;
  font-size: ${fontSizes.normal}rem;
  padding: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;
`;

Footer.defaultProps = {
  color: `${colors.selago}`,
};

export default Footer;
