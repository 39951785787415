import axios from "axios";
import { ember } from "../middleware/apis/ember";
import { CREATE_IMAGE } from "./types";

// Upload image to user_photo
export const createImage = (imageFile) => async (dispatch, getState) => {
  const { userId } = getState().auth;

  try {
    // send req to API to get signed s3 url
    const res = await ember.post(`/user_photo/`);
    const signedRequest = res.data.presigned_put_url;

    delete axios.defaults.headers.common["Authorization"];

    // upload image to signed s3 url
    await axios.put(signedRequest, imageFile, {
      headers: {
        "Content-Type": "image/jpeg",
      },
    });

    // add back auth header to axios req
    localStorage.getItem("fs-access") &&
      (ember.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("fs-access")}`);

    dispatch({
      type: CREATE_IMAGE,
      payload: { id: userId, photo_url: res.data.presigned_put_url },
    });
  } catch (error) {
    console.log(error);
  }
};
