import React, { Component } from "react";
import { connect } from "react-redux";
import Navbar from "../Navbar/Navbar";

class NavbarConnected extends Component {
  render() {
    let { isSignedIn, userId, mode, name, createdCircle } = this.props;
    return (
      <Navbar
        isSignedIn={isSignedIn}
        userId={userId}
        mode={mode}
        name={name}
        createdCircle={createdCircle}
      />
    );
  }
}

const mapStateToProps = ({ auth, ctas }) => {
  let createdCircle = Object.values(ctas).length > 0;

  return {
    isSignedIn: auth.isSignedIn,
    userId: auth.userId,
    name: auth.first_name,
    createdCircle,
  };
};
export default connect(
  mapStateToProps,
  null
)(NavbarConnected);
