import { colors, dfs } from "./styleGuide";

const btnStyle = (type) => {
  let style = {};
  switch (type) {
    case "primary":
      style = {
        activeBg: `${colors.bittersweet}`,
        hoverBg: `${colors.salmon}`,
        disabledBg: `${colors.sundown}`,
        color: `${colors.white}`,
        bordercolor: `transparent`,
        bxShadow: `${colors.transparentBlack}`,
        textDecoration: "none",
        fontSize: `${dfs.normal}`,
        borderRadius: "5px",
      };
      break;
    case "secondary":
      style = {
        activeBg: `${colors.slateGrey}`,
        hoverBg: `${colors.eastBay}`,
        disabledBg: `${colors.greyChateau}`,
        color: `${colors.white}`,
        bordercolor: `transparent`,
        bxShadow: `${colors.transparentBlack}`,
        textDecoration: "none",
        fontSize: `${dfs.normal}`,
        borderRadius: "5px",
      };

      break;
    case "tertiary":
      style = {
        activeBg: `transparent`,
        hoverBg: `transparent`,
        disabledBg: `transparent`,
        color: `${colors.cloudBurst}`,
        bordercolor: `transparent`,
        bxShadow: `transparent`,
        textDecoration: "underline",
        fontSize: `${dfs.normal}`,
        borderRadius: "5px",
      };
      break;
    case "tertiary-lite":
      style = {
        activeBg: `transparent`,
        hoverBg: `transparent`,
        disabledBg: `transparent`,
        color: `${colors.slateGrey}`,
        bordercolor: `transparent`,
        bxShadow: `transparent`,
        textDecoration: "underline",
        fontSize: `${dfs.h5}`,
        borderRadius: "5px",
      };
      break;
    case "quad":
      style = {
        activeBg: `transparent`,
        hoverBg: `transparent`,
        disabledBg: `transparent`,
        color: `${colors.bittersweet}`,
        bordercolor: `transparent`,
        bxShadow: `transparent`,
        textDecoration: "none",
        fontSize: `${dfs.normal}`,
        borderRadius: "5px",
      };
      break;
    case "fifth":
      style = {
        activeBg: `${colors.bittersweet}`,
        hoverBg: `${colors.salmon}`,
        disabledBg: `${colors.sundown}`,
        color: `${colors.white}`,
        bordercolor: `transparent`,
        bxShadow: `${colors.transparentBlack}`,
        textDecoration: "none",
        fontSize: `${dfs.normal}`,
        borderRadius: "20px",
      };
      break;
    case "grey":
      style = {
        activeBg: `${colors.greyChateau}`,
        hoverBg: `${colors.greyChateau}`,
        disabledBg: `${colors.greyChateau}`,
        color: `${colors.white}`,
        bordercolor: `transparent`,
        bxShadow: `${colors.transparentBlack}`,
        textDecoration: "none",
        fontSize: `${dfs.normal}`,
        borderRadius: "5px",
      };

      break;
    case "maverick":
      style = {
        activeBg: `${colors.maverick}`,
        hoverBg: `${colors.maverick}`,
        disabledBg: `${colors.maverick}`,
        color: `${colors.white}`,
        bordercolor: `transparent`,
        bxShadow: `${colors.transparentBlack}`,
        textDecoration: "none",
        fontSize: `${dfs.normal}`,
        borderRadius: "5px",
      };

      break;
    default:
      style = {
        activeBg: `${colors.bittersweet}`,
        hoverBg: `${colors.salmon}`,
        disabledBg: `${colors.sundown}`,
        color: `${colors.white}`,
        bordercolor: `transparent`,
        bxShadow: `${colors.transparentBlack}`,
        textDecoration: "none",
        borderRadius: "5px",
        fontSize: `${dfs.normal}`,
      };
  }
  return style;
};
export default btnStyle;
