import React from "react";
import styled from "styled-components";
import Circle from "../Shared/Circle";
import EllipsisText from "../Shared/EllipsisText";

const CircleContainer = styled("div")`
  display: ${({ containerDisplay }) => containerDisplay};
`;
const CircleIconWithText = (props) => (
  <CircleContainer containerDisplay={props.containerDisplay}>
    <Circle {...props} />
    {props.children && <EllipsisText width={8}>{props.children}</EllipsisText>}
  </CircleContainer>
);

CircleIconWithText.defaultProps = {
  containerDisplay: "block",
};
export default CircleIconWithText;
