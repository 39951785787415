import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { passwordResetConfirm } from "../../store/actions";
import PasswordResetConfirmForm from "../Forms/PasswordResetConfirmForm";
import { Formik } from "formik";

class PasswordResetConfirmFormConnected extends Component {
  onSubmit = (values, actions) => {
    const { token, uid } = this.props.match.params;
    this.props.passwordResetConfirm(values, token, uid, actions);
    actions.resetForm({
      new_password1: "",
      new_password2: "",
    });
  };
  render() {
    return (
      <Formik
        initialValues={{
          new_password1: "",
          new_password2: "",
        }}
        onSubmit={(values, actions) => {
          this.onSubmit(values, actions);
        }}
        render={PasswordResetConfirmForm}
      />
    );
  }
}

export default withRouter(
  connect(
    null,
    { passwordResetConfirm }
  )(PasswordResetConfirmFormConnected)
);
