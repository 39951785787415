import React from "react";
import styled from "styled-components";
import { colors } from "../Shared/styleGuide";

const Wrapper = styled("div")`
  text-align: left;
  display: block;
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  padding-bottom: 1.875rem;
  color: ${colors.cloudBurst};
  font-family: Avenir Next;
  @media (max-width: 576px) {
    padding-bottom: 2rem;
  }
`;

Wrapper.defaultProps = {};

export default Wrapper;
