import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PromptShowScene from "../Scenes/PromptShowScene";

class PromptShowSceneConnected extends Component {
  render() {
    const { prompt } = this.props;
    return <>{prompt && <PromptShowScene {...this.props} />}</>;
  }
}

const mapStateToProps = (
  { prompts, circles, auth: { first_name, last_name } },
  ownProps
) => {
  const prompt = prompts[ownProps.match.params.id];
  const circle = Object.values(circles).filter(
    (circle) => circle && circle.posts.includes(prompt.id)
  )[0];

  return {
    prompt,
    circle,
    name: `${first_name} ${last_name}`,
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    null
  )(PromptShowSceneConnected)
);
