export const colors = {
  cloudBurst: "#3A3F4C", // main
  slateGrey: "#697789",
  eastBay: "#495060",
  greyChateau: "#999DA6", // secondary, dates, edit buttons
  lightGrey: "#EDEDED",
  lightGray: "D7D7D7",
  maverick: "#C9C3C7",
  selago: "#F4F3F4", // alternate for darker colors
  bittersweet: "#FF6161",
  salmon: "#F57070",
  sundown: "#FFB0B0",
  white: "#FFFFFF",
  greyBlue: "#BECDD5",
  green: "#27AE60",
  transparentBlack: "rgba(0, 0, 0, 0.25)",
};

// 1 rem = 16px
export const fontSizes = {
  h1: "3", //'48px'
  h2: "1.5", //'24px',
  h3: "1.125", //'18px',
  h4: "0.875", //'14px',
  normal: "1.125", //'18px',
  caption: "0.875", //'14px',
  tiny: "0.6875", //'11px',
};

// 1 rem = 16px
export const dfs = {
  h1: "3", //'48px'
  h2: "2", //'32px',
  h3: "1.5", //'24px',
  h4: "1.0625", //'17px',
  h5: "0.875", //'14px',
  h6: "0.75", //'12px'
  normal: "1.0625", //'17px',
};

export const mfs = {
  h1: "2", //'32px'
  h2: "1.5", //'24px',
  h3: "1.0625", //'17px',
  h4: "0.875", //'14px',
  h5: "0.75", //'12px',
  h6: "0.6875", //'11px'
  normal: "1.0625", //'17px',
};

export const dln = {
  h1: "3.5", //'56px'
  h2: "3", //'48px',
  h3: "2.25", //'36px',
  h4: "1.5", //'24px',
  h5: "1.1875", //'19px',
  h6: "1.1875", //'19px',
  normal: "1.5", //'24px',
};

export const mln = {
  h1: "2.4375", //'39px'
  h2: "2.25", //'36px',
  h3: "1.5", //'24px',
  h4: "1.75", //'28px',
  h5: "1.1875", //'19px',
  h6: "1.1875", //'19px',
  normal: "1.5", //'24px',
};
export const spacing = {};
//1.0625 17
//2.4375 39
//1.1875 19
//0.75 12
// 1.75 28
// 2.3125 37
// 2 32
// 3.5 56
//2.375 38
//0.9375 15
