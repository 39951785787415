import React from "react";
import { getIn } from "formik";
import styled from "styled-components";

import InputField from "../Shared/InputField";
import LabelField from "../Shared/LabelField";
import FlexVC from "../Shared/FlexVC";
const ErrorMessage = styled.div`
  height: 1rem;
  font-size: 0.75rem;
`;

const RenderFieldComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  form,
  lightLabel,
  inputtype,
  margin,
  pattern,
  mobileMed,
  width,
  index,
  dwidth,
  readOnly,
  ...props
}) => (
  <FlexVC direct={"column"} margin={margin} align={"flex-start"}>
    {label && <LabelField lightLabel>{label}</LabelField>}
    <InputField
      pattern={pattern}
      dwidth={dwidth}
      noMargin={index ? index : false}
      type={inputtype ? inputtype : "text"}
      mobileMed={mobileMed}
      width={width}
      readOnly={readOnly}
      {...field}
      {...props}
    />

    {index ? (
      <ErrorMessage>{getIn(errors, field.name)}</ErrorMessage>
    ) : (
      errors[field.name] &&
      touched[field.name] && <div>{errors[field.name]}</div>
    )}
  </FlexVC>
);

export default RenderFieldComponent;
