import React from "react";
import Modal from "../BasicModal";
import ModalHeader from "../ModalHeader";
import CircleModalType from "../CircleModalType";
import Button from "../../Shared/Button";
import Text from "../../Shared/Text";
import FlexVC from "../../Shared/FlexVC";

const LeaveCircleModal = ({ show, handleClose, circle }) => (
  <Modal show={show} onHide={handleClose}>
    <ModalHeader handleClick={handleClose} />

    <Modal.Body>
      <CircleModalType
        handleClose={handleClose}
        name={circle.circle_name}
        title={"Leave Circle"}
      />
      <Text display={"block"} margin={"0rem"}>
        Your responses will be archived, and you won't get any new questions.
      </Text>
      <FlexVC justify={"center"} direct={"column"} full>
        <FlexVC direct={"column"} margin={"1rem 0rem 0rem 0rem"} full>
          <Text margin={"0rem"}>
            To leave a circle email hello@fireside.social
          </Text>
        </FlexVC>
        <FlexVC direct={"column"} margin={"1rem 0rem 0rem 0rem"} full>
          <Button width={"100%"} type="button" onClick={() => handleClose()}>
            Stay With Your Circle
          </Button>
        </FlexVC>
      </FlexVC>
    </Modal.Body>

    <Modal.Footer />
  </Modal>
);
export default LeaveCircleModal;
{
  /* <Button width={"100%"} type="submit" btntype={"maverick"}>
        Leave Your circle
      </Button> */
}
