import history from "../../history";
import { ember } from "../middleware/apis/ember";
import { getCTAs } from "./ctaActions";
import { getInvites } from "./inviteActions";
import { clearLocalStorage } from "../../utilities/functions/localStorage";
import {
  SIGN_IN,
  SIGN_UP,
  RESET,
  PASSWORD_RESET,
  PASSWORD_RESET_CONFIRM,
  PASSWORD_CHANGE,
  UPDATE_USER,
  GET_WELCOME_MODAL_VIEWS,
  REQUESTING,
} from "./types";

export const isRequesting = (requesting) => async (dispatch) => {
  dispatch({
    type: REQUESTING,
    payload: {
      requesting,
    },
  });
};

export const signUp = (formValues, actions) => async (dispatch, getState) => {
  // Submit new user registration
  const res = await ember.post("/dj-rest-auth/registration/", {
    email: formValues.email,
    username: formValues.email,
    password1: formValues.password1,
    password2: formValues.password2,
  });

  // Set Token in Header to make subsequent calls
  ember.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${res.data.access_token}`;
  localStorage.setItem("fs-access", res.data.access_token);
  localStorage.setItem("fs-refresh", res.data.refresh_token);

  const acquireTokenRes = await ember.post("/api/token/refresh/", {
    refresh: res.data.refresh_token,
  });

  // Getting more stable access token
  ember.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${acquireTokenRes.data.access}`;
  localStorage.setItem("fs-access", acquireTokenRes.data.access);

  // Submit first_name and last_name of user
  const updateUserRes = await ember.put(`/users/${res.data.user.pk}/`, {
    first_name: formValues.first_name,
    last_name: formValues.last_name,
  });
  const userId = res.data.user.pk;

  await ember.post("/welcome/");

  dispatch({
    type: SIGN_UP,
    payload: {
      userId,
      first_name: updateUserRes.data.first_name,
      last_name: updateUserRes.data.last_name,
      email: res.data.user.email.toLowerCase(),
      new_user: true,
    },
  });
  // Check to see if user has any invites
  await dispatch(getInvites());
  history.push(`/get-started`);
};

export const signIn = (formValues, actions) => async (dispatch, getState) => {
  const res = await ember.post("/dj-rest-auth/login/", {
    ...formValues,
    username: formValues.email,
  });

  ember.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${res.data.access_token}`;
  localStorage.setItem("fs-access", res.data.access_token);
  localStorage.setItem("fs-refresh", res.data.refresh_token);

  const acquireTokenRes = await ember.post("/api/token/refresh/", {
    refresh: res.data.refresh_token,
  });

  // Getting more stable access token
  ember.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${acquireTokenRes.data.access}`;
  localStorage.setItem("fs-access", acquireTokenRes.data.access);

  const userId = res.data.user.pk;
  const userRes = await ember.get(`/users/${userId}/`);

  // Check to see if user has any invites
  await dispatch(getInvites());

  // Check to see if user is new & has no circles by looking at CTAs
  await dispatch(getCTAs());
  const newUser = Object.values(getState().ctas).length === 0;

  dispatch({
    type: SIGN_IN,
    payload: {
      userId: res.data.user.pk,
      first_name: userRes.data.first_name,
      last_name: userRes.data.last_name,
      email: userRes.data.email.toLowerCase(),
      new_user: newUser,
    },
  });

  newUser ? history.push(`/how-it-works`) : history.push(`/home`);
};

export const signOut = () => async (dispatch) => {
  let access = localStorage.getItem("fs-access");

  try {
    if (access) {
      await ember.post("/dj-rest-auth/logout/");
    }
    // clear out localstorage
    clearLocalStorage();
    // reset values in redux state
    dispatch({
      type: RESET,
    });
    // clear out auth token or else will get error on next signin
    ember.defaults.headers.common["Authorization"] = ``;
  } catch (error) {
    console.log(error);
  }
  history.push(`/`);
};

/* Resetting a password requires two steps.
 ** /dj-rest-auth/password/reset/
 *** params: email
 *** returns: uid, token
 ** /dj-rest-auth/password/reset/confirm/
 *** params: uid, token, new_password1,new_password2
 *** returns:
 */
export const passwordReset = ({ email }, actions) => async (dispatch) => {
  try {
    const resetResponse = await ember.post("/dj-rest-auth/password/reset/", {
      email,
    });

    return {
      type: PASSWORD_RESET,
    };
  } catch (error) {
    console.log(error);
    actions.setFieldError("general", "Sign Up Credentials Invalid");
  }
};

export const passwordResetConfirm = (formValues, token, uid, actions) => async (
  dispatch
) => {
  const { new_password1, new_password2 } = formValues;
  try {
    const resetResponse = await ember.post(
      "/dj-rest-auth/password/reset/confirm/",
      {
        uid,
        token,
        new_password1,
        new_password2,
      }
    );
    history.push("/");

    return {
      type: PASSWORD_RESET_CONFIRM,
    };
  } catch (error) {
    console.log(error);
    actions.setFieldError(
      "general",
      "Whoops! Something went wrong with the reset. Try again."
    );
  }
};

export const passwordChange = (formValues) => async (dispatch) => {
  const res = await ember.post("/dj-rest-auth/password/change/", formValues);
  return {
    type: PASSWORD_CHANGE,
  };
};

export const updateUser = (formValues) => async (dispatch, getState) => {
  const { userId } = getState().auth;

  try {
    const res = await ember.put(`/users/${userId}/`, { ...formValues });
    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });
  } catch (error) {
    // Message to send to error logging route
    console.log(error);

    history.push("/error");
  }
};

export const getWelcomeModalViews = () => async (dispatch) => {
  dispatch({
    type: GET_WELCOME_MODAL_VIEWS,
  });
};
