import React, { Component } from "react";
import SceneTemplate from "./SceneTemplate";
import HeaderSection from "../containers/HeaderSectionConnected";
import PromptWithResponsesConnected from "../containers/PromptWithResponsesConnected";
import LoadingScene from "../Scenes/LoadingScene";

class TimelineScene extends Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    this.setState({ loading: false });
  }
  render() {
    let {
      props,
      state: { loading },
    } = this;
    if (loading) {
      return <LoadingScene />;
    }
    return (
      <SceneTemplate
        mode={1}
        header={
          <HeaderSection
            header={`Timeline`}
            tagline={`See all responses from your Circles here`}
            mode={1}
          />
        }
      >
        {props.prompts.map((prompt) => (
          <PromptWithResponsesConnected
            tm
            prompt={prompt}
            key={prompt.id}
            {...props}
          />
        ))}
      </SceneTemplate>
    );
  }
}
export default TimelineScene;
