import React from "react";
import styled from "styled-components";

const FlexVC = styled("div")`
  display: flex;
  flex-direction: ${({ direct, rd }) => (rd ? `${direct}-reverse` : direct)};
  align-items: ${({ align }) => (align ? align : "center")};
  width: ${({ full, width }) => {
    if (full) return "100%";
    if (width) return width;
    return "auto";
  }};
  ${({ tall }) => tall && "height: 100vh;"}
  justify-content: ${({ justify }) => justify};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => (padding ? padding : "auto")};
  flex-wrap: ${({ wrap }) => (wrap ? "wrap" : "nowrap")};
  ${({ height }) => height && `height:${height};`}
  @media (max-width: 576px) {
    flex-direction: ${({ direct, mobileCol }) =>
      mobileCol ? "column" : direct};
      ${({ malign }) => `align-items:${malign}`};
      margin: ${({ mmargin }) => mmargin};
      ${({ mheight }) => mheight && `height:${mheight};`}
      ${({ mwidth }) => mwidth && `width:${mwidth};`}
      padding: ${({ mpad }) => (mpad ? mpad : "auto")};



    }
`;
export default FlexVC;
