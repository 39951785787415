import React from "react";
import styled from "styled-components";
import { colors, fontSizes } from "../Shared/styleGuide";

const Box = styled("div")`
  display: ${({ display }) => display};
  flex-direction: column;
  width: 100%;
  margin: 1.75rem 0rem;
  background: ${colors.white};
  box-sizing: border-box;
  align-items: center;
  justify-content: ${({ btnPresent }) =>
    btnPresent ? "space-between" : "center"};
  font-family: Avenir Next;
  line-height: 1rem;
  font-size: ${fontSizes.normal}rem;
  padding: ${({ padding }) => padding};
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 ${colors.transparentBlack};
  overflow: hidden;
`;

Box.defaultProps = {
  btnPresent: 0,
  display: "inline-flex",
  padding: "1.3125rem 5.625rem",
};

export default Box;
