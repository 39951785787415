import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InvitedToCircleScene from "../Scenes/InvitedToCircleScene";
import {
  updateInvite,
  createCurrentInvite,
  deleteCurrentInvite,
  getCTAs,
} from "../../store/actions";

class InvitedToCircleSceneConnected extends Component {
  componentDidMount() {
    // this is used to forget past currentInviteId used for HIW
    this.props.deleteCurrentInvite();
    this.props.createCurrentInvite(this.props.invited_id);
  }
  handleInvite = (id, res) => {
    this.props.updateInvite(id, res);
    this.props.getCTAs();
    this.props.deleteCurrentInvite();
  };
  render() {
    const { circle_name, invitor, invitedId } = this.props;
    return (
      <InvitedToCircleScene
        circle_name={circle_name}
        handleInvite={this.handleInvite}
        invitedId={invitedId}
        invitor={invitor}
        {...this.props}
      />
    );
  }
}
const mapStateToProps = ({ invites, auth }, ownProps) => {
  const invite = invites[ownProps.match.params.id || ownProps.inviteId];
  const circle_name = invite && invite.circle_name;
  // Return all the invites for the circle
  const invited_members =
    invites &&
    Object.values(invites).filter(
      (i) => i && i.circle == invite.circle && i.accept_status !== "Cancelled"
    );

  const current_user_invite =
    invited_members &&
    invited_members.filter((invite) => invite.invited_email == auth.email)[0];
  const invitor = current_user_invite && current_user_invite.invitor_first_name;
  const invited_id = current_user_invite && current_user_invite.id;
  const newUser = auth.new_user;

  return {
    invitor,
    circle_name,
    invited_id,
    invited_members,
    newUser,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    updateInvite,
    createCurrentInvite,
    deleteCurrentInvite,
    getCTAs,
  })(InvitedToCircleSceneConnected)
);
