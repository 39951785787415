import React from "react";
import { Field, Form } from "formik";
import Button from "../../Shared/Button";
import FlexVC from "../../Shared/FlexVC";
import RenderFieldComponent from "../../Shared/RenderFieldComponent";
import { validateName, validateEmail } from "../validate.js";

// form from in circles page
const MemberAddForm = (props) => (
  <Form>
    <Field
      component={RenderFieldComponent}
      placeholder={"First Name"}
      label={"First Name"}
      name={"first_name"}
      width={"100%"}
      validate={validateName}
      lightLabel
      {...props}
    />
    <Field
      component={RenderFieldComponent}
      placeholder={"Email"}
      label={"Email"}
      name={"email"}
      width={"100%"}
      validate={validateEmail}
      lightLabel
      {...props}
    />

    <FlexVC
      justify={"center"}
      direct={"column"}
      margin={"1rem 0rem 0rem 0rem"}
      full
    >
      <Button type="submit" width={"100%"}>
        Invite New Member
      </Button>
      <FlexVC direct={"column"} margin={"1rem 0rem 0rem 0rem"} full>
        <Button
          width={"100%"}
          type="button"
          btntype={"maverick"}
          onClick={() => props.handleClose()}
        >
          Cancel
        </Button>
      </FlexVC>
    </FlexVC>
  </Form>
);

export default MemberAddForm;
