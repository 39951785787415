import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors, fontSizes } from "../Shared/styleGuide";

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  font-size: ${fontSizes.normal}rem;
  color: ${({ mode }) => (mode ? colors.cloudBurst : colors.white)} !important;
  padding: 0.25rem 1.5rem;
  :hover {
    color: ${({ mode }) =>
      mode ? colors.white : colors.cloudBurst} !important;
    text-decoration: none;
  }
  :focus {
    color: ${({ mode }) =>
      mode ? colors.cloudBurst : colors.white} !important;
    text-decoration: none;
  }
`;

const MobileMenuLink = (props) => {
  return <StyledLink {...props}>{props.children}</StyledLink>;
};

MobileMenuLink.defaultProps = {};

export default MobileMenuLink;
