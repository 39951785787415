import React from "react";
import PropTypes from "prop-types";
import Text from "../Shared/Text";
import Button from "../Shared/Button";
import Box from "../Shared/Box";
import FlexVC from "../Shared/FlexVC";

const NotificationBox = ({
  name,
  notification,
  followUp,
  btnText,
  btntype,
  padding,
  handleClick
}) => (
  <Box btnPresent={1} padding={padding}>
    <Text margin={"0.5rem"}>
      {notification} {name && <Text fontWeight={600}>{name}!</Text>}
    </Text>
    {followUp && <Text>{followUp}</Text>}
    {btnText && (
      <FlexVC direct={"row"} margin={"1.6875rem 0rem 0rem 0rem"}>
        <Button onClick={handleClick} btntype={btntype}>
          {btnText}
        </Button>
      </FlexVC>
    )}
  </Box>
);
NotificationBox.propTypes = {
  name: PropTypes.string,
  notification: PropTypes.string,
  followUp: PropTypes.string,
  btnText: PropTypes.string,
  btntype: PropTypes.string,
  btnPresent: PropTypes.number
};
NotificationBox.defaultProps = {
  notification: "You have new responses from",
  btnText: "Read Responses",
  btnPresent: 1,
  btntype: "primary"
};
export default NotificationBox;
