import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { passwordReset } from "../../store/actions";
import PasswordResetForm from "../Forms/PasswordResetForm";
import { Formik } from "formik";

class PasswordResetFormConnected extends Component {
  onSubmit = (values, actions) => {
    this.props.passwordReset(values, actions);
    actions.resetForm({
      email: "",
    });
    // should show some sort of confirmation
  };
  render() {
    return (
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={(values, actions) => {
          this.onSubmit(values, actions);
        }}
        render={PasswordResetForm}
      />
    );
  }
}

export default withRouter(
  connect(
    null,
    { passwordReset }
  )(PasswordResetFormConnected)
);
