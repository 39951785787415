import React from "react";
import styled from "styled-components";
import { colors, fontSizes } from "../Shared/styleGuide";

const Pill = styled("button")`
  display: inline-block;
  user-select: none;
  vertical-align: middle;
  padding: 0px;
  width: 78px;
  height: 16px;
  color: ${colors.white};
  font-family: Avenir Next;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  background: ${colors.maverick};
  border: 1px solid ${colors.maverick};
  text-decoration: none;
  border-radius: 5px;
  font-weight: 600};
  :focus {
    outline: none;
    background: ${colors.maverick};
  }

  :hover {
    background: ${colors.maverick};
    border: 1px solid  ${colors.maverick};
    box-shadow: 1px 4px 10px ${colors.transparentBlack};
  }
  :disabled {
    background:  ${colors.maverick};
    border: 1px solid  ${colors.maverick};
  }
`;

export default Pill;
