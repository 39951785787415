import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { colors } from "../Shared/styleGuide";

const InputContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 56px;
  height: 29px;
  > input {
    display: none;
  }
`;

const Input = styled.input`
  &:checked + span {
    background-color: ${colors.green};
  }
  &:checked + span:before {
    -webkit-transform: translateX(23px);
    -ms-transform: translateX(23px);
    transform: translateX(23px);
  }
  &:disabled + span {
    background-color: ${colors.maverick};
    opacity: 0.3;
    cursor: not-allowed;
  }
  &:disabled:checked + span {
    background-color: ${colors.green};
    opacity: 0.3;
    cursor: not-allowed;
  }
  &:focus + span {
    box-shadow: 0 0 1px ${colors.green};
  }
`;

const Slider = styled.span`
  display: flex;
  align-items: center;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;

  background-color: ${colors.maverick};
  -webkit-transition: 0.2s;
  transition: 0.2s;
  &:before {
    content: "";
    position: relative;
    height: 23px;
    width: 23px;
    left: 3px;
    background-color: ${colors.white};
    border-radius: 50%;
    -webkit-transition: 0.2s;
    transition: 0.2s;
  }
`;

const Toggle = props => {
  const { name, value, onChange, checked, disabled } = props;
  return (
    <InputContainer onChange={onChange}>
      <Input
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
      />
      <Slider />
    </InputContainer>
  );
};

Toggle.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};
export default Toggle;
