import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import HomeScene from "../Scenes/HomeScene";
import LoadingScene from "../Scenes/LoadingScene";

import {
  getCircles,
  getCTAs,
  getWelcomeModalViews,
  getInvites,
  getNotifications,
} from "../../store/actions";

class HomeSceneConnected extends Component {
  state = {
    loading: true,
  };
  async componentDidMount() {
    await this.props.getInvites();
    this.props.circles.length < 1 && (await this.props.getCircles());
    await this.props.getCTAs();
    this.props.getNotifications();
    this.setState({ loading: false });
  }
  render() {
    return this.state.loading ? (
      <LoadingScene />
    ) : (
      <HomeScene {...this.props} />
    );
  }
}

const mapStateToProps = ({ invites, responses, auth, ctas, circles }) => {
  const { welcomeModalViews } = auth;
  const userInvites = Object.values(invites).filter(
    (invite) =>
      invite &&
      invite.invited_email == auth.email &&
      invite.accept_status === "Pending"
  );
  const userResArray = Object.values(responses).filter(
    (res) => res.owner_id == auth.userId
  );
  const firstRes =
    userResArray.length === 1 &&
    userResArray.filter((res) => res.is_submitted === false);
  return {
    circles: Object.values(circles),
    ctas: ctas,
    name: auth.first_name,
    userInvites,
    firstRes,
    welcomeModalViews,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    getCircles,
    getCTAs,
    getWelcomeModalViews,
    getInvites,
    getNotifications,
  })(HomeSceneConnected)
);
