import React from "react";
import Modal from "../BasicModal";
import ModalHeader from "../ModalHeader";
import CircleModalType from "../CircleModalType";
import CircleNameEditFormConnected from "../../containers/CircleNameEditFormConnected";

const CircleNameEditModal = ({
  show,
  handleClose,
  circle,
  handleLeaveCircle,
}) => (
  <Modal show={show} onHide={handleClose}>
    <ModalHeader handleClick={handleClose} />
    <Modal.Body>
      <CircleModalType name={circle.circle_name} title={"Change Name"} />
      <CircleNameEditFormConnected handleClose={handleClose} circle={circle} />
    </Modal.Body>
    <Modal.Footer />
  </Modal>
);
export default CircleNameEditModal;
