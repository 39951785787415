import React from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import CircleHeaderInfo from "./CircleHeaderInfo";
import HeroRow from "../../Heros/HeroRow";

const CircleHeaderSection = ({ mode, circle, first_name }) => (
  <HeroRow mode={mode}>
    <Container>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <CircleHeaderInfo first_name={first_name} circle={circle} />
        </Col>
      </Row>
    </Container>
  </HeroRow>
);
CircleHeaderSection.propTypes = {
  mode: PropTypes.number,
  circle: PropTypes.object,
  first_name: PropTypes.string,
};
CircleHeaderSection.defaultProps = {
  mode: 1, // 0 for dark, 1 for light
  circle: {},
  first_name: "fireside",
};
export default CircleHeaderSection;
