import React from "react";
import { Field } from "formik";
import Button from "../../Shared/Button";
import Form from "../../Shared/Form";
import RenderFieldComponent from "../../Shared/RenderFieldComponent";
import FlexVC from "../../Shared/FlexVC";
import { validateEmail } from "../validate.js";

const PasswordResetForm = ({ errors }) => (
  <Form mobileMed>
    <FlexVC direct={"column"} full>
      <Field
        component={RenderFieldComponent}
        name="email"
        inputtype={"email"}
        placeholder={"Email"}
        mobileMed
        validate={validateEmail}
      />

      <Button width={"100%"} type="submit" btntype="primary" mobileMed>
        Send Password Reset
      </Button>
      {errors.general}
    </FlexVC>
  </Form>
);

export default PasswordResetForm;
