import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { pick } from "lodash";
import { Formik } from "formik";
import { createResponse } from "../../store/actions";
import ResponseForm from "../Forms/ResponseForm";
import ConfirmDraftModal from "../Modals/ConfirmDraftModal";

// Google Analytics
import { Event } from "../Tracking";

class ResponseFormConnected extends Component {
  state = {
    show: false,
  };
  handleClose = () => {
    this.setState({
      show: false,
    });
  };
  handleShow = () => {
    this.setState({
      show: true,
    });
  };
  onSubmit = async (values) => {
    const {
      createResponse,
      match: {
        params: { id },
      },
    } = this.props;
    // display modal to show draft is saving
    values.is_submitted === false && this.handleShow();

    await createResponse(values, id);
    Event("Response", "Submitted", id);
  };

  render() {
    const { prompt, response, circle } = this.props;
    return (
      <>
        <ConfirmDraftModal
          show={this.state.show}
          handleClose={this.handleClose}
        />

        {prompt && (
          <Formik
            onSubmit={(values, actions) => this.onSubmit(values, actions)}
            initialValues={{
              circle_name: circle.circle_name,
              circle_id: circle.id,
              responsesList: prompt.response,
              response_text: response.response_text,
              response_id: response.id,
              is_submitted: response.is_submitted,
            }}
            render={ResponseForm}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (
  { prompts, responses, auth: { userId, first_name, last_name } },
  ownProps
) => {
  const prompt = prompts[ownProps.match.params.id];
  const response = Object.values(pick(responses, prompt.response)).filter(
    (res) => res.owner_id == userId
  )[0];
  return {
    prompt,
    response,
    userId,
    name: `${first_name} ${last_name}`,
  };
};
export default withRouter(
  connect(mapStateToProps, { createResponse })(ResponseFormConnected)
);
