import React, { Component } from "react";
import SceneTemplate from "./SceneTemplate";
import HeaderSection from "../containers/HeaderSectionConnected";
import BasicInfoSection from "../Sections/BasicInfoSection";
import NotificationsSection from "../Sections/NotificationsSection";
import OtherSection from "../Sections/OtherSection";

class AccountScene extends Component {
  render() {
    const { props } = this;

    return (
      <SceneTemplate
        mode={1}
        header={
          <HeaderSection
            tagline={`You’ve been a fireside member since ${props.dateJoined}`}
            header={`Account Info`}
          />
        }
      >
        <BasicInfoSection {...props} />
        {false && <NotificationsSection />}
        <OtherSection />
      </SceneTemplate>
    );
  }
}
export default AccountScene;
