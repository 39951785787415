import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { passwordChange } from "../../store/actions";
import UpdatePasswordForm from "../Forms/UpdatePasswordForm";
import { Formik } from "formik";

class UpdatePasswordConnected extends Component {
  onSubmit = (values, actions) => {
    this.props.passwordChange(values);

    actions.resetForm({
      old_password: "",
      new_password1: "",
      new_password2: ""
    });
    this.props.handleSubmit(this.props.form);
  };
  render() {
    return (
      <Formik
        initialValues={{
          old_password: "",
          new_password1: "",
          new_password2: ""
        }}
        onSubmit={(values, actions) => {
          this.onSubmit(values, actions);
        }}
        render={UpdatePasswordForm}
      />
    );
  }
}

export default withRouter(
  connect(null, { passwordChange })(UpdatePasswordConnected)
);
