import React from "react";
import styled, { css } from "styled-components";
import { dfs, mfs, dln, mln, colors } from "../Shared/styleGuide";

const baseStyle = css`
  color: ${({ mode }) => {
    if (mode === 0) return colors.selago;
    if (mode === 1) return colors.cloudBurst;
    return colors.cloudBurst;
  }};
  font-family: Avenir Next, sans serif;
  font-weight: ${({ light }) => (light ? "400" : "500")};
  text-align: ${(props) => {
    if (props.right) return "right";
    if (props.center) return "center";
    return "left";
  }};
  text-transform: ${(props) => {
    if (props.uppercase) return "uppercase";
    if (props.lowercase) return "lowercase";
    return "none";
  }};
  word-wrap: break-word;
  word-break: ${({ ellipsis }) => (ellipsis ? " break-all" : "normal")};
  opacity: ${({ opacity }) => opacity};
  margin: ${({ margin }) => (margin ? margin : "0.5rem 0rem")};
  display: ${({ inblock }) => (inblock ? "inline-block" : "block")};
  ${({ ellipsis }) =>
    ellipsis &&
    `width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;`}
`;

const HeaderOne = styled.h1`
  ${baseStyle};

  font-size: ${dfs.h1}rem;
  line-height: ${dln.h1}rem;
  height: ${({ ellipsis }) =>
    ellipsis ? `calc(${dfs.h1}rem + 0.75rem)` : "auto"};

  @media (max-width: 576px) {
    font-size: ${mfs.h1}rem;
    line-height: ${mln.h1}rem;
    height: ${({ ellipsis }) =>
      ellipsis ? `calc(${mfs.h1}rem + 0.75rem)` : "auto"};
    margin: ${({ noMargin, margin }) =>
      margin ? margin : noMargin ? "0rem" : "0.25rem 0rem"};
  }
`;

const HeaderTwo = styled.h2`
  ${baseStyle};
  color: ${({ grey }) => grey && colors.slateGrey} !important;
  font-size: ${dfs.h2}rem;
  line-height: ${dln.h2}rem;
  height: ${({ ellipsis }) =>
    ellipsis ? `calc(${dfs.h2}rem + 0.75rem)` : "auto"};
  margin: ${({ noMargin, margin }) =>
    margin ? margin : noMargin ? "0rem" : "0.5rem 0rem"};

  @media (max-width: 576px) {
    font-size: ${({ noRes }) => (noRes ? `${mfs.h2}rem` : `1.125rem`)};
    line-height: ${mln.h2}rem;
    margin: ${({ noMargin, margin }) =>
      margin ? margin : noMargin ? "0rem" : " 0.25rem 0rem"};
    height: ${({ ellipsis }) =>
      ellipsis ? `calc(${mfs.h2}rem + 0.75rem)` : "auto"};
  }
`;

const HeaderThree = styled.h3`
  ${baseStyle};
  ${({ cursor }) => cursor && `cursor: pointer`};
  font-size: ${dfs.h3}rem;
  line-height: ${({ nlh }) => (nlh ? "auto" : `${dln.h3}rem`)};
  height: ${({ ellipsis }) =>
    ellipsis ? `calc(${dfs.h3}rem + 0.75rem)` : "auto"};
  margin: ${({ noMargin }) => (noMargin ? "0rem" : "0.5rem 0rem")};
  color: ${({ grey, slateGrey, mode }) => {
    if (grey) return colors.greyChateau;
    if (slateGrey) return colors.slateGrey;
    if (mode === 0) return colors.selago;
    if (mode === 1) return colors.cloudBurst;
    return colors.cloudBurst;
  }} !important;

  @media (max-width: 576px) {
    font-size: ${mfs.h3}rem;
    line-height: ${({ nlh }) => (nlh ? "auto" : `${mln.h3}rem`)};
    height: ${({ ellipsis }) => (ellipsis ? `${mln.h3}rem` : "auto")};
  }
`;

const HeaderFour = styled.h4`
  ${baseStyle};
  color: ${({ color }) => color} !important;
  font-size: ${dfs.h4}rem;
  line-height: ${({ nlh }) => (nlh ? "auto" : `${dln.h4}rem`)};
  height: ${({ ellipsis }) =>
    ellipsis ? `calc(${dfs.h4}rem + 0.75rem)` : "auto"};
  @media (max-width: 576px) {
    line-height: ${({ nlh }) => (nlh ? "auto" : `${mln.h4}rem`)};
    font-size: ${mfs.h4}rem;
  }
`;
const HeaderFive = styled.h5`
  ${baseStyle};
  color: ${({ color }) => color} !important;
  font-size: ${dfs.h5}rem;
  line-height: ${dln.h5}rem;
  height: ${({ ellipsis }) => (ellipsis ? `1.5rem` : "auto")};
  margin: ${({ margin }) => margin};
  @media (max-width: 576px) {
    ${({ desOn }) => desOn && "font-size: 1.25rem;"}
    font-size: ${mfs.h5}rem;
    line-height: ${mln.h5}rem;
  }
`;
const HeaderSix = styled.h6`
  ${baseStyle};

  font-size: ${dfs.h6}rem;
  line-height: ${dln.h6}rem;

  @media (max-width: 576px) {
    font-size: ${mfs.h6}rem;
    line-height: ${mln.h6}rem;
  }
`;

const Header = ({
  h2,
  h3,
  h4,
  h5,
  h6,
  noMargin,
  right,
  center,
  uppercase,
  lowercase,
  grey,
  opacity,
  inblock,
  mode,
  margin,
  light,
  handleHeaderClick,
  children,
  noRes,
  desOn,
  width,
  ellipsis,
  color,
  slateGrey,
  nlh,
  cursor,
}) => {
  if (h2)
    return (
      <HeaderTwo
        noMargin={noMargin}
        right={right}
        center={center}
        grey={grey}
        opacity={opacity}
        inblock={inblock}
        uppercase={uppercase}
        mode={mode}
        margin={margin}
        light={light}
        onClick={handleHeaderClick}
        noRes={noRes}
        width={width}
        ellipsis={ellipsis}
        color={color}
      >
        {children}
      </HeaderTwo>
    );
  if (h3)
    return (
      <HeaderThree
        nlh={nlh}
        noMargin={noMargin}
        right={right}
        center={center}
        grey={grey}
        opacity={opacity}
        inblock={inblock}
        uppercase={uppercase}
        mode={mode}
        margin={margin}
        light={light}
        onClick={handleHeaderClick}
        width={width}
        ellipsis={ellipsis}
        color={color}
        slateGrey={slateGrey}
        cursor={cursor}
      >
        {children}
      </HeaderThree>
    );
  if (h4)
    return (
      <HeaderFour
        noMargin={noMargin}
        right={right}
        center={center}
        opacity={opacity}
        inblock={inblock}
        uppercase={uppercase}
        mode={mode}
        margin={margin}
        light={light}
        onClick={handleHeaderClick}
        width={width}
        ellipsis={ellipsis}
        color={color}
        nlh={nlh}
      >
        {children}
      </HeaderFour>
    );
  if (h5)
    return (
      <HeaderFive
        noMargin={noMargin}
        width={width}
        right={right}
        center={center}
        opacity={opacity}
        inblock={inblock}
        color={color}
        uppercase={uppercase}
        mode={mode}
        margin={margin}
        light={light}
        onClick={handleHeaderClick}
        desOn={desOn}
        ellipsis={ellipsis}
      >
        {children}
      </HeaderFive>
    );
  if (h6)
    return (
      <HeaderSix
        noMargin={noMargin}
        right={right}
        center={center}
        opacity={opacity}
        inblock={inblock}
        uppercase={uppercase}
        mode={mode}
        margin={margin}
        light={light}
        width={width}
        onClick={handleHeaderClick}
        ellipsis={ellipsis}
        color={color}
      >
        {children}
      </HeaderSix>
    );
  return (
    <HeaderOne
      noMargin={noMargin}
      right={right}
      center={center}
      opacity={opacity}
      inblock={inblock}
      uppercase={uppercase}
      mode={mode}
      margin={margin}
      width={width}
      light={light}
      onClick={handleHeaderClick}
      ellipsis={ellipsis}
      color={color}
    >
      {children}
    </HeaderOne>
  );
};

Header.defaultProps = {
  noMargin: true,
  textTransform: "none",
  opacity: 1.0,
};

export default Header;
