// load state
export const loadStateFromLocalStorage = () => {
  try {
    const reduxState = localStorage.getItem("fs-state");
    if (reduxState === null) {
      return undefined;
    }
    return JSON.parse(reduxState);
  } catch (err) {
    return undefined;
  }
};

// set state
export const saveStateToLocalStorage = (state) => {
  try {
    const reduxState = JSON.stringify(state);
    localStorage.setItem("fs-state", reduxState);
  } catch {}
};

// clear state
export const clearLocalStorage = () => {
  localStorage.removeItem("fs-state");
  localStorage.removeItem("fs-access");
  localStorage.removeItem("fs-refresh");
};
