import React from "react";
import styled from "styled-components";
import { colors } from "../Shared/styleGuide";

const StyledNavbar = styled("div")`
  display: flex;
  font-family: Avenir Next;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${({ mode }) => (mode ? colors.selago : colors.slateGrey)}
  z-index: 1000;
  padding: 0px 20px;
  padding-top: 14px;
  height: 56px;
  color: ${({ mode }) => (mode ? colors.cloudBurst : colors.white)};
  a {
    color: ${({ mode }) => (mode ? colors.cloudBurst : colors.white)};
  }
  a:hover {
    color: ${({ mode }) => (mode ? colors.cloudBurst : colors.white)};
    text-decoration: none;
  }
  svg {
    height: 1.5rem;
  }
`;
export default StyledNavbar;
