import React from "react";
import styled from "styled-components";

const CircleInfoWrapper = styled("div")`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (max-width: 576px) {
    flex-direction: column;
    margin-top: 0px;
  }
`;
export default CircleInfoWrapper;
