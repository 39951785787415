import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../Shared/styleGuide";

const LogoText = styled(Link)`
  font-family: Avenir Next;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.03em;
  color: ${({ mode }) => (mode ? ` #2b2f38` : `${colors.white}`)};
`;
export default LogoText;
