import React from "react";
import styled from "styled-components";
import circleBgSelector from "../../utilities/functions/circleBgSelector.js";
import { colors } from "./styleGuide";

const InnerCircle = styled("div")`
  display: inline-flex;
  box-sizing: border-box;
  height: ${({ size }) => size / 1.1}rem;
  width: ${({ size }) => size / 1.1}rem;
  line-height: ${({ size }) => (size * 2) / 10}rem;
  font-size: ${({ size }) => (size * 2) / 5}rem;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color};
  background: ${({ plainBg, name, alert, resv }) =>
    circleBgSelector(plainBg, name, alert, resv)};
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: ${({ bordercolor, border, size, innerwhite }) => {
    if (border && bordercolor !== colors.bittersweet)
      return `${size * 0.75}px solid ${colors.white}`;
    if (border && bordercolor == colors.bittersweet)
      return `3px solid ${colors.white}`;
    if (innerwhite) return `2px solid ${colors.white}`;
    return `transparent`;
  }}
  z-index: ${({ z }) => z};
  ${({ filter }) => filter && `filter:${filter};`}

  box-shadow: ${({ bordercolor, border }) =>
    border && bordercolor !== colors.bittersweet
      ? `0px 8px 8px ${colors.transparentBlack}`
      : `transparent`};
  transition: ${({ noscale }) => (noscale ? "" : "width 500ms, height 500ms")};
  z-index: ${({ z }) => z};
  @media (max-width: 576px) {
    ${({ msize }) => msize && `height:${msize / 1.1}rem;`}
    ${({ msize }) => msize && `width:${msize / 1.1}rem;`}
    line-height: ${({ msize }) => (msize * 2) / 10}rem;
  font-size: ${({ msize }) => (msize * 2) / 5}rem;
  border: ${({ bordercolor, border, msize, innerwhite }) => {
    if (border && bordercolor !== colors.bittersweet)
      return `${msize * 0.75}px solid ${colors.white}`;
    if (border && bordercolor == colors.bittersweet)
      return `3px solid ${colors.white}`;
    if (innerwhite) return `2px solid ${colors.white}`;
    return `transparent`;
  }}
  }
`;

const OuterCircle = styled("div")`
  display: inline-flex;
  box-sizing: border-box;
  margin: ${({ margin }) => margin};
  height: ${({ size }) => size}rem;
  width: ${({ size }) => size}rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  z-index: ${({ z }) => z};

  border: ${({ bordercolor, border, outerdark, outer }) => {
    if (border && bordercolor == colors.bittersweet)
      return `4px solid ${bordercolor}`;
    if (outer) return `2px solid ${bordercolor}`;
    if (outerdark) return `3px solid ${outerdark}`;
    return "transparent";
  }}

  background: transparent;
  transition: ${({ noscale }) => (noscale ? "" : "width 500ms, height 500ms")};
  @media (max-width: 576px) {
    ${({ msize }) => msize && `height:${msize}rem;`}
    ${({ msize }) => msize && `width:${msize}rem;`}
  }
  ${({ cursor }) => cursor && `cursor: pointer`};
  `;

const Circle = (props) => {
  const [initial] = props.name;

  return (
    <OuterCircle {...props}>
      <InnerCircle {...props}>
        {props.plainBg ? (props.alert ? props.name : `${initial}`) : ""}
      </InnerCircle>
    </OuterCircle>
  );
};

Circle.defaultProps = {
  size: 6,
  name: " ", // make sure this has a space
  bordercolor: `${colors.bittersweet}`,
  color: `${colors.white}`,
  border: false,
  margin: "1rem",
  alert: 0,
  z: 1,
};

export default Circle;
