import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CirclePromptsList from "../Lists/CirclePromptsList";
import { pick } from "lodash";
import ProtoTypes from "prop-types";

class CirclePromptsListConnected extends Component {
  render() {
    const { prompts } = this.props;
    return <>{prompts && <CirclePromptsList {...this.props} />}</>;
  }
}
const mapStateToProps = (
  { prompts, circles, auth: { userId } },
  { match: { params } }
) => {
  const { id } = params;

  // get prompts for circle
  const promptIds = circles[id].posts;
  const promptsList = Object.values(pick(prompts, promptIds)).sort(
    (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
  );
  return {
    prompts: promptsList,
    userId,
  };
};

CirclePromptsListConnected.protoTypes = {
  prompts: ProtoTypes.array,
};

export default withRouter(
  connect(mapStateToProps, null)(CirclePromptsListConnected)
);
