import React from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import HeaderSection from "../containers/HeaderSectionConnected";
import SceneTemplate from "./SceneTemplate";
import FlexVC from "../Shared/FlexVC";

class PhotoUploadScene extends React.Component {
  state = {
    selectedImgFile: null,
    sizeError: false,
    crop: {
      unit: "%",
      width: 30,
      aspect: 1 / 1,
    },
    croppedImageUrl: null,
    imgToUpload: null,
  };
  handleImageSelection = (e) => {
    const selected = e.target.files[0];

    // Check file size <= 500kb
    if (selected.size <= 500000) {
      if (selected) {
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          console.log(reader);
          this.setState({ selectedImgFile: reader.result });
        });
        reader.readAsDataURL(selected);
      }
    } else {
      this.setState({
        sizeError: true,
      });
    }
  };

  handleImageUpload = () => {
    const { selectedImgFile, imgToUpload } = this.state;
    //TODO: add a message that image has to be cropped?

    if (selectedImgFile && imgToUpload) {
      this.props.createImage(imgToUpload);
    }
    this.props.getUser();
  };
  onImageLoaded = (image) => {
    console.log("on img loaded");
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "user-photo.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    console.log("gettubg crip un");
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        // converting to File
        blob.name = fileName;
        blob.lastModifiedDate = new Date();
        this.setState({ imgToUpload: blob });

        // used to create img to display
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }
  render() {
    const { photoURL } = this.props;
    const { sizeError, crop, croppedImageUrl, selectedImgFile } = this.state;
    return (
      <SceneTemplate
        mode={0}
        header={<HeaderSection header="Photo Upload" mode={0} />}
      >
        <FlexVC direct="column" margin="1rem 0rem">
          <FlexVC direct="row" align="flex-start">
            {selectedImgFile && (
              <FlexVC direct="column" margin="1rem 0rem">
                <h1>Original image</h1>
                <ReactCrop
                  src={selectedImgFile}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />
              </FlexVC>
            )}
            {croppedImageUrl && (
              <FlexVC direct="column" margin="1rem">
                <h1>Cropped Image to Upload</h1>
                <img
                  alt="Crop"
                  style={{ maxWidth: "100%" }}
                  src={croppedImageUrl}
                />
              </FlexVC>
            )}
          </FlexVC>

          <FlexVC direct="row">
            <input
              type="file"
              id="img"
              name="img"
              accept="image/*"
              multiple={false}
              onChange={this.handleImageSelection}
            />
            <button onClick={this.handleImageUpload}>Upload</button>
          </FlexVC>

          {photoURL && (
            <FlexVC direct="column" margin="1rem">
              <h1>User Uploaded photo</h1>
              <img src={photoURL} />
            </FlexVC>
          )}
          {sizeError && "Image is too large"}
        </FlexVC>
      </SceneTemplate>
    );
  }
}

export default PhotoUploadScene;
