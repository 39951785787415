import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateUser } from "../../store/actions";
import UpdateNameForm from "../Forms/UpdateNameForm";
import { Formik } from "formik";

class UpdateNameConnected extends Component {
  onSubmit = (values, actions) => {
    this.props.updateUser(values);
    actions.resetForm({
      first_name: "",
      last_name: ""
    });
    this.props.handleSubmit(this.props.form);
  };
  render() {
    return (
      <Formik
        initialValues={{
          first_name: "",
          last_name: ""
        }}
        onSubmit={(values, actions) => {
          this.onSubmit(values, actions);
        }}
        render={UpdateNameForm}
      />
    );
  }
}

export default withRouter(connect(null, { updateUser })(UpdateNameConnected));
