import React from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import { fontSizes, colors } from "../../Shared/styleGuide";

const StyledSlider = styled(Slider)`
  height: ${({ vertical }) => (vertical ? "200px !important" : "100%")};
  margin: 1rem 2rem;
  width: ${({ vertical }) => (vertical ? "14px" : "42%")} !important;

  .rc-slider-vertical {
    width: 14px;
    height: 100%;
    padding: 0 5px;
  }
  .rc-slider-vertical .rc-slider-rail {
    height: 100%;
    width: 4px;
  }
  .rc-slider-vertical .rc-slider-track {
    left: 5px;
    bottom: 0;
    width: 4px;
  }
  .rc-slider-vertical .rc-slider-handle {
    margin-left: -5px;
    touch-action: pan-y;
  }
  .rc-slider-vertical .rc-slider-mark {
    top: 0;
    left: 18px;
    height: 100%;
  }
  .rc-slider-vertical .rc-slider-step {
    height: 100%;
    width: 4px;
  }
  .rc-slider-vertical .rc-slider-dot {
    left: 2px;
    margin-bottom: -4px;
  }
  .rc-slider-vertical .rc-slider-dot:first-child {
    margin-bottom: -4px;
  }
  .rc-slider-vertical .rc-slider-dot:last-child {
    margin-bottom: -4px;
  }

  .rc-slider {
    &-rail {
      background-color: ${colors.greyChateau};
    }

    &-track {
      background-color: ${colors.cloudBurst};
    }

    &-handle {
      border-color: ${colors.cloudBurst}!important;
      background-color: ${colors.bittersweet};
      &:active {
        border-color: ${colors.cloudBurst};
        box-shadow: 0 0 0 5px ${colors.bittersweet};
      }

      &:focus {
        border-color: tint (${colors.cloudBurst}, 20%);
        box-shadow: 0 0 0 5px ${colors.cloudBurst};
      }

      &-click-focused:focus {
        border-color: tint(${colors.cloudBurst}, 50%);
      }

      &:hover {
        border-color: ${colors.cloudBurst};
      }

      &:active {
        border-color: tint(${colors.cloudBurst}, 20%);
        box-shadow: 0 0 5px tint(${colors.cloudBurst}, 20%);
      }
    }

    &-mark-text {
      color: ${colors.cloudBurst};
      font-size: ${fontSizes.h2}rem;

      &-active {
        color: ${colors.cloudBurst};
      }
    }

    &-step {
      background: transparent;
    }

    &-dot {
      border: 2px solid ${colors.cloudBurst};
      background-color: ${colors.white};

      &-active {
        border-color: tint(${colors.cloudBurst}, 50%);
      }
    }

    &-disabled {
      background-color: #e9e9e9;

      .rc-slider-track {
        background-color: @disabledColor;
      }

      .rc-slider-handle,
      .@{prefixClass}-dot {
        border-color: @disabledColor;
        box-shadow: none;
        background-color: #fff;
        cursor: not-allowed;
      }

      .rc-slider-mark-text,
      .@{prefixClass}-dot {
        cursor: not-allowed !important;
      }
    }
  }
`;
const WrappedSlider = props => <StyledSlider {...props} />;
export default WrappedSlider;
