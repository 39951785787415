import React from "react";
import styled from "styled-components";
import { colors } from "../Shared/styleGuide";

const InputField = styled("input")`
  width: ${(props) => {
    if (props.width) return props.width;
    if (props.dwidth) return "220px";

    return "343px";
  }};
  height: 40px;
  background: ${colors.white};
  border: 1px solid ${colors.greyChateau};
  box-sizing: border-box;
  border-radius: 3px;
  padding-left: 5px;
  margin: ${({ noMargin }) => (noMargin ? "0rem" : "0.5rem 0rem 1rem 0rem")};
  :focus {
    outline: none;
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: ${colors.cloudBurst};
    font-weight: 500;
    padding-left: 1rem;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.cloudBurst};
    font-weight: 500;
    padding-left: 1rem;
  }

  ::placeholder {
    color: ${colors.cloudBurst};
    font-weight: 500;
    padding-left: 1rem;
  }

  @media (max-width: 576px) {
    width: ${({ mobileMed, width }) => {
      if (mobileMed) return "250px";
      if (width) return width;
      return "192px";
    }};
    margin: ${({ noMargin }) => (noMargin ? "0rem" : "0.5rem 0rem")};
  }
`;

InputField.defaultProps = {
  mobileMed: false,
  noMargin: false,
};

export default InputField;
