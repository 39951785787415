import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ResponseCreateScene from "../Scenes/ResponseCreateScene";
import ResponseSubmittedScene from "../Scenes/ResponseSubmittedScene";
import { pick, pickBy } from "lodash";

class ResponseCreateSceneConnected extends Component {
  render() {
    const { prompt, response } = this.props;
    return (
      <>
        {prompt && response.is_submitted ? (
          <ResponseSubmittedScene {...this.props} />
        ) : (
          <ResponseCreateScene {...this.props} />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ prompts, circles, responses, auth }, ownProps) => {
  const prompt = prompts[ownProps.match.params.id];
  const circle = Object.values(circles).filter((circle) =>
    circle.posts.includes(prompt.id)
  )[0];
  const promptResponses = prompt && pick(responses, prompt.response);

  const response =
    promptResponses &&
    Object.values(
      pickBy(promptResponses, (res) => res.owner_id === auth.userId)
    )[0];

  return {
    prompt,
    circle,
    response,
    responsesList: prompt && Object.values(prompt.response),
  };
};
export default withRouter(
  connect(
    mapStateToProps,
    null
  )(ResponseCreateSceneConnected)
);
