import React from "react";
import PropTypes from "prop-types";

import Circle from "../../Shared/Circle";
import Header from "../../Shared/Header";
import FlexVC from "../../Shared/FlexVC";
import CircleInfoWrapper from "./CircleInfoWrapper";
import TextWithDate from "./TextWithDate";
import CircleNameWithEdit from "./CircleNameWithEdit";
import CircleSpreadTextBtn from "../../containers/CircleSpreadTextBtnConnected";

// Google Analytics
import { Event } from "../../Tracking";

const CircleHeaderInfo = ({ circle, first_name }) => (
  <CircleInfoWrapper>
    <div>
      <Circle
        size={10}
        name={circle.circle_name}
        border={1}
        bordercolor={"white"}
        margin={"0rem 2rem"}
        onClick={() =>
          Event("Circle", "Viewed", circle.id)
        }
      />
    </div>
    <FlexVC
      direct={"column"}
      justify={"flex-start"}
      align={"flex-start"}
      malign={"center"}
    >
      <Header h1 margin={"0.2rem 0rem"} center>
        {circle.circle_name}
      </Header>
      <TextWithDate date={circle.created_at} />
      <CircleSpreadTextBtn circle={circle} first_name={first_name} />
    </FlexVC>
  </CircleInfoWrapper>
);
CircleHeaderInfo.propTypes = {
  circle: PropTypes.object,
  first_name: PropTypes.string,
};
CircleHeaderInfo.defaultProps = {
  circle: {},
  first_name: "",
};
export default CircleHeaderInfo;
