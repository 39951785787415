import React from "react";

const Arc = () => (
  <svg id="curve" viewBox="0 0 1440 71" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1440,313.5 C1200,313.5 960,313.5 720,313.5 C480,313.5 240,313.5 0,313.5 L0,313 C240,266.333333 480,243 720,243 C960,243 1200,266.333333 1440,313 L1440,313.5 Z"
      transform="translate(0.000000, -242.000000)"
    />
  </svg>
);

export default Arc;
