import React, { Component } from "react";
import { connect } from "react-redux";
import { getUser } from "../../store/actions";
import AccountScene from "../Scenes/AccountScene";
import formatDate from "../../utilities/functions/formatDate";
import ProtoTypes from "prop-types";
import LoadingScene from "../Scenes/LoadingScene";

class AccountSceneConnected extends Component {
  state = { loading: true };
  componentDidMount() {
    this.props.getUser();
    this.setState({ loading: false });
  }
  render() {
    let { isSignedIn, name, email, dateJoined } = this.props;
    return (
      <>
        {this.state.loading ? (
          <LoadingScene />
        ) : (
          <AccountScene
            isSignedIn={isSignedIn}
            name={name}
            email={email}
            dateJoined={formatDate(dateJoined)}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ auth, users }) => {
  return {
    isSignedIn: auth.isSignedIn,
    name: `${auth.first_name} ${auth.last_name}`,
    email: `${auth.email}`,
    dateJoined: Object.values(users)[0] && Object.values(users)[0].date_joined,
  };
};

AccountSceneConnected.protoTypes = {
  isSignedIn: ProtoTypes.bool,
  name: ProtoTypes.string,
  email: ProtoTypes.string,
  dateJoined: ProtoTypes.object,
};

export default connect(mapStateToProps, { getUser })(AccountSceneConnected);
