import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CommentListingWithInput from "../Lists/CommentListingWithInput";
import { pick } from "lodash";
import ProtoTypes from "prop-types";

class CommentListingWithInputConnected extends Component {
  render() {
    const { comments, name } = this.props;
    return (
      <>{comments && <CommentListingWithInput name={name} {...this.props} />}</>
    );
  }
}

const mapStateToProps = ({ comments, auth }, { commentsIds }) => {
  const filteredComments = Object.values(pick(comments, commentsIds)).sort(
    (a, b) => (b.created_at < a.created_at ? 1 : -1)
  );
  const name = auth.first_name;

  return {
    comments: filteredComments,
    name,
  };
};

CommentListingWithInputConnected.protoTypes = {
  comments: ProtoTypes.object,
  name: ProtoTypes.string
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(CommentListingWithInputConnected)
);
