import React, { Component } from "react";
import { connect } from "react-redux";
import { signUp } from "../../store/actions";
import { withRouter } from "react-router-dom";
import history from "../../history";
import { Formik } from "formik";
import EmailInviteForm from "../Forms/EmailInviteForm";
import LoadingScene from "../Scenes/LoadingScene";

class EmailInviteFormConnected extends Component {
  componentDidMount() {
    const { email, inviteeName } = this.props;
    if (!email || !inviteeName) {
      history.push("/");
    }
  }

  render() {
    const { email, inviteeName } = this.props;

    return (
      <Formik
        initialValues={{
          password1: "",
          password2: "",
          first_name: `${inviteeName || ""}`,
          email: `${email || ""}`,
          last_name: "",
        }}
        onSubmit={(values, actions) => {
          this.props
            .signUp(values, actions)
            .then(() => {})
            .catch((error) => {
              const { data } = error.response;
              let errorMessage = data.non_field_errors ||
                data.email ||
                data.password1 || ["Sign Up Credentials Invalid"];
              actions.setFieldError("general", errorMessage[0]);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {({ values, errors, isSubmitting }) =>
          !isSubmitting ? (
            <EmailInviteForm values={values} errors={errors} />
          ) : (
            <LoadingScene />
          )
        }
      </Formik>
    );
  }
}

export default withRouter(connect(null, { signUp })(EmailInviteFormConnected));
