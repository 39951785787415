import React, { Component } from "react";
import SceneTemplate from "./SceneTemplate";
import HeaderSection from "../containers/HeaderSectionConnected";
import LandingFormSection from "../Sections/LandingFormSection";
class LandingScene extends Component {
  state = {
    formToDisplay: "Sign Up",
  };
  selectForm = (form) => {
    this.setState({
      formToDisplay: form,
    });
  };

  render() {
    const { formToDisplay } = this.state;
    return (
      <SceneTemplate
        mode={0}
        createdCircle={this.props.createdCircle}
        header={<HeaderSection header={formToDisplay} mode={0} short />}
      >
        <LandingFormSection
          selectForm={this.selectForm}
          formToDisplay={formToDisplay}
          {...this.props}
        />
      </SceneTemplate>
    );
  }
}

export default LandingScene;
