import history from "../../history";
import { ember } from "../middleware/apis/ember";
import { GET_ONBOARDING_QS, DELETE_ONBOARDING_QS } from "./types";

export const getOnboardingQs = (circle_category = "social") => async (
  dispatch
) => {
  try {
    const res = await ember.post(`/onboarding_questions/`, {
      circle_category,
    });

    dispatch({
      type: GET_ONBOARDING_QS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    history.push("/error");
  }
};

export const deleteOnboardingQs = () => async (dispatch) => {
  dispatch({
    type: DELETE_ONBOARDING_QS,
  });
};
