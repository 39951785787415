import styled from "styled-components";
import { colors } from "../Shared/styleGuide";

const MobileDropDownContainer = styled("div")`
  display: block;
  width: 100vw;
  height: 100vh;
  padding: 1rem;
  margin-bottom: -100px;
  text-align: left;
  background: ${({ mode }) => (mode ? colors.selago : colors.slateGrey)}
  color: ${({ mode }) => (mode ? colors.cloudBurst : colors.white)};
`;
export default MobileDropDownContainer;
