import React, { Component } from "react";
import Circle from "../Shared/Circle";
import Bell from "../Shared/icons/Bell";
import styled from "styled-components";

// Google Analytics
import { Event } from "../Tracking";

const AlertBellContainer = styled("div")`
  position: relative;
  width: 26px;
  height: 24px;
`;
const RedCircle = styled(Circle)`
  position: absolute;
  right: 0;
  top: 1px;
`;
class AlertBell extends Component {
  state = {
    clicked: 0,
  };
  handleClick = () => {
    this.setState(({ clicked }) => ({
      clicked: clicked === 0 ? clicked + 1 : 0,
    }));
    if (this.state.clicked === 1) {
      this.props.deleteNotifications();
    };
    Event("Notifications", "Viewed", "id");
  };
  render() {
    const { alert, mode } = this.props;
    return (
      <AlertBellContainer onClick={this.handleClick}>
        <Bell mode={mode} />
        <RedCircle
          size={0.8}
          plainBg={true}
          alert={true}
          margin={"0px"}
          name={alert.toString()}
        />
      </AlertBellContainer>
    );
  }
}

export default AlertBell;
