import React from "react";
import FlexVC from "../Shared/FlexVC";
import Header from "../Shared/Header";
import LeftArrow from "../Shared/icons/OnboardingLeft";
import RightArrow from "../Shared/icons/OnboardingRight";
import friendsOnline from "../Shared/images/friendsOnline.png";
import groupChat from "../Shared/images/groupChat.png";
import personWQuestion from "../Shared/images/personWQuestion.png";

const HowItWorksSlide = ({ step, handleStep }) => (
  <FlexVC direct={"column"} justify={"flex-start"} full margin={"0rem 3rem"}>
    {step === 0 && (
      <Header h3 center margin={"1.5rem 2rem"}>
        Invite people from your life into a circle
      </Header>
    )}
    {step === 1 && (
      <Header h3 center margin={"1.5rem 2rem"}>
        Answer new questions each week
      </Header>
    )}
    {step === 2 && (
      <Header h3 center margin={"1.5rem 2rem"}>
        See each other's responses once everyone has responded
      </Header>
    )}
    <FlexVC direct={"row"} justify={"space-between"} align={"center"}>
      <div onClick={() => handleStep(-1)}>{step !== 0 && <LeftArrow />}</div>
      {step === 0 && <img src={friendsOnline} />}
      {step === 1 && <img src={personWQuestion} />}
      {step === 2 && <img src={groupChat} />}
      <div onClick={() => handleStep(1)}>{step !== 2 && <RightArrow />}</div>
    </FlexVC>
  </FlexVC>
);
export default HowItWorksSlide;
