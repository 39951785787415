import React, { Component } from "react";
import { Field, FieldArray } from "formik";
import Oval from "../../Shared/Oval";
import Button from "../../Shared/Button";
import FlexVC from "../../Shared/FlexVC";
import RenderFieldComponent from "../../Shared/RenderFieldComponent";
import InvitedMemberList from "../../Lists/InvitedMembersList";
import { validateName, validateEmail, validateUnique } from "../validate.js";

class InviteMembers extends Component {
  handleStepValidation = () => {
    const {
      values: { first_name, email, members },
      userEmail,
      setFieldError,
      handleStep,
      isValid,
    } = this.props;
    const membersLen = members.length;

    if (
      membersLen === 0 &&
      first_name.length !== 0 &&
      email.length !== 0 &&
      !isValid
    ) {
      setFieldError("email", "Invalid");
    } else if (email === userEmail) {
      setFieldError("email", "Invite someone besides yourself");
    } else {
      membersLen > 0
        ? handleStep()
        : setFieldError("email", "Add at least one");
    }
  };

  handlePartnerAddition = () => {
    const {
      setFieldValue,
      isValid,
      values: { first_name, email, members },
    } = this.props;

    if (first_name.length !== 0 && email.length !== 0 && isValid) {
      setFieldValue("members", [
        {
          first_name,
          email,
        },
      ]);
    }
  };

  handleMultipleMembersValidation = async (push) => {
    const {
      isValid,
      setFieldError,
      setFieldValue,
      userEmail,
      errors,
      values: { members, email, first_name },
    } = this.props;

    // Check for unique invitations
    const unique = validateUnique(members, email);

    if (email === userEmail) {
      setFieldError("email", "Invite someone besides yourself");
    }
    if (!email || !first_name) {
      setFieldError("email", "Required");
    }
    if (
      isValid &&
      unique == undefined &&
      email !== userEmail &&
      email &&
      first_name
    ) {
      delete errors.email;
      await push({
        first_name,
        email,
      });
    } else if (unique) {
      setFieldError("email", "Already added");
    }
    setFieldValue("first_name", "");
    setFieldValue("email", "");
  };

  handleContinue = async () => {
    const {
      handlePartnerAddition,
      handleStepValidation,
      props: { memberType },
    } = this;

    if (memberType) {
      await handlePartnerAddition();
    }
    await handleStepValidation();
  };
  render() {
    const {
      memberType,
      handleBack,
      values: { members },
      props,
    } = this.props;
    return (
      <FlexVC direct={"column"} align={"center"} full>
        <FlexVC
          direct={"column"}
          align={"center"}
          full
          margin={"1rem 0rem 0.5rem 0rem"}
        >
          <FieldArray
            name="members"
            render={({ push }) => (
              <FlexVC direct="column" justify={"center"}>
                <Field
                  component={RenderFieldComponent}
                  placeholder={"First Name"}
                  name={`first_name`}
                  validate={validateName}
                  margin={"0rem 1.375rem"}
                  index
                  dwidth
                  {...props}
                />

                <Field
                  component={RenderFieldComponent}
                  name={`email`}
                  validate={validateEmail}
                  placeholder={"Email"}
                  margin={"0rem 1.375rem"}
                  dwidth
                  index
                  {...props}
                />
                {memberType !== true && (
                  <Oval
                    colorstatic
                    width={"112px"}
                    type="button"
                    margin={"0.5rem 0rem 1rem 0rem"}
                    onClick={() => {
                      this.handleMultipleMembersValidation(push);
                    }}
                  >
                    Invite
                  </Oval>
                )}
              </FlexVC>
            )}
          />
          <FlexVC
            direct="column"
            justify="center"
            margin={"0.75rem 0rem 0.5rem 0rem"}
            full
          >
            <FlexVC direct="column" full>
              <Button
                width={"236px"}
                type={"button"}
                onClick={this.handleContinue}
              >
                Name Your Circle
              </Button>
              <Button
                btntype="tertiary"
                type={"button"}
                onClick={() => handleBack()}
                fontSize="0.875"
              >
                Back
              </Button>
            </FlexVC>
            {memberType !== true && members.length > 0 && (
              <InvitedMemberList members={members} />
            )}
          </FlexVC>
        </FlexVC>
      </FlexVC>
    );
  }
}

export default InviteMembers;
