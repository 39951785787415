import React, { Component } from "react";
import Logo from "../Shared/FlexibleLogo";
import Header from "../Shared/Header";
import FlexVC from "../Shared/FlexVC";
import LoadingScene from "../Scenes/LoadingScene";

// Seen right after signing up
class WelcomeMobileScene extends Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    this.setState({ loading: false });
  }
  render() {
    const { message } = this.props;
    if (this.state.loading) {
      return <LoadingScene />;
    }
    return (
      <FlexVC direct="row" align="center" justify="center" tall>
        <FlexVC direct="column" width={"30%"} mwidth={"100%"} justify="center">
          <Logo size={150} />
          <Header h1 center>
            Welcome
          </Header>
          <Header h1 center>
            {message}
          </Header>
        </FlexVC>
      </FlexVC>
    );
  }
}

export default WelcomeMobileScene;
