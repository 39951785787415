import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FirstQOptions from "../Forms/CreateCircleForm/FirstQOptions";
import ProtoTypes from "prop-types";

class FirstQOptionsConnected extends Component {
  render() {
    return <FirstQOptions {...this.props} />;
  }
}

const mapStateToProps = ({ onboardingQs }) => {
  const options = Object.values(onboardingQs).map((question) => ({
    ...question,
    selected: false,
    disabled: true,
  }));
  return {
    options,
  };
};

FirstQOptionsConnected.protoTypes = {
  onboardingQs: ProtoTypes.array,
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(FirstQOptionsConnected)
);
