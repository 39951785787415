import React, { Component } from "react";
import SceneTemplate from "./SceneTemplate";
import HorizontalCenteredRow from "../Shared/HorizontalCenteredRow";
import CirclesListingWArrowBtn from "../Lists/CirclesListingWArrowBtn";
import HomeHero from "../Heros/HomeHero";
import PendingInvites from "../Sections/PendingInvites";
import WelcomeModal from "../Modals/WelcomeModal";

class HomeScene extends Component {
  state = {
    showModal: false,
    circles: this.props.circles,
  };
  static getDerivedStateFromProps(props, state) {
    if (props.circles !== state.circles) {
      if (props.welcomeModalViews < 1 && props.firstRes.length === 1) {
        return {
          showModal: props.welcomeModalViews < 1,
          circles: props.circles,
        };
      }
      return {
        circles: props.circles,
      };
    }

    return null;
  }
  handleClose = () => {
    this.props.getWelcomeModalViews();

    this.setState({
      showModal: false,
    });
  };
  render() {
    const { props } = this;

    return (
      <SceneTemplate
        mode={1}
        header={
          <HomeHero
            header={`Good to see you again,`}
            tagline={`There is new activity in your circles`}
            mode={1}
            name={props.name}
            ctas={props.ctas}
            mh={"565px"}
            h={"744px"}
            {...props}
          />
        }
        ntm
        {...props}
      >
        {props.firstRes.length === 1 && (
          <WelcomeModal
            handleClose={this.handleClose}
            show={this.state.showModal}
            response={props.firstRes[0]}
          />
        )}

        {props.userInvites.length > 0 && (
          <PendingInvites invites={props.userInvites} />
        )}
        <HorizontalCenteredRow>
          <CirclesListingWArrowBtn {...props} />
        </HorizontalCenteredRow>
      </SceneTemplate>
    );
  }
}
export default HomeScene;
