import React, { Component } from "react";
import { connect } from "react-redux";
import { getNotifications } from "../../store/actions";
import NotificationMenu from "../Notifications/NotificationMenu";

// Getting notifications for navbar
class NotificationMenuConnected extends Component {
  componentDidMount() {
    // this.props.getNotifications();
  }
  render() {
    let { notifications } = this.props;
    return <NotificationMenu notifications={notifications} {...this.props} />;
  }
}

const mapStateToProps = ({ notifications }) => {
  return {
    notifications: Object.values(notifications),
  };
};
export default connect(mapStateToProps, { getNotifications })(
  NotificationMenuConnected
);
