import React, { Component } from "react";
import { Field } from "formik";
import FlexVC from "../../Shared/FlexVC";
import Button from "../../Shared/Button";
import RenderFieldComponent from "../../Shared/RenderFieldComponent";
import { validateCircleName } from "../validate.js";
import Oval from "../../Shared/Oval";
import Circle from "../../Shared/Circle";

// Google Analytics
import { Event } from "../../Tracking";

class NameAndImg extends Component {
  handleImageUpload = (val) => {};
  render() {
    const { memberType, handleStep, handleBack } = this.props;

    return (
      <>
        <FlexVC direct="column" justify={"center"}>
          <Field
            component={RenderFieldComponent}
            name="circle_name"
            inputtype={"text"}
            placeholder={"Circle Name"}
            validate={validateCircleName}
            dwidth
          />
          {/*<Oval
            type={"button"}
            width={"112px"}
            margin={"0.5rem 0rem 0rem 0rem"}
            smf
          >
            Add Image
          </Oval>
          <FlexVC direct="row" justify="center" margin={"0rem"} full>
            <Circle size={10} bordercolor={"white"} border />
          </FlexVC>*/}
        </FlexVC>

        <FlexVC direct="row" justify="center" margin={"1rem 0rem"} full>
          <FlexVC direct="column" full>
            {memberType == true ? (
              <Button
                type={"button"}
                width={"236px"}
                onClick={() => handleStep()}
              >
                Length of Relationship
              </Button>
            ) : (
              <Button
                type={"submit"}
                width={"236px"}
                onClick={() =>{
                  // Testing to get Circle.id at time of Circle creation
                  // console.log(this.props);
                  // console.log(Circle);
                  // console.log(Circle.id);
                  Event("Circle", "Created", "id")
                }}
              >
                Create Circle
              </Button>
            )}
            <Button
              btntype="tertiary"
              type={"button"}
              onClick={() => handleBack()}
              fontSize="0.875"
            >
              Back
            </Button>
          </FlexVC>
        </FlexVC>
      </>
    );
  }
}

export default NameAndImg;
