import React from "react";
import styled from "styled-components";
import { colors } from "../../Shared/styleGuide";
import FlexVC from "../../Shared/FlexVC";
import Logo from "../../Shared/FlexibleLogo";

const LogoRow = styled(FlexVC)`
  padding-top: 1.5rem;
  background: ${({ mode }) => (mode ? colors.selago : colors.slateGrey)};
`;
const CenterLogoOnlyNav = ({ mode }) => (
  <LogoRow direct={"row"} justify={"center"} mode={mode} full>
    <Logo size={50} msize={34} mb={"0rem"} />
  </LogoRow>
);

export default CenterLogoOnlyNav;
