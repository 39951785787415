import React, { Component } from "react";
import SceneTemplate from "./SceneTemplate";
import Header from "../Shared/Header";
import FlexVC from "../Shared/FlexVC";
import FlexUl from "../Shared/FlexUl";
import Circle from "../Shared/Circle";
import Text from "../Shared/Text";
import CircleManageHero from "../Heros/CircleManageHero";
import HeaderSection from "../containers/HeaderSectionConnected";
import MemberAddBtnWModal from "../Modals/MemberAddBtnWModal";
import LeaveCircleBtnWModal from "../Modals/LeaveCircleBtnWModal";
import CancelInviteBtnWModal from "../Modals/CancelInviteBtnWModal";

const Segment = ({
  title,
  items,
  verb,
  titlebtn,
  secMargin,
  itemAction,
  circleCategory,
}) => (
  <FlexVC direct="column" margin={secMargin} width={"325px"} mwidth={"325px"}>
    <ListTitle
      title={title}
      titlebtn={titlebtn}
      circleCategory={circleCategory}
    />
    {items && <List items={items} verb={verb} itemAction={itemAction} />}
  </FlexVC>
);
const ListTitle = ({ title, titlebtn, circleCategory }) => (
  <FlexVC
    direct="column"
    mjustify={"center"}
    malign={"center"}
    justify={"center"}
    margin={"0.8125rem 0rem"}
    full
    mobileCol
  >
    <Header h2 noRes noMargin>
      {title}
    </Header>
    {circleCategory !== "partner" && titlebtn}
  </FlexVC>
);

// follows circle color order
const List = ({ items, verb, itemAction }) => (
  <FlexUl direct={"column"} lipad={"0px"} la={verb} width={"100%"}>
    {items.map((item, index) => (
      <ListItem
        key={item.id}
        item={item}
        verb={verb}
        index={index}
        itemAction={itemAction}
      />
    ))}
  </FlexUl>
);
const ListItem = ({
  item,
  verb,
  index,
  itemAction,
  circleColors = ["h", "d", "c", "i"],
}) => (
  <li>
    <FlexVC direct="row" malign={verb ? "flex-start" : "center"} full>
      <Circle
        margin={"0rem 1rem 0rem 0rem"}
        size={2.25}
        plainBg={true}
        bordercolor={"transparent"}
        resv={circleColors[index % circleColors.length]}
        name={verb ? item.invited_first_name : item.first_name}
      />
      <FlexVC
        direct="row"
        justify={!verb ? "flex-start" : "space-between"}
        align={"center"}
        mobileCol
        width={"100%"}
      >
        <Text
          textAlign={"left"}
          fontSize={"1.125"}
          display={"block"}
          margin={"0.5rem 0rem"}
        >
          {verb
            ? item.invited_first_name
            : `${item.first_name} ${item.last_name}`}
        </Text>

        {verb && (
          <CancelInviteBtnWModal
            circleId={item.circle}
            inviteId={item.id}
            deleteInvite={itemAction}
          />
        )}
      </FlexVC>
    </FlexVC>
  </li>
);

class CirclesManageScene extends Component {
  render() {
    const { circle, members, invited, deleteInvite } = this.props;
    return (
      <SceneTemplate
        mode={1}
        header={<HeaderSection header={`Customize Your Circle`} mode={1} />}
        ntm
      >
        <CircleManageHero circle={circle} />

        <FlexVC direct={"column"} margin={"0rem 1.125rem"} mobileCol>
          <Segment
            title={"Current Members"}
            secMargin={"0.3125rem 0rem 0.4875rem 0rem"}
            items={members}
            circleCategory={circle.category}
            titlebtn={
              <MemberAddBtnWModal circle={circle}>
                Add a Member
              </MemberAddBtnWModal>
            }
          />
          {invited.length > 0 && (
            <Segment
              title={"Pending Invites"}
              items={invited}
              verb={"cancel"}
              itemAction={deleteInvite}
            />
          )}
          <Segment
            secMargin={"0.3125rem 0rem 0.4875rem 0rem"}
            titlebtn={
              <LeaveCircleBtnWModal circle={circle}>
                Leave Circle
              </LeaveCircleBtnWModal>
            }
          />
        </FlexVC>
      </SceneTemplate>
    );
  }
}
export default CirclesManageScene;
