import React from "react";
import { Field } from "formik";
import Button from "../../Shared/Button";
import Form from "../../Shared/Form";
import RenderFieldComponent from "../../Shared/RenderFieldComponent";
import FlexVC from "../../Shared/FlexVC";

const SignInForm = ({ errors, selectForm }) => (
  <Form mobileMed>
    <Field
      component={RenderFieldComponent}
      name="email"
      inputtype={"email"}
      placeholder={"Email"}
      mobileMed
    />
    <Field
      component={RenderFieldComponent}
      name="password"
      inputtype={"password"}
      placeholder={"Password"}
      mobileMed
      noMargin
    />
    <FlexVC direct="column" margin={"0rem 0rem 2rem 0rem"}>
      <Button
        type="button"
        btntype="tertiary"
        fontSize="0.875"
        onClick={() => selectForm("Reset Password")}
      >
        Forgot your password?
      </Button>
    </FlexVC>
    <Button type="Submit" btntype={"primary"}>
      Sign In
    </Button>
    {errors.general}
  </Form>
);

export default SignInForm;
