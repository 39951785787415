import React from "react";
import history from "../../history";
import SceneTemplate from "./SceneTemplate";
import HeaderSection from "../containers/HeaderSectionConnected";
import Button from "../Shared/Button";
import Circle from "../Shared/Circle";
import FlexVC from "../Shared/FlexVC";
import Header from "../Shared/Header";

const ErrorScene = (props) => (
  <SceneTemplate
    mode={1}
    header={
      <HeaderSection
        header={`Oops! How did you end up here?`}
        tagline={<Circle name={"b"} size={"16"} border bordercolor={""} />}
        mode={1}
      />
    }
  >
    <FlexVC direct="column">
      <Header h3 slateGrey light center>
        Something went wrong.
      </Header>
      <Header h3 slateGrey light center>
        Don't worry though, we'll help you get back to your home...page.
      </Header>
      <FlexVC direct="column" margin={"3rem 0rem 0rem 0rem"}>
        <Button width="236px" onClick={() => history.push("/home")}>
          Back To Homepage
        </Button>
      </FlexVC>
    </FlexVC>
  </SceneTemplate>
);
export default ErrorScene;
