import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CircleSpreadTextBtn from "../Heros/CircleViewHero/CircleSpreadTextBtn";
import { pick } from "lodash";
import ProtoTypes from "prop-types";

class CircleSpreadTextBtnConnected extends Component {
  render() {
    const { members } = this.props;
    return <>{members && <CircleSpreadTextBtn {...this.props} />}</>;
  }
}

const mapStateToProps = ({ circles, members }, { match: { params } }) => {
  const circle = circles[params.id];
  const membersList = Object.values(pick(members, circle.members));

  return {
    members: membersList,
  };
};

CircleSpreadTextBtnConnected.protoTypes = {
  members: ProtoTypes.object,
};

export default withRouter(
  connect(mapStateToProps, null)(CircleSpreadTextBtnConnected)
);
