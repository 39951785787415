import {
  CREATE_RESPONSE,
  GET_RESPONSES,
  UPDATE_RESPONSE,
  UPDATE_RESPONSES,
} from "../actions/types";

export default (state = {}, action) => {
  switch (action.type) {
    case GET_RESPONSES:
      return {
        ...state,
        ...action.payload,
      };

    case CREATE_RESPONSE:
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], ...action.payload },
      };

    case UPDATE_RESPONSE:
      let newState = {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          comments: state[action.payload.id].comments.concat(
            action.payload.commentId
          ),
        },
      };

      return newState;

    case UPDATE_RESPONSES:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
