import strToHash from "./strToHash.js";
import { colors } from "../../components/Shared/styleGuide.js";

// importing svgs
import CircleA from "../../components/Shared/images/circles/circle-a.svg";
import CircleB from "../../components/Shared/images/circles/circle-b.svg";
import CircleC from "../../components/Shared/images/circles/circle-c.svg";
import CircleD from "../../components/Shared/images/circles/circle-d.svg";
import CircleE from "../../components/Shared/images/circles/circle-e.svg";
import CircleF from "../../components/Shared/images/circles/circle-f.svg";
import CircleG from "../../components/Shared/images/circles/circle-g.svg";
import CircleH from "../../components/Shared/images/circles/circle-h.svg";
import CircleI from "../../components/Shared/images/circles/circle-i.svg";
import CircleJ from "../../components/Shared/images/circles/circle-j.svg";
import CircleK from "../../components/Shared/images/circles/circle-k.svg";
import CircleL from "../../components/Shared/images/circles/circle-l.svg";

const {
  bittersweet,
  greyChateau,
  slateGrey,
  eastBay,
  greyBlue,
  maverick,
} = colors;

const bgColors = [
  bittersweet,
  greyChateau,
  slateGrey,
  eastBay,
  greyBlue,
  maverick,
];
const bgCircles = [
  CircleA,
  CircleB,
  CircleC,
  CircleD,
  CircleE,
  CircleF,
  CircleG,
  CircleH,
  CircleI,
  CircleJ,
  CircleK,
  CircleL,
];
const circleBgImgSelector = (plainBg, name, alert, resv) => {
  let circleBg;
  const charToHash = resv ? resv : name;
  const [last] = strToHash(charToHash)
    .split("")
    .slice(-1);
  const circleTotal = plainBg ? bgColors.length : bgCircles.length;

  // need to change this to have the right range
  const circle_idx = (circleTotal + last) % circleTotal;
  circleBg = plainBg ? bgColors[circle_idx] : bgCircles[circle_idx];

  circleBg = alert ? bgColors[0] : circleBg;

  return plainBg ? circleBg : `url(${circleBg}) white no-repeat`;
};
export default circleBgImgSelector;
