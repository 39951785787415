import { schema } from "normalizr";

// Comments schema
const commentSchema = new schema.Entity("comments");

// Members schema
const memberSchema = new schema.Entity("members");
// Invites schema
const inviteSchema = new schema.Entity("Invites");

// Responses schema
const responseSchema = new schema.Entity("response", {
  comments: [commentSchema]
});

// Posts Schema (Used to be called prompts)
const postSchema = new schema.Entity("posts", {
  response: [responseSchema]
});

// Circles Scheme
// TODO: get invites from API call and use for circle page
const circleSchema = new schema.Entity("circles", {
  posts: [postSchema],
  members: [memberSchema],
  invites: [inviteSchema]
});

// Schemas for API responses
export const Schemas = {
  POST: postSchema,
  POST_ARRAY: [postSchema],
  RESPONSE: responseSchema,
  RESPONSE_ARRAY: [responseSchema],
  COMMENT: commentSchema,
  COMMENT_ARRAY: [commentSchema],
  CIRCLE: circleSchema,
  CIRCLES_ARRAY: [circleSchema],
  MEMBER: memberSchema,
  MEMBERS_ARRAY: [memberSchema],
  INVITE: inviteSchema,
  INVITES_ARRAY: [inviteSchema]
};
