import history from "../../history";
import { ember } from "../middleware/apis/ember";
import { updateCircleInvites } from "../actions/circleRelatedActions";
import { getCTAs } from "../actions/ctaActions";
import { arrayToObject } from "../../utilities/functions/arrayToObject";
import {
  GET_INVITES,
  UPDATE_INVITES,
  CREATE_CURRENT_INVITE,
  CREATE_CIRCLE_INVITE,
  DELETE_CIRCLE_INVITE,
  DELETE_CURRENT_INVITE,
  INVALID_INVITE,
} from "./types";

export const getInvites = () => async (dispatch) => {
  try {
    //used to get invites if not in circle
    const getUserInvites = await ember.get(`/invites/`);
    const circleIdsOfInvites = arrayToObject(getUserInvites.data, "id");

    // api res is struct in a way where the data needs to be reformatted
    let invites = getUserInvites.data.reduce((a, c) => a.concat(c.invites), []);
    for (let i = 0; i < invites.length; i++) {
      invites[i]["circle_name"] =
        circleIdsOfInvites[invites[i].circle].circle_name;
    }
    dispatch({
      type: GET_INVITES,
      payload: invites,
    });
  } catch (error) {
    // Message to send to error logging route
    console.log(error);
    history.push("/error");
  }
};

// Used by user to update their own invite
export const updateInvite = (invitedId, selection) => async (
  dispatch,
  getState
) => {
  const { newUser } = getState().auth;
  try {
    // check if valid invite
    await dispatch(getInvites());
    const { invites } = getState();

    if (invitedId in invites) {
      const response = selection == 0 ? "Decline" : "Accept";
      await ember.put(`/invites/${invitedId}/`, {
        response,
      });

      dispatch({
        type: UPDATE_INVITES,
        payload: { invitedId, accept_status: response },
      });
    } else {
      dispatch({
        type: INVALID_INVITE,
      });
    }
    await dispatch(getCTAs());
    const ctas = getState().ctas;
    const ctasLen = ctas && Object.values(ctas).length;

    newUser && ctasLen < 1 ? history.push("/welcome") : history.push("/home");
  } catch (error) {
    // Message to send to error logging route
    console.log(error);
    history.push("/error");
  }
};

export const createInvite = (circle_id, member) => async (dispatch) => {
  try {
    const res = await ember.post(`/invites/`, {
      email: member.email,
      first_name: member.first_name,
      circle_id,
    });
    dispatch({
      type: CREATE_CIRCLE_INVITE,
      payload: res.data,
    });
    await dispatch(updateCircleInvites(circle_id, res.data.id, "ADD"));
  } catch (error) {
    console.log(error);
    history.push("/error");
  }
};

// Used by user to cancel another person's invite
export const deleteInvite = (inviteId, circleId) => async (dispatch) => {
  try {
    await ember.delete(`/invites/${inviteId}`);

    dispatch({
      type: DELETE_CIRCLE_INVITE,
      payload: { circleId, inviteId },
    });

    await dispatch(updateCircleInvites(circleId, inviteId, "REMOVE"));
  } catch (error) {
    console.log(error);
    history.push("/error");
  }
};
/* Used in How it works to remember current viewed invite */
export const createCurrentInvite = (currentId) => async (dispatch) => {
  dispatch({
    type: CREATE_CURRENT_INVITE,
    payload: { currentId },
  });
};

export const deleteCurrentInvite = () => async (dispatch) => {
  dispatch({
    type: DELETE_CURRENT_INVITE,
  });
};
