import React, { Component } from "react";
import { Transition } from "react-transition-group";
import FadeIn from "../Shared/FadeIn";
import FadeOut from "../Shared/FadeOut";
import WelcomeMobile from "../Scenes/WelcomeMobileScene";
import HIWScene from "../containers/HIWSceneConnected";
import InvitedToCircleScene from "../containers/InvitedToCircleSceneConnected";
import LoadingScene from "../Scenes/LoadingScene";

class GetStartedScene extends Component {
  state = {
    step: 0,
    visible: true,
    message: "",
    loading: true,
    invitedId: false,
  };
  static getDerivedStateFromProps(props, state) {
    if (props.invitedId !== state.invitedId) {
      return {
        invitedId: props.inviteId,
        message:
          props.inviteId && state.loading === false
            ? "You Have An Invite Waiting For You! "
            : "Let’s Make Your First Fireside Circle",
      };
    }
  }
  componentDidMount() {
    this.setTimer();
    this.setState({
      loading: false,
    });
  }

  setTimer() {
    // clear any existing timer
    if (this._timer != null) {
      clearTimeout(this._timer);
    }

    // hide after `delay` milliseconds
    this._timer = setTimeout(
      function() {
        this.setState({ visible: false });
        this._timer = null;
      }.bind(this),
      3000
    );
  }

  componentWillUnmount() {
    clearTimeout(this._timer);
  }

  render() {
    const { inviteId } = this.props;
    if (this.state.loading) {
      return <LoadingScene />;
    }
    return (
      <>
        <Transition in={this.state.visible} timeout={500}>
          {(state) => (
            <FadeOut state={state}>
              <WelcomeMobile message={this.state.message} />
            </FadeOut>
          )}
        </Transition>
        <Transition in={!this.state.visible} timeout={500}>
          {(state) => (
            <FadeIn state={state}>
              {inviteId ? (
                <InvitedToCircleScene
                  createdCircle={this.props.createdCircle}
                  inviteId={inviteId}
                />
              ) : (
                <HIWScene createdCircle={this.props.createdCircle} />
              )}
            </FadeIn>
          )}
        </Transition>
      </>
    );
  }
}
export default GetStartedScene;
